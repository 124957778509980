const NomineeDocumentIcon = ({ size = 22, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.76367 16.5013V11.918" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.0003 14.207H6.41699" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.33301 1.83203V4.58203" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.667 1.83203V4.58203" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.4928 3.13672C17.5545 3.24672 19.1037 4.37422 19.1953 8.68255L19.3145 14.3384C19.3878 18.1151 18.517 20.0126 13.9337 20.1134L8.43367 20.2234C3.85034 20.3151 2.897 18.4451 2.82367 14.6776L2.69534 9.01255C2.60367 4.70422 4.11617 3.51255 7.1595 3.28339L14.4928 3.13672Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    

  );
};

export default NomineeDocumentIcon;
