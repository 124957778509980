import styled from "styled-components";
import CoverBg from "../../Assets/Images/backgroundCover.jpg";
import CarouselCoverBg from "../../Assets/Images/carouselCover.jpg";
import theme from "../../Services/Utils/themes";
import { fonts } from "../../Services/Utils/helper";

export const StyledBgContainer = styled.div`
  background-image: url(${CoverBg});
  background-repeat: no-repeat;
  background-size: cover;
  height : 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;

  @media screen and (max-width: 768px) {
    background-image: none;
    background-color: ${theme.white};
    align-items: baseline;

  }
`


export const StyledCardContainer = styled.div`
max-width: 980px;
width: 100%;
max-height: 730px;
height: 100%;
border-radius: 5px;
display: flex;

align-items: center;
justify-content: center;

@media screen and (max-width: 768px) {
flex-direction: column;
padding: 0 20px;
max-height: 100%;
}

`


export const StyledSelectOption = styled.div`
max-width: 364px;
width: 100%;
max-height: 208px;
height: 100%;
border-radius: 5px;
`

export const CarouselContainer = styled.div`
background-image: url(${CarouselCoverBg});
background-repeat: no-repeat;
background-size: cover;
max-width: 490px;
width: 100%;
height: 100%;
border-radius: 5px 0 0 5px;
display: flex;
align-items: end;
justify-content: center;
padding-bottom: 80px;
@media screen and (max-width: 768px) {
border-radius:0;
padding-bottom: 25px;
}

`



export const AuthContainer = styled.div`
background-color: ${theme.white};
padding: 15px;
max-width: 490px;
width: 100%;
height: 100%;
border-radius: 0 5px 5px 0;
display: flex;
flex-direction: column;
justify-content: center;

@media screen and (max-width: 768px) {
padding:0;
}

`

export const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  height: 100%;
  padding: 0 50px;
  @media screen and (max-width: 920px) {
  padding: 0 40px;
    
  }

  @media screen and (max-width: 880px) {
    padding: 0 30px;
      
    }

    @media screen and (max-width: 840px) {
      padding: 0 20px;
        
      }

      @media screen and (max-width: 800px) {
        padding: 0 10px;
          
        }

         @media screen and (max-width: 768px) {
   align-items: center;
   padding: 0px;
    
  }
`;


const getStatusColor = (status) => {
  switch (status) {
    case 'Delivered':
      return theme.tealishGreen;
    case 'Requested':
      return theme.muddyOrange;
    default:
      return;
  }
};

export const StyledBadge = styled.div`
background-color: ${({ bgColor, status }) => bgColor ? bgColor : status ? getStatusColor(status) : theme.silverColor};
border-radius: 100px;
color: ${({ status }) => status ? theme.white : theme.gray};
font-size: ${({ fs }) => fs ? fs : '10px'};
max-width: ${({ width }) => !width && 'fit-content'};
width: ${({ width }) => width && width} !important;
padding: 0px 10px;
font-weight: ${({ fw }) => fw ? fw : '500'};
border: 1px solid ${({ borderColor }) => borderColor ? borderColor : 'none'};
text-align: center;


`


export const DocumentCard = styled.div`
max-height: 296px;
background-color: ${theme.white};
border-radius: 8px;
width: 100%;
// min-width: 258px;
// max-width: 258px;

box-shadow: 0px 4px 40px 0px #35292D0D;


`

export const NomineeCard = styled.div`
background-color: ${theme.white};
border-radius: 8px;
width: 100%;
border: 1px solid ${theme.lightGray};
border-style: dashed;

`


export const StyledFollowerImage = styled.div`
  overflow: hidden;
  width: ${(props) => props?.width || '30px'};
  height: ${(props) => props?.height || '30px'};
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: -12px;
  vertical-align: top;
  transition: transform 0.1s ease-out 0s;
  z-index: 5;
 
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const StyledContainer = styled.div`
max-height: ${({ height }) => height ? `calc(100vh - ${height})` : 'calc(100vh - 258px)'};
height: ${({ zeroState }) => zeroState && '100vh'};
overflow-y: auto;
padding: 15px 35px;
@media screen and (max-width: 768px) {
  height: ${({ smallScreenHeight }) => smallScreenHeight ? `calc(100vh - ${smallScreenHeight})` : 'calc(100vh - 325px)'};
  padding: 15px;
}

@media (min-width: 768px) and (max-width: 1086px) {
    max-height:calc(100vh - 325px);
  }

`;

export const DocumentPostingStyledContainer = styled.div`
max-height: ${({ height }) => height ? `calc(100vh - ${height})` : 'calc(100vh - 258px)'};
height: ${({ zeroState }) => zeroState && '100vh'};
overflow-y: ${({ scrollEnabled }) => scrollEnabled && 'auto'};
@media screen and (max-width: 768px) {
  height: ${({ smallScreenHeight }) => smallScreenHeight ? `calc(100vh - ${smallScreenHeight})` : 'calc(100vh - 325px)'};
  padding: 15px;
  overflow-y: auto;
}
`;


export const CommonStyledContainer = styled.div`
background-color: ${theme.white};
padding:30px 35px;

@media screen and (max-width: 768px) {
  padding:15px;
}
`


export const StyledIconBg = styled.div`
  background-color:${({ color }) => color ? color : theme.aliceBlue};
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height ? height : '34px'};
  width: ${({ width }) => width ? width : '34px'};
  border-radius: ${({ height }) => height ? `${parseInt(height, 10) / 2}px` : '17px'};
  `


export const GridCardContainer = styled.div`

 display: grid;
grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));  
gap: 10px;

`

export const NomineeGridCardContainer = styled.div`

display: grid;
grid-template-columns: ${({ itemCount }) =>
    itemCount === 1 ? "1fr" : "repeat(auto-fill, minmax(340px, 1fr))"};
gap: 10px;

@media screen and (max-width: 390px) {
 grid-template-columns: ${({ itemCount }) =>
    itemCount === 1 ? "1fr" : "repeat(auto-fill, minmax(280px, 1fr))"};
}


`

export const DocumentListContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(258px, 1fr));  
gap: 10px;
padding: 20px 0;

`;

export const StyledDocumentListContainer = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));  
gap: 10px;
padding: 20px 0;

@media screen and (max-width: 768px) {
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));  
}

`;

export const AddressContainer = styled.div`
padding:20px;
background: linear-gradient(90deg, #F7F7F7 72.63%, #FFFFFF 100%);
border-radius: 0px 0px 5px 5px;

`;


export const AdminCommonContainer = styled.div`
background-color: ${theme.white};
padding: 35px;
@media screen and (max-width: 768px) {
  padding:15px;
}

`;


export const TextCommonContainer = styled.div`
background-color: ${theme.white};
padding:30px 35px 0px;
@media screen and (max-width: 768px) {
  padding:15px;
}

`;


export const PaginationCommonContainer = styled.div`
background-color: ${theme.white};
padding:0px 35px;
@media screen and (max-width: 768px) {
  padding:15px;
}

`;

export const UnreadCountCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color:${theme.jasperRed}; /* Red background for unread */
  color: white;
  font-size: 8px;
  font-weight: 700;
  font-family:${fonts.quicksand}
  text-align: center;


      position: absolute;
    top: -5px;
    right: -5px;


`;

