import React, { useState } from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import {
  downloadUrl,
  handleViewDocs,
  useWindowSize,
} from "../../Services/Utils/helper";

import { useTranslation } from "react-i18next";
import DownloadIcon from "../../Components/Svgicons/DownloadIcon";
import { useGetUserAdminListQuery } from "../../Services/DashboardApi/dashboardApiSlice";

import StyledIconInput from "../../Components/StyledComponents/StyledIconInput";
import PaginationPerPage from "../../Components/pagination/PaginationPerPage";
import { GridCardContainer,  PaginationCommonContainer, StyledContainer, TextCommonContainer } from "../../Components/StyledComponents/CommonStyled";
import ViewIcon from "../../Components/Svgicons/ViewIcon";
import CardSkeleton from "../../Components/Skeletons/CardSkeleton";
import ResourcesZeroState from "../../Components/Svgicons/ResourcesZeroState";
import StyledUserCard from "../../Components/StyledComponents/StyledUserCard";

const Team = () => {
  const [timer, setSearchTimer] = useState(null);
  const isSmallScreen = useWindowSize();
  const { t } = useTranslation();

  const initialFilters = {
    perPage: 10,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [filters, setFilters] = useState({ ...initialFilters });
  // eslint-disable-next-line
  const [listing, setListing] = useState("");
  const {
    data: getAdminList,
    refetch: refetchAdmin,
    isLoading
  } = useGetUserAdminListQuery(
    {
      perPage: filters.perPage,
      page: filters.page,
      query: filters.query && filters.query,
      sort: filters.sortedColumn.path || listing.sort,
      sortDir: filters.sortedColumn.order || listing.sortDir,
      ...listing,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );


  const handlePerPageChange = (perPage) => {
    setFilters((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setFilters((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setFilters({ ...filters, page: 1, query: query });
    }, 1000);
    setSearchTimer(newTimer);
  };


  const options = [
    {
      icon: <DownloadIcon size="18px" />,
      onClick: (item) => { window.open(downloadUrl + item?.fileDescriptor, "_blank") },
    },
    {
      icon: <ViewIcon size="18px" />,
      onClick: (item) => { handleViewDocs(item?.s3Object?.signedUrl) },
    },

  ]


  return (
    <div className="bg-white">
      <div className="container-fluid px-0 ">
        <TextCommonContainer className="row align-items-center m-0 pb-0 pb-2">
          <div className="col-md-6 col-sm-12 text-center text-md-start px-0">
            <div>
              <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary}>
                {t("common:ourTeamMembers")}
              </StyledText>
              <StyledText as='p' $fontWeight={400} $fontSize="13px" color={theme.doveGray} className="mb-0 mb-md-2">
                {t("common:allTeamText")}

              </StyledText>
            </div>
          </div>
        </TextCommonContainer>

      </div>
      <StyledContainer smallScreenHeight={isSmallScreen && '265px'} zeroState={!isLoading && (getAdminList?.data?.length == 0 || getAdminList?.data?.length == undefined)}>
        {!isLoading && (getAdminList?.data?.length == 0 || getAdminList?.data?.length == undefined) ?

          (<div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="d-flex align-items-center justify-content-center">
              <ResourcesZeroState />
            </div>
            <StyledText
              color={theme.gray}
              $fontWeight={600}
              $fontSize="20px"
              className="text-center mt-3"
            >
              {t("admin:noAdminsAvailable")}
            </StyledText>
            {/* <StyledText
              color={theme.gray}
              $fontWeight={500}
              $fontSize="14px"
              className="text-center"
            >
              {t("common:noResourceDocumentDescription")}
            </StyledText> */}
          </div>) : isLoading ? <GridCardContainer><CardSkeleton /></GridCardContainer> :
            <GridCardContainer className="gap-3">
              {getAdminList?.data?.map((item, index) => (
                <StyledUserCard options={options} data={item} key={index} />
              ))}
            </GridCardContainer>}

      </StyledContainer>
      {isLoading ? null : !isLoading && (getAdminList?.data?.length == 0 || getAdminList?.data?.length == undefined) ? null : <PaginationCommonContainer>
        <PaginationPerPage
          perPage={getAdminList?.perPage || 0}
          pageNo={getAdminList?.page || 0}
          onPerPageChange={handlePerPageChange}
          totalCount={getAdminList?.count || 0}
          onPageChange={handlePageChange}
        />
      </PaginationCommonContainer>}
    </div>
  );
};




export default Team;
