import styled from 'styled-components';
import theme from '../../Services/Utils/themes';


const StyledDot = styled.div`
  position: ${({ $position }) => ($position ?? $position)};;
  top:${({ $top }) => ($top ?? $top)};
  right:${({ $right }) => ($right ?? $right)};
  height: ${({ $size }) => ($size ? $size : '8px')};
  width: ${({ $size }) => ($size ? $size : '8px')};
  background-color: ${({ color }) => (color ?? theme.inputBorder)};
  border-radius: 100%;
  padding: ${({ $padding }) => ($padding ?? $padding)};
  display: inline-block;
  border: ${({ $border }) => ($border ?? $border)};
  z-index:${({ $zIndex }) => ($zIndex ?? $zIndex)};
`;

export default StyledDot;
