import { useEffect, useState } from "react";
import DefaultFileIcon from "../../Components/Svgicons/DefaultFileIcon";
import ExcelIcon from "../../Components/Svgicons/ExcelIcon";
import FileIcon from "../../Components/Svgicons/FileIcon";
import PdfIcon from "../../Components/Svgicons/PdfIcon";
import { formatDistanceToNow } from 'date-fns';


// export const isValidEmail = (value) =>
//   /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
export const DefaulImage = {
  image: "https://smartrecroot-prod.s3.eu-west-1.amazonaws.com/public/occy/Avatar.svg",
  document: <DefaultFileIcon />
};

export const downloadUrl = `${process.env.REACT_APP_API_URL}/api/document/download?fileDescriptor=`

export const handleErrorImg = async (e) => {
  e.target.onerror = null;
  e.target.src =
    "https://smartrecroot-prod.s3.eu-west-1.amazonaws.com/public/occy/Avatar.svg";
};

export const USERTYPE = {
  NOMINEE: "NOMINEE",
  CLIENT: "CLIENT",
  ADMIN: "admin"
};

export const STATUSES = {
  LIVE: 2,
  PENDING: 1,
};

export function stripTags(html) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
}

export const getRelativeTime = (timestamp) => {
  const distance = formatDistanceToNow(new Date(timestamp), { addSuffix: true });

  return distance
    .replace(' seconds', ' sec')
    .replace(' minutes', ' min')
    .replace(' hours', ' hours')
    .replace(' days', ' days')
    .replace(' months', ' months')
    .replace(' years', ' years');
};


export const chatArray = [
  {
    id: 1,
    sender: "You",
    message: "Hello, how are you?",
    image: "https://cdn-icons-png.flaticon.com/512/147/147142.png",
    date: "Friday 11:02 pm",
  },
  {
    id: 4,
    sender: "Svenskatestamenten",
    message: "I have a question about your product.",
    image: "https://cdn-icons-png.flaticon.com/512/147/147142.png",
    date: "Friday 11:02 pm",
  },
  {
    id: 5,
    sender: "You",
    message: "I have a question about your product.",
    image: "https://cdn-icons-png.flaticon.com/512/147/147142.png",
    date: "Friday 11:02 pm",
  },
  {
    id: 6,
    sender: "Svenskatestamenten",
    message: "I have a question about your product. Sure, feel free to ask!",
    image: "https://cdn-icons-png.flaticon.com/512/147/147142.png",
    date: "Friday 11:02 pm",
  },
  {
    id: 7,
    sender: "You",
    message: "... your question here ...",
    image: "https://cdn-icons-png.flaticon.com/512/147/147142.png",
    date: "Friday 11:02 pm",
  },
  // Add more chat messages as needed
];

// export const uuidv4 = () =>
//   ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
//     (
//       c ^
//       (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
//     ).toString(16),
//   );

// export const getRandomInteger = (num) => Math.floor(Math.random() * num) + 1;
// export const getActiveTabId = (tabs) => tabs?.find((t) => t.active).id;
// export const handleChangeTab = (tabs, clicked) =>
//   tabs?.map((p) => {
//     return p.id === clicked
//       ? {
//         ...p,
//         active: true,
//       }
//       : {
//         ...p,
//         active: false,
//       };
//   });
// export const copyText = (text = 'Text Copied') =>
//   navigator.clipboard.writeText(text);

// export const getStatusFriendly = (statusId = 1, obj = STATUSES) =>
//   capitalizeFirstLetter(
//     startCase(Object.keys(obj).find((key) => obj[key] === statusId)),
//   );

// export const getCurrencySign = (name = 'GBP', obj = CURRENCY) => obj[name];

// export const capitalizeFirstLetter = (string) =>
//   string
//     ? string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
//     : '';

// export const getPreviewURL = (url) => {
//   const msDocs = ['doc', 'docx', 'ppt', 'pptx'];
//   const reg = url.match(/\.([^\\./?]+)($|\?)/);
//   const isMsg = reg && reg.length >= 2 && msDocs.indexOf(reg[1]) !== -1;
//   return isMsg
//     ? `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
//       url,
//     )}&wdStartOn=1&wdEmbedCode=0&wdPrint=1`
//     : `https://docs.google.com/viewer?url=${encodeURIComponent(
//       url,
//     )}&embedded=true&hgd=1`;
// };

// export const getFileNameByFileDescriptor = (name) => {
//   if (name) {
//     var filename = name?.replace(/^.*[\\\/]/, '');
//     var extension = isArray(filename) ? filename?.match(/\.[0-9a-z]+$/i)[0] : filename?.match(/\.[0-9a-z]+$/i);
//     const myRe = new RegExp('\\b(uuid:){0,1}s*([a-f0-9\\-]*){1}\\s*', 'g');
//     filename = filename?.replace(myRe, '');
//     // filename = filename.exec((/\.[0-9a-z]+$/i)[0]);
//     return extension ? filename?.substr(0, filename?.lastIndexOf('.')) + extension : filename;
//     // const myRe = new RegExp('(?=\\w+\\.\\w{3,4}$).+', 'g');
//     // return myRe.exec(name);
//   }
// };

// export const getAppEnvironment = () => {
//   const env = process.env.REACT_APP_ENVIRONMENT;

//   if (env === 'production') {
//     return {
//       IsDevelopment: false,
//       IsProduction: true,
//     };
//   } else {
//     return {
//       IsDevelopment: true,
//       IsProduction: false,
//     };
//   }
// };
export const filterHtml = (data) => {
  return (
    <div className="rendered-html" dangerouslySetInnerHTML={{ __html: data }} />
  );
};
// export const addAttributToAnchorTag = (htmlString) => {
//   // create a temporary element to parse the HTML string
//   const tempElement = document.createElement('div');
//   tempElement.innerHTML = htmlString;

//   // find all anchor tags inside the temporary element
//   const anchorTags = tempElement.getElementsByTagName('a');
//   if (isArray(anchorTags) && anchorTags?.length > 0) {
//     // loop through the anchor tags and add the attribute
//     for (let i = 0; i < anchorTags?.length; i++) {
//       anchorTags[i].setAttribute('target', '_blank');
//     }
//   }

//   return <div dangerouslySetInnerHTML={{ __html: tempElement.innerHTML }} />;
// };
// export const domainSelect = () => {
//   const hostname = window.location.hostname?.split(':')[0];
//   const domain = hostname?.split('.').slice(-3).join('.');
//   return domain;
// };
// export const useQuery = () => new URLSearchParams(useLocation().search);

// export const stripHtmlTags = (html) =>
//   isString(html) ? html?.replace(/<[^>]*>/g, '') : html;
// //Forms and dynamic fields control types
// export const ControlTypes = {
//   SECTION: 'section',
//   HEADING: 'heading',
//   SUB_HEADING: 'sub_heading',
//   TEXT_INPUT: 'text',
//   NUMBER_INPUT: 'number',
//   PARAGRAPH: 'description',
//   TEXTAREA: 'textarea',
//   RADIO: 'radio',
//   CHECKBOX: 'checkbox',
//   DROPDOWN: 'select',
//   EMAIL: 'email',
//   DATE: 'date',
//   FILE_UPLOAD: 'file',
//   EDUCATION: 'education',
//   EXPERIENCE: 'experience',
//   COUNTRY: 'country',
//   REFEREE: 'referee',
// };

export function useWindowSize() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  function debounce(func, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  useEffect(() => {
    const handleResize = debounce(() =>
      setIsSmallScreen(window.innerWidth < 768),
    );
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isSmallScreen;
}

// export const getLabel = (value) => {
//   let val = BgChecksTypes?.find((item) => item?.value === value.toString())?.label;
//   return val;
// };

// export const dragStart = (ev, type = 'text/plain', ref, isFocus = false) => {
//   ev.dataTransfer.effectsAllowed = 'none';
//   ev.dataTransfer.setData(type, ev.target.getAttribute('id'));
//   if (ref?.current) {
//     if (isFocus) ref?.current?.focus()
//     else ref?.current?.blur()
//   }

// };

export const sort = [
  {
    id: 1,
    label: "None",
    value: "createdAt",
    dir: "DESC",
    match: "checked1",
  },
  {
    id: 2,
    label: "Oldest to Newest",
    value: "createdAt",
    dir: "ASC",
    match: "checked2",
  },
  {
    id: 3,
    label: "Newest to Oldest",
    value: "createdAt",
    dir: "DESC",
    match: "checked3",
  },
];



export const fonts = {
  montserrat: "Montserrat",
  raleway: "Raleway",
  quicksand: "Quicksand",
  lato: "Lato"
};

export function formatFileSize(bytes) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i];
}

export const capitalizeFirstLetter = (string) =>
  string
    ? string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase()
    : "";

export function getIconByFileExtension(filename, height, width) {

  const extension = filename?.split(".")?.pop()?.toLowerCase();

  // Define the mapping of file extensions to icon names or URLs
  const iconMapping = {
    xls: <ExcelIcon />,
    xlsx: <ExcelIcon />,
    csv: <ExcelIcon />,
    pdf: <PdfIcon />,
    doc: <FileIcon />,
    docx: <FileIcon height={height} width={width} />,
    // Add more extensions and corresponding icons as needed
  };

  // Check if the extension exists in the mapping
  if (extension in iconMapping) {
    return iconMapping[extension];
  } else {
    // If the extension is not found in the mapping, return a default icon
    return <DefaultFileIcon />;
  }
}


export function TableFileExtensionIcon(filename) {

  const extension = filename?.split(".")?.pop()?.toLowerCase();

  // Define the mapping of file extensions to icon names or URLs
  const iconMapping = {
    xls: <ExcelIcon height={16} width={12} />,
    xlsx: <ExcelIcon height={16} width={12} />,
    csv: <ExcelIcon height={16} width={12} />,
    pdf: <PdfIcon height={16} width={12} />,
    doc: <FileIcon height={16} width={12} />,
    docx: <FileIcon height={16} width={12} />,
    // Add more extensions and corresponding icons as needed
  };

  // Check if the extension exists in the mapping
  if (extension in iconMapping) {
    return iconMapping[extension];
  } else {
    // If the extension is not found in the mapping, return a default icon
    return <DefaultFileIcon height={14} width={12} />;
  }
}

export function getFileExtension(filename) {
  if (filename) {
    const parts = filename?.split(".");
    return parts.length > 1 ? parts[parts.length - 1] : "";
  }
}



export const handleDownload = async (item) => {
  if (item) {
    window.open(downloadUrl + item?.fileDescriptor, "_blank")
  }
};

export const handleViewDocs = async (fileUrl) => {
  const viewUrl = fileUrl;
  window.open(viewUrl, "_blank");
};


export function MobileScreen() {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth < 575);

  function debounce(func, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

  useEffect(() => {
    const handleResize = debounce(
      () => setIsMobileScreen(window.innerWidth < 575),
      250 // Adjust the delay as needed
    );

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobileScreen;
}

export const getRandomInteger = (num) => Math.floor(Math.random() * num) + 1;
