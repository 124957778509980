const ExcelIcon = ({
  height = "42",
  width = "33",
  rotate = "down",
  transition,
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.35714 0H22.7857L33 10.5V42H2.35714V0Z" fill="#ECECEC"/>
<path d="M0 5.24665H20.4286V16.5543H0V5.24665Z" fill="#649E36"/>
<path d="M1.96596 13.3236V9.21168H4.56752V9.8361H2.69057V10.9524H4.43276V11.5768H2.69057V12.6992H4.58315V13.3236H1.96596Z" fill="#ECECEC"/>
<path d="M5.91225 9.21168L6.79311 10.7175H6.82436L7.70912 9.21168H8.5353L7.30287 11.2676L8.55483 13.3236H7.71498L6.82436 11.8278H6.79311L5.90248 13.3236H5.06655L6.33022 11.2676L5.08217 9.21168H5.91225Z" fill="#ECECEC"/>
<path d="M12.383 10.599H11.6525C11.6316 10.4759 11.5932 10.3668 11.5372 10.2718C11.4813 10.1754 11.4116 10.0938 11.3283 10.0268C11.2449 9.95991 11.1499 9.90971 11.0431 9.87625C10.9376 9.84145 10.8237 9.82405 10.7013 9.82405C10.4839 9.82405 10.2912 9.88027 10.1232 9.9927C9.95522 10.1038 9.82371 10.2671 9.72865 10.4826C9.6336 10.6968 9.58608 10.9584 9.58608 11.2676C9.58608 11.5822 9.6336 11.8472 9.72865 12.0627C9.82501 12.2769 9.95652 12.4388 10.1232 12.5486C10.2912 12.657 10.4832 12.7112 10.6994 12.7112C10.8191 12.7112 10.9311 12.6951 11.0353 12.663C11.1408 12.6296 11.2352 12.5807 11.3185 12.5165C11.4031 12.4522 11.4741 12.3732 11.5314 12.2795C11.59 12.1858 11.6303 12.0788 11.6525 11.9583L12.383 11.9623C12.3556 12.1577 12.2964 12.3411 12.2052 12.5124C12.1154 12.6838 11.9975 12.835 11.8517 12.9662C11.7059 13.096 11.5353 13.1978 11.34 13.2714C11.1447 13.3436 10.9279 13.3798 10.6896 13.3798C10.338 13.3798 10.0242 13.2961 9.74819 13.1288C9.47215 12.9615 9.2547 12.7199 9.09584 12.404C8.93699 12.0881 8.85756 11.7093 8.85756 11.2676C8.85756 10.8246 8.93764 10.4458 9.0978 10.1312C9.25795 9.81535 9.47605 9.57375 9.75209 9.40644C10.0281 9.23912 10.3406 9.15547 10.6896 9.15547C10.9122 9.15547 11.1193 9.18759 11.3107 9.25184C11.5021 9.31609 11.6727 9.41045 11.8224 9.53493C11.9721 9.65807 12.0952 9.80933 12.1915 9.98868C12.2892 10.1667 12.353 10.3702 12.383 10.599Z" fill="#ECECEC"/>
<path d="M13.0465 13.3236V9.21168H15.6481V9.8361H13.7711V10.9524H15.5133V11.5768H13.7711V12.6992H15.6637V13.3236H13.0465Z" fill="#ECECEC"/>
<path d="M16.3873 13.3236V9.21168H17.112V12.6992H18.8737V13.3236H16.3873Z" fill="#ECECEC"/>
<path d="M7.07143 18.5769H27.5V20.1923H7.07143V18.5769Z" fill="#CCCCCC"/>
<path d="M7.07143 22.6154H27.5V24.2308H7.07143V22.6154Z" fill="#CCCCCC"/>
<path d="M7.07143 26.6538H27.5V28.2692H7.07143V26.6538Z" fill="#CCCCCC"/>
<path d="M7.07143 30.6923H27.5V32.3077H7.07143V30.6923Z" fill="#CCCCCC"/>
<path d="M7.07143 34.7308H27.5V36.3462H7.07143V34.7308Z" fill="#CCCCCC"/>
<path d="M21.9981 10.5975C22.0781 7.55023 22.2783 2.62583 22.359 0.534447C22.3727 0.179831 22.7965 0.0104384 23.0408 0.261211L32.84 10.3205C33.0878 10.5748 32.9125 11.0101 32.5624 11.0101H22.3904C22.1696 11.0101 21.9922 10.8244 21.9981 10.5975Z" fill="#CCCCCC"/>
</svg>

  );
};

export default ExcelIcon;
