import React from "react";
import { Modal } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { fonts } from "../../Services/Utils/helper";
import CartIcon from "../../Components/Svgicons/CartIcon";
import CrossIcon from "../../Components/Svgicons/CrossIcon";

const PaymentModal = ({
  show,
  onHide,
  handleDelete,
  onClose,
  disabled,
  loading,
  descriptionText,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} centered onHide={onHide} size={"lg"} backdrop="static">
      <Modal.Body className="d-flex flex-column bg-body rounded">
        <div className="d-flex justify-content-end cursor-pointer" onClick={onClose}>
      <CrossIcon/>
        </div>
        <div className="d-flex flex-column align-items-center p-0 p-md-5 pt-md-0">
          <StyledText
            $fontSize="20px"
            $fontWeight={600}
            color={theme.secondary}
            className="text-center"
          >
            {t("client:accept&Continue")}
          </StyledText>
          <StyledText
            $fontSize="14px"
            $fontWeight={400}
            color={theme.gray}
            className="text-center"
          >
            {t("client:purchaseMembership")}
          </StyledText>

          <hr className="mt-3 w-100" />

          <PaymentContainer className="my-4">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <div className="h-25">
                <StyledText
                  as='label'
                  $fontSize="94px"
                  $fontWeight={800}
                  color={theme.secondary}
                  fontFamily={fonts.lato}
                  lh={1}
                >
                  99
                </StyledText>
                <StyledText
                  as='label'
                  $fontSize="12px"
                  $fontWeight={500}
                  color={theme.gray}
                  $fontFamily={fonts.raleway}
                  className="text-center"
                >
                  SEK
                </StyledText>
              </div>
              <StyledText $fontSize="12px"
                $fontWeight={500}
                color={theme.gray}
                $fontFamily={fonts.raleway}
                className="text-center"
              >            {t("client:reviveYourPlan")}
              </StyledText>
            </div>


            <div className="d-flex flex-column align-items-center text-center mt-4 px-md-5 px-2">
              <StyledText
                $fontSize="14px"
                $fontWeight={600}
                color={theme.secondary}
              >{t("client:outOfStock")}</StyledText>
              <StyledText $fontSize="12px"
                $fontWeight={400}
                color={theme.doveGray}>{t("client:paymentDescription")} </StyledText>
            </div>
          </PaymentContainer>


          <StyledButton
            icon={<CartIcon />}
            variant="primary"
            disabled={disabled}
            loading={loading}
            className="w-100"
            onClick={onHide}
          >
            {t("client:accept&Continuefor99")}
          </StyledButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};


const PaymentContainer = styled.div`

background: #C77B211A;
border :  1px dashed #C77B21;
width : 100%;
border-radius: 5px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding:20px;
`

export default PaymentModal;
