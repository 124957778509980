const EditIcon = ({ size = 21, rotate = "down",color="white" ,transition, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0495 3.00002L6.2078 10.2417C5.94947 10.5167 5.69947 11.0584 5.64947 11.4334L5.34114 14.1333C5.2328 15.1083 5.9328 15.775 6.89947 15.6084L9.5828 15.15C9.9578 15.0834 10.4828 14.8084 10.7411 14.525L17.5828 7.28335C18.7661 6.03335 19.2995 4.60835 17.4578 2.86668C15.6245 1.14168 14.2328 1.75002 13.0495 3.00002Z"
        stroke={color}
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.9082 4.20831C12.2665 6.50831 14.1332 8.26665 16.4499 8.49998"
        stroke={color}
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 18.3333H19.5"
        stroke={color}
        strokeWidth="1.3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
