import { apiSlice } from "../../ApiSlice/apiSlice";

const LOGIN_URL = "/backoffice/bankId";

export const AdminLoginApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminSameDeviceData: builder.mutation({
      query: (params) => ({
        url: `${LOGIN_URL}/auth?isSameDevice=${params}`,
        method: "GET",
      }),
    }),

    getAdminAnotherDeviceData: builder.mutation({
      query: (param) => ({
        url: `${LOGIN_URL}/auth`,
        method: "GET",
      }),
    }),

    getAdminOrderRef: builder.mutation({
      query: (params) => ({
        url: `${LOGIN_URL}/collect?orderRef=${params}`,
        method: "GET",
      }),
    }),

    getAdminQrCodeData: builder.mutation({
      query: (params) => ({
        url: `${LOGIN_URL}/order-status?orderRef=${params}`,
        method: "GET",
      }),
    }),

    adminLogin: builder.mutation({
      query: (data) => ({
        url: `/backoffice/admin/self/login?email=${data.email}&personalNumber=${data.personalNumber}`,
        method: "GET",
      }),
    }),

  }),
});

export const {
  useGetAdminSameDeviceDataMutation,
  useGetAdminOrderRefMutation,
  useGetAdminAnotherDeviceDataMutation,
  useGetAdminQrCodeDataMutation,
  useAdminLoginMutation
} = AdminLoginApiSlice;
