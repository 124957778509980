import React from 'react'
import Table from '../../Components/Table/Table';

const CommonTable = ({
    data,
    column
}) => {
    return (
        <Table
    
            data={data}
            columns={column}
        />
    );
};
export default CommonTable