import styled from 'styled-components';
import theme from '../../../Services/Utils/themes';

const StyledSelectCss = styled.div`
  width: 100%;

  .async-select {
    width: 100%;

    [class*='-container'] {
      width: 100%;
    }

    [class$='-menu'] {
      ::-webkit-scrollbar {
        width: 7px;
        background-color: ${({ theme }) => theme.bodyBg};
      }

      ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.inputBorder};
        border-radius: 5px;
      }

      ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px ${({ theme }) => theme.inputColor};
        background-color: ${({ theme }) => `${theme.white}da`};
      }

      background-color: ${({ theme }) => theme.white} !important;
      box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
      border: none !important;
      font-size: 12px;
      font-weight: 600;
      color: ${({ theme }) => theme.headingColor};
      padding: 10px 25px;
      border-radius: 7px;

      &:active,
      &:focus {
        box-shadow: none !important;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        color: ${({ theme }) => theme.headingColor};
      }
    }

    [class$='-control'] {
      border:1px solid ${({ theme }) => theme.pastelGray} !important;
      box-shadow: none !important;
      border-radius: ${({borderRadius}) => borderRadius ? borderRadius : '5px'};
      font-size: 0.9rem;
      height: 100%;
      cursor: pointer;
      padding-block: 2px;
    }

    &.application {
      [class$='-control'] {
        border: none;
      }
    }

    &.publicForm {
      [class$='-menu'] {
        background: ${({ theme }) =>
          `linear-gradient(to bottom, ${theme?.backgroundColor}, ${theme?.backgroundColor}db)`};

        @include shadow();
        border: none !important;
        font-size: 12px;
        font-weight: 600;

        [class$='-option'] {
          color: ${({ theme }) => theme.primary};
          margin-bottom: 2px;

          &:active,
          &:focus {
            box-shadow: none !important;
          }

          &:focus {
            outline: none;
          }

          &:hover {
            color: ${({ theme }) => theme.primary};
          }
        }
      }

      [class$='-control'] {
        border: none;

        background-color: transparent;
        color: ${({ theme }) => theme.inputBorder} !important;
        border-bottom: 1px solid;
        border-color: ${({ theme }) => theme.primary} !important;
        border-radius: 0;

        [class$='-indicatorContainer'] {
          color: ${({ theme }) => theme.primary} !important;
        }

        [class$='-ValueContainer'] {
          padding: 2px 2px 2px 10px;

          [class$='-singleValue'] {
            color: ${({ theme }) => theme.primary} !important;
            font-weight: 500;
          }

          [class$='-placeholder'] {
            color: ${({ theme }) => theme.primary} !important;
          }
        }

        &:hover,
        &:active,
        &:focus {
          border-bottom: 2px solid ${({ theme }) => theme.white};
        }
      }
    }

    &.customBorder {
      [class$='-control'] {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    [class$='-indicatorContainer'] {
      padding: 5px 10px 5px 5px;
    }

    [class$='-ValueContainer'] {
      padding: 2px 2px 2px 10px;
    }

    [class$='-placeholder'] {
      color: ${({ theme }) => theme.inputPlaceHolder};
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 85%;
    }

    [class$='-indicatorSeparator'] {
      display: none;
    }

    [class$='-multiValue'] {
      border-radius: 6px;
      background-color: #e0f2f1;
      padding: 0 4px;
      font-weight: 600;
      font-size: 12px;
      margin-right: 5px;

      [role='button'] {
        padding-right: 2px;

        &:hover {
          color: ${({ theme }) => theme.headingColor};
        }
      }

      div:last-child:hover {
        background-color: transparent;
      }

      &:nth-child(even) {
        background: #fcf1e9;
      }
    }

    &.is-invalid {
      [class$='-control'] {
        border-color: ${({ theme }) => theme.danger} !important;
      }
    }

    //&.is-valid {
    //  [class$='-control'] {
    //    border-color: $success !important;
    //  }
    //}

    &.pay {
      width: max-content;
    }

    &.is-light-pink {
      [class$='-control'] {
        border-color: transparent;
        font-weight: 600;
        background-color: ${({ theme }) => theme.primaryLight} !important;
        color: ${({ theme }) => theme.primary} !important;
      }

      [class$='-indicatorContainer'] {
        color: ${({ theme }) => theme.primary} !important;
      }
    }
  }
`;

export default StyledSelectCss;


export const StyledDropdownCard = styled.div`
position:relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding:20px 10px;
  background-color: ${theme.primaryLight};
width: 138px;
height: 134px;
border-radius: 8px;
border: ${({ isSelected }) => isSelected ? `1px solid ${theme.primary}` : `1px solid ${theme.pastelGray}`};

`

export const StyledSelectOption = styled.div`
position:absolute;
top:5px;
right:8px;
  

`