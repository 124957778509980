import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAuth from "../Services/Hooks/useAuth";
import { svenskaLs } from "../Services/Utils/localStorage";
import { USERTYPE } from "../Services/Utils/helper";
import CommonOutlet from "../Layouts/AppOutlet/Outlet";

function ProtectedRoute() {
  const { user } = useAuth();
  let userType = svenskaLs.getObject("userType");
  if (
    user &&
    (userType === USERTYPE?.CLIENT || userType === USERTYPE?.NOMINEE)
  ) {
    return (
      <CommonOutlet>
        <Outlet />
      </CommonOutlet>
    );
  }

  return <Navigate to="/login" />;
}

export default ProtectedRoute;
