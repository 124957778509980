const AttachmentIcon = ({
  size = 20,
  rotate = "down",
  transition,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.97494 10V12.9167C9.97494 14.525 11.2833 15.8333 12.8916 15.8333C14.4999 15.8333 15.8083 14.525 15.8083 12.9167V8.33333C15.8083 5.10833 13.1999 2.5 9.97494 2.5C6.74994 2.5 4.1416 5.10833 4.1416 8.33333V13.3333C4.1416 16.0917 6.38327 18.3333 9.1416 18.3333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AttachmentIcon;
