import React from "react";
import { Modal, Row } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import DeleteIcon from "../../Components/Svgicons/DeleteIcon";
import { useTranslation } from "react-i18next";
import DeleteWarningIcon from "../../Components/Svgicons/DeleteWarningIcon";
import { Form, Formik } from "formik";
import FormikInputField from "../../Components/Formik/FormikInputField";

const ConfirmDeleteModal = ({
  show,
  onHide,
  handleDelete,
  disabled,
  loading,
  descriptionText,
  placeholderText
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} centered onHide={onHide} size={"lg"} backdrop="static">
      <Modal.Body className="d-flex flex-column bg-body rounded">
        <div className="d-flex gap-3 flex-column align-items-center p-3 p-sm-5">
          <div>
            <DeleteWarningIcon />
          </div>
          <StyledText
            $fontSize="20px"
            $fontWeight={600}
            color={theme.secondary}
            className="text-center"
          >
            {t("common:textTitleModalDelete")}
          </StyledText>
          <StyledText
            $fontSize="16px"
            $fontWeight={400}
            color={theme.gray}
            className="text-center px-4"
          >
            {descriptionText}
          </StyledText>

          <div className="w-100">
            <Formik
              initialValues={{
                delete: "",
              }}
              onSubmit={handleDelete}
            >
              {({values}) => (
                <Form>

                  <Row>
                    <div className='col my-3'>
                      <FormikInputField
                        name='delete'
                        id='delete'
                        placeholder={placeholderText}
                      />
                    </div>
                  </Row>

                  <div className="d-flex align-items-center justify-content-center gap-3 mt-4">
                    <StyledButton
                    icon={<DeleteWarningIcon size={20} color={theme.secondary}/>}
                      variant={"silver"}
                      className=" border-0 text-black   px-4"
                      onClick={onHide}
                    >
                      {t("common:textBtnCancle")}
                    </StyledButton>
                    <StyledButton
                    icon={<DeleteIcon size={20} color={theme.white}/>}

                      type="submit"
                      disabled={disabled}
                      loading={loading}
                      className={` bg-fireBrick  border-0 text-white  px-4 ${(values?.delete === "Yes" || values?.delete === "yes" || values?.delete === "ta bort") ? "" : "feature-pending"}`}
                    >
                      {t("common:textBtnDelete")}
                    </StyledButton>
                  </div>


                </Form>
              )}
            </Formik>
          </div>


        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmDeleteModal;
