import React, { useState } from "react";
import DeleteModal from "../../Modals/DeleteModal";
import AddClientModal from "../../Modals/AddClientModal";
import {
  useAdminRequestDocumentMutation,
  useDeleteClientMutation,
  useGetAdminRequestedDocsQuery,
  useGetAdminRequestStatusMutation,
  useGetUserListQuery,
} from "../../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import * as Yup from 'yup';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { svenskaLs } from "../../../Services/Utils/localStorage";
import MenuSection from "./MenuSection";
import AdminClientTable from "./AdminClientTable";
import { AdminCommonContainer } from "../../../Components/StyledComponents/CommonStyled";
import DocumentPostingModal from "../../Modals/DocumentPostingModal";
import PaymentModal from "../../Modals/PaymentModal";

const ClientWorkplace = () => {
  const { t } = useTranslation();
  const [deleteClient, { isLoading: deleteLoading }] =
    useDeleteClientMutation();
  const lang = svenskaLs.getItem("lng") || "swe";
  const [deleteData, setDeleteData] = useState(false);
  const [uID, setUId] = useState("");
  const [userDetails, setUserDetails] = useState("");

  const [timer, setSearchTimer] = useState(null);
  const [addClintModalOpen, setaddClintModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState({ data: {}, state: false });
  const [sorted, setSorted] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [getStatus, { isLoading: isLoadingStatus }] = useGetAdminRequestStatusMutation();
  const [requestDocument, { isLoading: requestLoading }] = useAdminRequestDocumentMutation();

  const initialFilters = {
    perPage: 5,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [filters, setFilters] = useState({ ...initialFilters });
  const [listing, setListing] = useState("");
  const {
    data: getUserList,
    refetch: refetchUsers,
    isLoading
  } = useGetUserListQuery(
    {
      perPage: filters.perPage,
      page: filters.page,
      query: filters.query && filters.query,
      sort: filters.sortedColumn.path || listing.sort,
      sortDir: filters.sortedColumn.order || listing.sortDir,
      ...listing,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );




  const handlePerPageChange = (perPage) => {
    setFilters((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setFilters((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setFilters({ ...filters, page: 1, query: query });
    }, 1000);
    setSearchTimer(newTimer);
  };

  const handleSortBy = (item) => {
    setSorted(item.match);
    const prevFilters = { ...listing };
    prevFilters.sort = item.value;
    prevFilters.sortDir = item.dir;
    setListing({ ...prevFilters });
  };

  const handleDelete = () => {
    const data = {
      userId: uID,
      trans: lang,
    };
    deleteClient(data).then((Resp) => {
      if (getUserList?.data?.length === 1 && getUserList?.page > 1) {
        // If only one item was on the current page and it is deleted, move to the previous page
        setFilters((prevState) => ({
          ...prevState,
          page: prevState?.page - 1, // Go to the previous page
        }));
      } else {
        refetchUsers(); // Refetch the current page
      }

      setDeleteData(false);
      toast.success(t("admin:ClientDeletedSuccessfully"));
    });
  };

  const handleConditionModal = (userDetail) => {

    setUserDetails(userDetail)

    getStatus(userDetail?.id).then(({ data }) => {
      if (data?.isFree) {
        setShowModal(true);
      }
      else {
        setShowPaymentModal(true);
      }

    })



  }

  const handleSubmit = (values) => {    
    values.clientId = userDetails?.id; 
    values.fileId = values?.fileId?.map(item => item?.value);
    requestDocument(values).then((resp) => {
        toast.success(t('common:documentPostingToast'));
        setShowModal(false);
    }
    ).catch((e) => toast.error(e.message));

  }

  const validationSchema = Yup.object().shape({
    fileId: Yup.array().min(1, t("common:selectAtleast")).required("required")
      .required(t("common:selectAtleast")),
    name: Yup.string().required('Name is required'),
    phoneNumber: Yup.string()
      .required(t("common:phoneRequired"))
      .matches(/^[0-9]+$/, t("common:phoneNumberOnlyDigits")),
    email: Yup.string()
      .required(t("common:emailRequired"))
      .email(t("common:invalidEmail")),
    country: Yup.string().required(t("common:countryRequired")),
    // city: Yup.string().required(t("common:cityRequired")),
    town: Yup.string().required(t("common:townRequired")),
    streetAddress: Yup.string().required(t("common:streetAddressRequired")),
    postalCode: Yup.string().required(t("common:postalCodeRequired")),
  });

  return (
    <AdminCommonContainer >
      <MenuSection sorted={sorted} handleSortBy={handleSortBy} handleSearch={handleSearch} setAddClientModal={setaddClintModalOpen} setIsEdit={setIsEdit} />
      <AdminClientTable clientDetails={getUserList} handlePerPageChange={handlePerPageChange} handlePageChange={handlePageChange} setIsEdit={setIsEdit} setaddClientModalOpen={setaddClintModalOpen} setDeleteData={setDeleteData} setUId={setUId} loadClients={isLoading} refetchUsers={refetchUsers} handleConditionModal={handleConditionModal} loader={isLoadingStatus} />
      {/* <StyledTableSkeleton cols={column} isFilter={true} /> */}
      <DeleteModal
        show={deleteData}
        onHide={() => setDeleteData(false)}
        handleDelete={handleDelete}
        disabled={deleteLoading}
        loading={deleteLoading}
      />
      {addClintModalOpen && (
        <AddClientModal
          show={addClintModalOpen}
          onHide={() => setaddClintModalOpen(false)}
          refetchUsers={refetchUsers}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
        />
      )}

      {showModal && <DocumentPostingModal show={showModal} onHide={() => setShowModal(false)} handleSubmit={handleSubmit} validationSchema={validationSchema} user={userDetails} loading={requestLoading} disableLoading={requestLoading}/>}
      {showPaymentModal && <PaymentModal show={showPaymentModal} onHide={() => { setShowPaymentModal(false); setShowModal(true) }} onClose={() => setShowPaymentModal(false)} />}
    </AdminCommonContainer>
  );
};


export default ClientWorkplace;
