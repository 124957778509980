import React from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { Row } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import EditIcon from "../../Components/Svgicons/EditIcon";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";

function UserDetails({ setEditProfile, user, setActiveUploadIcon }) {
  const { t } = useTranslation('common');
  return (
    <>
      <div className="border rounded-3 p-4 mt-4">
        <StyledText $fontSize="18px" $fontWeight={600} color={theme.secondary}>
          {t('textPersonalInfo')}
        </StyledText>
        <Row className="mt-4">
          <div className="col-12 col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('textFirstName')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.firstName || ""}
              </StyledText>
            </div>
          </div>
          <div className="col-12  col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('textLastName')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.lastName || ""}
              </StyledText>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('textEmailAddress')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
                noTextTransform
              >
                {user?.email || ""}
              </StyledText>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('textRegistrationDate')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.createdAt ? moment(user?.createdAt).format("DD / M / YYYY") : "-"}
              </StyledText>
            </div>
          </div>
        </Row>
      </div>

      <div className="border rounded-3 p-4 mt-4">
        <StyledText $fontSize="18px" $fontWeight={600} color={theme.secondary}>
        {t('textAddress')}
        </StyledText>
        <Row className="mt-4">
          <div className="col-12 col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
                {t('textCountry')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.country || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('textCityState')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.city || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('textPostalCode')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.postalCode || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('textPersonalNo')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.personalNumber || "-"}
              </StyledText>
            </div>
          </div>
        </Row>
      </div>
      <StyledButton
        icon={<EditIcon />}
        className="text-white rounded-2 mt-3"
        onClick={() => {
          setEditProfile(true);
          setActiveUploadIcon(true);
        }}
      >
        {t('textBtnEditProfile')}
      </StyledButton>
    </>
  );
}

export default UserDetails;
