const ResourcesIcon = ({ size = 22, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.9258 2.67646L17.3341 5.07813C18.8925 5.76563 18.8925 6.9023 17.3341 7.5898L11.9258 9.99146C11.3116 10.2665 10.3033 10.2665 9.68914 9.99146L4.28081 7.5898C2.72248 6.9023 2.72248 5.76563 4.28081 5.07813L9.68914 2.67646C10.3033 2.40146 11.3116 2.40146 11.9258 2.67646Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.75 10.0835C2.75 10.8535 3.3275 11.7427 4.03333 12.0543L10.2575 14.8227C10.7342 15.0335 11.275 15.0335 11.7425 14.8227L17.9667 12.0543C18.6725 11.7427 19.25 10.8535 19.25 10.0835" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2.75 14.6665C2.75 15.519 3.25417 16.289 4.03333 16.6373L10.2575 19.4057C10.7342 19.6165 11.275 19.6165 11.7425 19.4057L17.9667 16.6373C18.7458 16.289 19.25 15.519 19.25 14.6665" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
    

  );
};

export default ResourcesIcon;
