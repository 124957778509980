const TickIcon = ({
  width = 30,
  height = 24,
  rotate = "down",
  transition,
  ...props
}) => {
  return (
    <svg
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 4.83L4.83 8.66L12.5035 1"
        stroke="#C77B21"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickIcon;
