import { apiSlice } from "../../../ApiSlice/apiSlice";

const ADMIN_URL = "/backoffice";

export const AdminWPApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminConversation: builder.query({
      query: (params) => ({
        url: `${ADMIN_URL}/conversations/`,
        method: "GET",
        params,
      }),
    }),
    postConversation: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/conversations/userId`,
        method: "POST",
        body: params,
      }),
    }),

    getUserList: builder.query({
      query: (params) => ({
        url: `${ADMIN_URL}/conversations`,
        method: "GET",
      }),
    }),

    editUserProfile: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/user`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
    }),

    postNewAdmin: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/admin/create-admin/`,
        method: "POST",
        body: params,
      }),
    }),

    getAdminList: builder.query({
      query: (params) => ({
        url: `${ADMIN_URL}/admin`,
        method: "GET",
        params: params,
      }),
    }),

    getSingleAdmin: builder.query({
      query: (id) => ({
        url: `${ADMIN_URL}/admin/${id}`,
        method: "GET",
      }),
    }),

    deleteSingleAdmin: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/admin/${data.adminId}?trans=${data.trans}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetConversationQuery,
  usePostConversationMutation,
  useGetSharedDocsQuery,
  useEditUserProfileMutation,
  useGetUserListQuery,
  usePostNewAdminMutation,
  useUploadProfileMutation,
  useGetAdminListQuery,
  useGetSingleAdminQuery,
  useDeleteSingleAdminMutation,
  use,
} = AdminWPApiSlice;
