const FileIcon = ({ height = "42", width = "33", rotate = "down", transition, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 51 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.64286 0H35.2143L51 16.25V65H3.64286V0Z" fill="#ECECEC" />
      <path d="M0 7.5H29.1429V25H0V7.5Z" fill="#3B7DC7" />
      <path d="M4.69156 21.25H1.99822V13.0682H4.74589C5.535 13.0682 6.21286 13.232 6.77947 13.5596C7.34867 13.8845 7.78592 14.3519 8.09121 14.9618C8.39651 15.5717 8.54916 16.3015 8.54916 17.1511C8.54916 18.0034 8.39522 18.7358 8.08733 19.3484C7.78204 19.9609 7.34091 20.431 6.76395 20.7586C6.18958 21.0862 5.49878 21.25 4.69156 21.25ZM3.43803 19.9676H4.6217C5.17537 19.9676 5.6372 19.8637 6.00718 19.656C6.37716 19.4456 6.65529 19.1326 6.84157 18.7171C7.02785 18.299 7.12099 17.777 7.12099 17.1511C7.12099 16.5252 7.02785 16.0059 6.84157 15.593C6.65529 15.1776 6.37974 14.8673 6.01494 14.6622C5.65272 14.4545 5.20254 14.3506 4.66439 14.3506H3.43803V19.9676Z" fill="#ECECEC" />
      <path d="M17.0192 17.1591C17.0192 18.0407 16.8588 18.7957 16.538 19.4243C16.2197 20.0502 15.7851 20.5296 15.234 20.8625C14.6855 21.1954 14.0632 21.3619 13.3673 21.3619C12.6713 21.3619 12.0478 21.1954 11.4967 20.8625C10.9482 20.5269 10.5135 20.0462 10.1927 19.4203C9.87448 18.7917 9.71536 18.038 9.71536 17.1591C9.71536 16.2775 9.87448 15.5238 10.1927 14.8979C10.5135 14.2694 10.9482 13.7886 11.4967 13.4557C12.0478 13.1228 12.6713 12.9563 13.3673 12.9563C14.0632 12.9563 14.6855 13.1228 15.234 13.4557C15.7851 13.7886 16.2197 14.2694 16.538 14.8979C16.8588 15.5238 17.0192 16.2775 17.0192 17.1591ZM15.5716 17.1591C15.5716 16.5385 15.4772 16.0152 15.2883 15.589C15.102 15.1602 14.8433 14.8366 14.5121 14.6183C14.181 14.3972 13.7993 14.2867 13.3673 14.2867C12.9352 14.2867 12.5536 14.3972 12.2224 14.6183C11.8912 14.8366 11.6312 15.1602 11.4424 15.589C11.2561 16.0152 11.1629 16.5385 11.1629 17.1591C11.1629 17.7797 11.2561 18.3043 11.4424 18.7331C11.6312 19.1593 11.8912 19.4829 12.2224 19.7039C12.5536 19.9223 12.9352 20.0315 13.3673 20.0315C13.7993 20.0315 14.181 19.9223 14.5121 19.7039C14.8433 19.4829 15.102 19.1593 15.2883 18.7331C15.4772 18.3043 15.5716 17.7797 15.5716 17.1591Z" fill="#ECECEC" />
      <path d="M25.1836 15.8287H23.7321C23.6908 15.5837 23.6144 15.3667 23.5032 15.1776C23.3919 14.9858 23.2535 14.8233 23.0879 14.6902C22.9223 14.557 22.7335 14.4571 22.5213 14.3905C22.3117 14.3213 22.0854 14.2867 21.8422 14.2867C21.4101 14.2867 21.0272 14.3985 20.6934 14.6222C20.3597 14.8433 20.0983 15.1682 19.9095 15.597C19.7206 16.0232 19.6262 16.5439 19.6262 17.1591C19.6262 17.785 19.7206 18.3123 19.9095 18.7411C20.1009 19.1673 20.3622 19.4895 20.6934 19.7079C21.0272 19.9236 21.4088 20.0315 21.8383 20.0315C22.0763 20.0315 22.2988 19.9996 22.5058 19.9356C22.7154 19.8691 22.9029 19.7718 23.0685 19.644C23.2367 19.5162 23.3777 19.359 23.4915 19.1726C23.608 18.9862 23.6882 18.7731 23.7321 18.5334L25.1836 18.5414C25.1293 18.9302 25.0115 19.2951 24.8304 19.636C24.6519 19.9769 24.4178 20.2779 24.128 20.5389C23.8382 20.7972 23.4993 20.9996 23.1112 21.1461C22.7231 21.2899 22.2923 21.3619 21.8189 21.3619C21.1203 21.3619 20.4968 21.1954 19.9483 20.8625C19.3998 20.5296 18.9677 20.0488 18.6521 19.4203C18.3364 18.7917 18.1786 18.038 18.1786 17.1591C18.1786 16.2775 18.3377 15.5238 18.656 14.8979C18.9742 14.2694 19.4075 13.7886 19.956 13.4557C20.5045 13.1228 21.1255 12.9563 21.8189 12.9563C22.2613 12.9563 22.6727 13.0202 23.053 13.1481C23.4333 13.2759 23.7723 13.4637 24.0698 13.7114C24.3673 13.9564 24.6118 14.2574 24.8033 14.6143C24.9973 14.9685 25.1241 15.3733 25.1836 15.8287Z" fill="#ECECEC" />
      <path d="M10.9286 28.75H42.5V31.25H10.9286V28.75Z" fill="#CCCCCC" />
      <path d="M10.9286 35H42.5V37.5H10.9286V35Z" fill="#CCCCCC" />
      <path d="M10.9286 41.25H42.5V43.75H10.9286V41.25Z" fill="#CCCCCC" />
      <path d="M10.9286 47.5H42.5V50H10.9286V47.5Z" fill="#CCCCCC" />
      <path d="M10.9286 53.75H42.5V56.25H10.9286V53.75Z" fill="#CCCCCC" />
      <path d="M33.9971 16.4009C34.1208 11.6849 34.4301 4.06378 34.5548 0.827121C34.576 0.27831 35.2309 0.0161547 35.6084 0.404256L50.7527 15.9721C51.1356 16.3657 50.8648 17.0394 50.3237 17.0394H34.6033C34.2621 17.0394 33.9879 16.752 33.9971 16.4009Z" fill="#CCCCCC" />
    </svg>

  );
};

export default FileIcon;
