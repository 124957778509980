const LogoutIcon = ({ size = 18, rotate = "down",color='#6C6C6C' ,transition, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.67578 5.67005C6.90828 2.97005 8.29578 1.86755 11.3333 1.86755H11.4308C14.7833 1.86755 16.1258 3.21005 16.1258 6.56255V11.4526C16.1258 14.8051 14.7833 16.1476 11.4308 16.1476H11.3333C8.31828 16.1476 6.93078 15.0601 6.68328 12.4051"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.2498 9H2.71484"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.3875 6.48755L1.875 9.00005L4.3875 11.5125"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LogoutIcon;
