import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { getRandomInteger } from '../../Services/Utils/helper'
import theme from '../../Services/Utils/themes'
import { DocumentCard } from '../StyledComponents/CommonStyled'

const CardSkeleton = () => {
    return (
            Array.from(new Array(4))?.map(() => (
            <DocumentCard className='p-3'>
                <div className='d-flex flex-column gap-2 w-100' >

                    <div className='d-flex align-items-center justify-content-between'>

                        <div className='d-flex gap-2'>
                            <div>
                                <Skeleton height={42} width={33} baseColor={theme.lightBlue} // Change the base color here
                                    highlightColor={theme.lavenderGray} />
                            </div>
                            <div>
                                <Skeleton height={13} width={98}  baseColor={theme.lightBlue} // Change the base color here
                                    highlightColor={theme.lavenderGray} />
                                <Skeleton height={13} width={98}  baseColor={theme.lightBlue} // Change the base color here
                                    highlightColor={theme.lavenderGray} />
                            </div>
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-between '>
                            <Skeleton height={15} width={56} baseColor={theme.lightBlue} // Change the base color here
                                highlightColor={theme.lavenderGray} />

                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-between pt-3 border-top  mt-3'>
                        <div className={`d-flex align-items-center justify-content-center cursor-pointer w-100  border-end}`}>
                        <Skeleton height={20} width={20} baseColor={theme.lightBlue} // Change the base color here
                                highlightColor={theme.lavenderGray} />
                        </div>
                        <div className={`d-flex align-items-center justify-content-center cursor-pointer w-100  border-end}`}>
                        <Skeleton height={20} width={20} baseColor={theme.lightBlue} // Change the base color here
                                highlightColor={theme.lavenderGray} />
                        </div>
                </div>

            </DocumentCard>
        ))

    )
}

export default CardSkeleton