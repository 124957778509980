import React from "react";
import { Modal } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import DeleteIcon from "../../Components/Svgicons/DeleteIcon";
import { useTranslation } from "react-i18next";
import DeleteWarningIcon from "../../Components/Svgicons/DeleteWarningIcon";

const DeleteModal = ({
  show,
  onHide,
  handleDelete,
  disabled,
  loading,
  descriptionText,
}) => {
  const { t } = useTranslation();

  return (
    <Modal show={show} centered onHide={onHide} size={"lg"} backdrop="static">
      <Modal.Body className="d-flex flex-column bg-body rounded">
        <div className="d-flex gap-3 flex-column align-items-center p-3 p-sm-5">
          <div className="bg-danger bg-opacity-10 p-4 rounded-circle mb-4">
            <DeleteIcon size={48} color={theme.danger} />
          </div>
          <StyledText
            $fontSize="22px"
            $fontWeight={700}
            color={theme.secondary}
            className="text-center"
          >
            {t("common:textTitleModalDelete")}
          </StyledText>
          <StyledText
            $fontSize="16px"
            $fontWeight={500}
            color={theme.gray}
            className="text-center"
          >
            {descriptionText}
          </StyledText>

          <div className="d-flex align-items-center gap-3 mt-4">
            <StyledButton
            icon={<DeleteWarningIcon size={20} color={theme.secondary}/>}
              variant={"silver"}
              className="border-0 text-black  px-4"
              onClick={onHide}
            >
              {t("common:textBtnCancle")}
            </StyledButton>
            <StyledButton
            icon={<DeleteIcon size={20} color={theme.white}/>}
              disabled={disabled}
              loading={loading}
              className="bg-fireBrick  border-0 text-white px-4"
              onClick={handleDelete}
            >
              {t("common:textBtnDelete")}
            </StyledButton>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteModal;
