import React from 'react';
import AppLogo from "../../../Assets/Images/appLogo.png";
import { ContentSection, ProfileSection, SidebarContainer, SidebarFooter, SidebarHeader, StyledHr, StyledNavLink } from '../../../CommonComponents/SidebarStyling';
import theme from '../../../Services/Utils/themes';
import StyledImage from '../../../Components/StyledComponents/StyledImage';
import { DefaulImage, handleErrorImg } from '../../../Services/Utils/helper';
import StyledText from '../../../Components/StyledComponents/StyledText';
import StyledButton from '../../../Components/StyledComponents/StyledButton';
import LogoutIcon from '../../../Components/Svgicons/LogoutIcon';
import ResourcesIcon from '../../../Components/Svgicons/ResourcesIcon';
import DocumentPostingIcon from '../../../Components/Svgicons/DocumentPostingIcon';
import UserIcon from '../../../Components/Svgicons/UserIcon';
import AdminIcon from '../../../Components/Svgicons/AdminIcon';
import { removeAdminCredentials } from '../../../Services/Auth/adminAuthSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useAdminAuth from '../../../Services/Hooks/useAdminAuth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetUserInfoQuery } from '../../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice';
import { EditProfile } from '../../../Layouts/Sidebar/Sidebar';
import EditIcon from '../../../Components/Svgicons/EditIcon';
import { apiSlice } from '../../../Services/ApiSlice/apiSlice';





const AdminSidebar = ({ isOpen, getAdminProfile }) => {
  const { t } = useTranslation();
  const { id } = useParams();

  const { admin: { isSuperAdmin } } = useAdminAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loc = useLocation();

  const userId = loc?.pathname?.includes('admin-chat') && id;

  const { data: getUserInfo } = useGetUserInfoQuery(userId, {
    skip: !userId,
  });




  const MenuItems = [
    {
      name: t('admin:userWorkplace'),
      to: 'user-workplace',
      icon: <UserIcon />
    },
    ...(isSuperAdmin ? [{
      name: t('admin:adminWorkplace'),
      to: 'admin-workplace',
      icon: <AdminIcon />
    }] : []),
    {
      name: t('common:resources'),
      to: 'admin-resources',
      icon: <ResourcesIcon />

    },
    {
      name: t('common:adminDocumentPosting'),
      to: 'admin-document-posting',
      icon: <DocumentPostingIcon />

    }
  ];

  return (
    <SidebarContainer isOpen={isOpen}>

      <SidebarHeader>
        <img src={AppLogo} alt="appLogo" height={45} />
      </SidebarHeader>

      <ProfileSection>
        <div className='mb-3 position-relative'>
          <EditProfile onClick={() => navigate('/admin-profile')}>
            <EditIcon color='black' size={20} />
          </EditProfile>
          <StyledImage
            height={"76px"}
            width={"76px"}
            className="border border-white rounded-pill"
          >

            <img
              src={getAdminProfile?.data?.signedUrl || DefaulImage.image}
              alt="userProfile"
              onError={handleErrorImg}
              style={{ objectFit: "cover" }}

            />
          </StyledImage>
        </div>
        <StyledText $fontWeight={600} $fontSize="14px" color={theme.white}>{`${getAdminProfile?.data?.firstName || ""} ${getAdminProfile?.data?.lastName || ""
          }`}</StyledText>
        <StyledText $fontWeight={400} $fontSize="12px" color={theme.white} noTextTransform>{getAdminProfile?.data?.email}</StyledText>

      </ProfileSection>

      <StyledHr />

      <ContentSection>

        {MenuItems.map((item, index) => {
          return (
            <StyledNavLink
              key={index}
              to={item?.to}
              className="px-0"
            >
              <div>{item.icon}</div>
              <p className=" mb-0">{item.name}</p>
            </StyledNavLink>
          );
        })}
        {loc.pathname.includes('admin-chat') && getUserInfo?.data?.caseDetail ? <StyledHr className='' /> : null}

        {loc.pathname.includes('admin-chat') && getUserInfo?.data?.caseDetail ? <div className=''>
          <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.white}>
            {t("common:textCaseDetailsTite")}
          </StyledText>

          <StyledText as='label' $fontWeight={400} $fontSize="13px" color={theme.white} lh="1.7" className='mt-2' truncatedlines={3}>
            {getUserInfo?.data?.caseDetail}
          </StyledText>
        </div> : null}
      </ContentSection>


      <SidebarFooter>
        <StyledButton variant="primary" icon={<LogoutIcon color={theme.white} />} className="w-100 mx-2" onClick={() => { dispatch(removeAdminCredentials()); dispatch(apiSlice.util.resetApiState()) }}>
          {t("common:logout")}
        </StyledButton>
      </SidebarFooter>

    </SidebarContainer>
  );
};



export default AdminSidebar;
