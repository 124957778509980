import React, { useState } from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import DownloadIcon from "../../Components/Svgicons/DownloadIcon";
import {
  useNomineesFileQuery,
} from "../../Services/DashboardApi/dashboardApiSlice";
import {
  downloadUrl,
  handleViewDocs,
  useWindowSize,
} from "../../Services/Utils/helper";
import PaginationPerPage from "../../Components/pagination/PaginationPerPage";
import { useTranslation } from "react-i18next";
import ViewIcon from "../../Components/Svgicons/ViewIcon";
import NotesModal from "./NotesModal";
import { GridCardContainer, PaginationCommonContainer, StyledContainer, TextCommonContainer } from "../../Components/StyledComponents/CommonStyled";
import StyledCard from "../../Components/StyledComponents/StyledCard";
import StyledIconInput from "../../Components/StyledComponents/StyledIconInput";
import CardSkeleton from "../../Components/Skeletons/CardSkeleton";
import SharedDocZeroState from "../../Components/Svgicons/SharedDocZeroState";
import NotesIcon from "../../Components/Svgicons/NotesIcon";

const NomineeDocument = ({ activeTab }) => {
  const { t } = useTranslation();
  const isSmallScreen = useWindowSize();
  const [timer, setSearchTimer] = useState(null);
  const [notes, setNotes] = useState({ state: false, notes: "" });


  const initialFilters = {
    perPage: 10,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [nomineeFilters, setNomineeFilters] = useState({ ...initialFilters });
  // eslint-disable-next-line
  const [listing, setListing] = useState("");


  const { data: getNomineesFile, isLoading } = useNomineesFileQuery(
    {
      perPage: nomineeFilters.perPage,
      page: nomineeFilters.page,
      query: nomineeFilters.query && nomineeFilters.query,
      sort: nomineeFilters.sortedColumn.path || listing.sort,
      sortDir: nomineeFilters.sortedColumn.order || listing.sortDir,
      ...listing,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );



  const handleNomineePerPageChange = (perPage) => {
    setNomineeFilters((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handleNomineePageChange = ({ selected }) => {
    setNomineeFilters((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };

  const handleSearch = (evt) => {    
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setNomineeFilters({ ...nomineeFilters, page: 1, query: query });
    }, 1000);
    setSearchTimer(newTimer);
  };






  const options = [
    {
      icon: <NotesIcon size="18px" />,
      onClick: (item) => {
        setNotes({ state: true, notes: item?.notes });
      }

    },
    {
      icon: <DownloadIcon size="18px" />,
      onClick: (item) => { window.open(downloadUrl + item?.file?.fileDescriptor, "_blank") },
    },
    {
      icon: <ViewIcon size="18px" />,
      onClick: (item) => { handleViewDocs(item?.file?.s3Object?.signedUrl) },
    },

  ]



  return (
    <div className="bg-white">
      <div className="container-fluid px-0">
        <TextCommonContainer className="row align-items-center m-0">
          <div className="col-md-6 col-sm-12 text-center text-md-start px-0">
            <div>
              <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary} >
                {t("common:nominatedDocumentsHeading")}
              </StyledText>
              <StyledText as='p' $fontWeight={400} $fontSize="13px" color={theme.doveGray}>
                {t("common:nominatedDocumentsDescp")}
              </StyledText>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 px-0">
            <StyledIconInput onChange={handleSearch} placeholder={t("common:searchDocument")} $maxWidth={isSmallScreen ? '100%' : "360px"} className="d-flex justify-content-end" />
          </div>
        </TextCommonContainer>
      </div>

      <StyledContainer height={isSmallScreen && '235px'} zeroState={!isLoading && (getNomineesFile?.data?.conversation?.length === 0 || getNomineesFile?.data?.conversation?.length === undefined)}>
        {!isLoading && (getNomineesFile?.data?.conversation?.length === 0 || getNomineesFile?.data?.conversation?.length === undefined) ?
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="d-flex align-items-center justify-content-center">
              <SharedDocZeroState />
            </div>
            <StyledText
              color={theme.gray}
              $fontWeight={600}
              $fontSize="20px"
              className="text-center mt-3"
            >
              {t('common:zeroStateNomineeHeading')}
            </StyledText>
            <StyledText
              color={theme.gray}
              $fontWeight={500}
              $fontSize="14px"
              className="text-center"
            >
              {t('common:zeroStateNomineeDescp')}
            </StyledText>
          </div> : isLoading ? <GridCardContainer><CardSkeleton /></GridCardContainer> :


            <GridCardContainer>
              {getNomineesFile?.data?.conversation?.map((item, index) => (
                <StyledCard options={options} data={item} key={index} />
              ))}
            </GridCardContainer>
        }

      </StyledContainer>
      {isLoading ? null : !isLoading && (getNomineesFile?.data?.conversation?.length === 0 || getNomineesFile?.data?.conversation?.length === undefined) ? null : <PaginationCommonContainer>
        <PaginationPerPage
          perPage={getNomineesFile?.data?.perPage || 0}
          pageNo={getNomineesFile?.data?.page || 0}
          onPerPageChange={handleNomineePerPageChange}
          totalCount={getNomineesFile?.data?.total || 0}
          onPageChange={handleNomineePageChange}
        />
      </PaginationCommonContainer>}

    

      {notes?.state && (
        <NotesModal
          show={notes?.state}
          onHide={() => setNotes({ state: false, notes: "" })}
          notes={notes?.notes}
        // setIsEditAdmin={setIsEditAdmin}
        />
      )}
    </div>
  );
};


export default NomineeDocument;
