import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sharedDocs: false,
};

const SharedDocs = createSlice({
  name: "activeTab",
  initialState,
  reducers: {
    SetSharedDocs: (state, { payload: { values } }) => {
      state.sharedDocs = values;
    },

  },
});

export const { SetSharedDocs } = SharedDocs.actions;

export default SharedDocs.reducer;
