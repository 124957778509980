const AddFileIcon = ({ size = "57", rotate = "down", transition, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 57 57" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_192_20535)">
        <path d="M46.8561 52.2388C46.8648 53.4033 45.9123 54.3634 44.7377 54.3672L12.8212 54.4731C11.6567 54.4818 10.6966 53.5293 10.6928 52.3546L10.5387 4.44481C10.5299 3.28026 11.4825 2.32021 12.6571 2.31638L31.8381 2.25552C33.0027 2.24679 34.6322 2.92534 35.4701 3.74375L45.2333 13.4477C46.0563 14.2713 46.7382 15.8998 46.7469 17.0644L46.8662 52.2437L46.8561 52.2388Z" fill="white" stroke="#4E4E4E" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M45.2195 13.4418L35.4563 3.73778C34.7502 3.03327 33.443 2.43625 32.3418 2.28862L32.3852 14.4618C32.3939 15.6263 33.3491 16.589 34.5136 16.5802L46.6868 16.5369C46.5284 15.4494 45.9257 14.1463 45.2195 13.4418Z" fill="#F1F0F0" stroke="#4E4E4E" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <path d="M56.4089 37.4242C56.4089 42.8766 51.9889 47.2966 46.5365 47.2966C41.0841 47.2966 36.6641 42.8766 36.6641 37.4242C36.6641 31.9718 41.0841 27.5518 46.5365 27.5518C51.9889 27.5518 56.4089 31.9718 56.4089 37.4242Z" fill="#1C79BF" stroke="#1C79BF" strokeWidth="0.5" />
      <path d="M43.6367 37.4951H49.5949" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M46.6133 40.3152V34.6748" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <clipPath id="clip0_192_20535">
          <rect width="54.5284" height="56.5263" fill="white" transform="translate(0 0.475586) rotate(-0.5)" />
        </clipPath>
      </defs>
    </svg>


  );
};

export default AddFileIcon;
