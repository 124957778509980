const UploadIcon = ({ size = 20, rotate = "down", transition, ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.6997 7.41699C16.6997 7.67533 17.9247 9.21699 17.9247 12.592V12.7003C17.9247 16.4253 16.4331 17.917 12.7081 17.917H7.28307C3.55807 17.917 2.06641 16.4253 2.06641 12.7003V12.592C2.06641 9.24199 3.27474 7.70033 6.22474 7.42533" stroke="#6C6C6C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10 12.4999V3.0166" stroke="#6C6C6C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.7923 4.87467L10.0007 2.08301L7.20898 4.87467" stroke="#6C6C6C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
};

export default UploadIcon;
