import styled from "styled-components";
import theme from "../Services/Utils/themes";

export const HeaderContainer = styled.header`
  background: ${theme.white};
  color: black;
  text-align: center;
  height: 52px;
  padding: 0px 35px;
  box-shadow: 0px 4px 40px 0px #0000000D;
  border-radius: ${({ location }) => location ? '8px 0px 0px 0px' : '8px 8px 0px 0px'};
  z-index: 1;

  @media (max-width: 768px) {
  padding: 0px 15px;
  }
`;

