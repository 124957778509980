const SwedenFlag = ({
  width = 30,
  height = 24,
  rotate = "down",
  transition,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="30" height="24" rx="2" fill="#005AAD" />
      <g clipPath="url(#clip0_657_4421)">
        <path
          d="M22.9687 0H7.03116C3.14791 0 -8.67499e-05 0.529438 -8.67499e-05 5.5V15C-8.67499e-05 19.9706 -0.38329 24 3.49996 24H26.5C30.3833 24 29.9999 19.9706 29.9999 15V5.5C29.9999 0.529438 26.8519 0 22.9687 0Z"
          fill="#005AAD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.375 0V9.6H0V14.4H9.375V24H13.125V14.4H30V9.6H13.125V0H9.375Z"
          fill="#FFC200"
        />
      </g>
      <defs>
        <clipPath id="clip0_657_4421">
          <rect width="30" height="24" rx="1" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SwedenFlag;
