const MonitorIcon = ({ size = 20, rotate = "down", transition, ...props }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8333 8.33337H73.125C87.9583 8.33337 91.6667 12.0417 91.6667 26.8334V53.2084C91.6667 68.0417 87.9583 71.7084 73.1667 71.7084H26.8333C12.0417 71.75 8.33333 68.0417 8.33333 53.25V26.8334C8.33333 12.0417 12.0417 8.33337 26.8333 8.33337Z"
        fill="#F6F7FA"
        stroke="#DADADA"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 71.75V91.6667"
        stroke="#DADADA"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33333 54.1666H91.6667"
        stroke="#DADADA"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.25 91.6666H68.75"
        stroke="#DADADA"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MonitorIcon;
