import React from 'react';
import styled from 'styled-components';
import NotificationIcon from '../Svgicons/NotificationIcon';
import StyledText from './StyledText';
import { DefaulImage, USERTYPE, filterHtml, fonts, getRelativeTime, handleErrorImg } from '../../Services/Utils/helper';
import CustomDropdown from '../dropdown/CustomDropDown';
import StyledImage from './StyledImage';
import theme from '../../Services/Utils/themes';
import StyledButton from './StyledButton';
import DoubleTickIcon from '../Svgicons/DoubleTickIcon';
import { useMarkAllReadNotificationsMutation, useReadNotificationsMutation } from '../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice';
import { useMarkAllClientReadNotificationsMutation, useReadClientNotificationsMutation } from '../../Services/DashboardApi/dashboardApiSlice';
import { svenskaLs } from '../../Services/Utils/localStorage';
import StyledDot from './StyledDot';
import NotificationZeroStateIcon from '../Svgicons/NotificationZeroStateIcon';
import { useTranslation } from 'react-i18next';

const NotificationDropDown = ({
  toggleClassName,
  menuClassName,
  notifications,
  refetch
}) => {
  const {t} = useTranslation();
  let userType = svenskaLs.getObject("userType");
  const [readNotifications] = useReadNotificationsMutation();
  const [readClientNotifications] = useReadClientNotificationsMutation();
  const [readAllNotifications] = useMarkAllReadNotificationsMutation();
  const [readAllClientNotifications] = useMarkAllClientReadNotificationsMutation();

  const markRead = (item) => {

    if (userType === USERTYPE.ADMIN) {
      readNotifications(item?.id).then((res) => {
        refetch();
      })
    }
    else {
      readClientNotifications(item?.id).then((res) => {
        refetch();
      })
    }


  }

  const markAllReadNotifications = (item) => {
    if (userType === USERTYPE.ADMIN) {
      readAllNotifications().then((res) => {
        refetch();
      })
    }
    else {
      readAllClientNotifications().then((res) => {
        refetch();
      })
    }


  }


  return (
    <ProfileDropDownStyle>
      <CustomDropdown
        toggleClassName={toggleClassName}
        menuClassName={menuClassName}
        dropdownToggle={
          <div className='position-relative'>
            <div>
              <NotificationIcon />
            </div>

            {notifications?.data?.map((item , index) => (item?.read === 0 ? <StyledDot
              key={index}
              color={theme.danger}
              $position="absolute"
              $top="0px"
              $right="2px"
              $border={`2px solid ${theme.white}`}
              $size="10px"
            /> : null))}
          </div>

        }
        dropdownMenu={
          <div className="dropDown ">
            <div className=" d-flex flex-row justify-content-between align-items-center p-2">
            <StyledText $fontWeight={600} $fontSize="16px" color={theme.secondary}>{t('common:notifications')}</StyledText>
              {notifications?.data?.length > 0 ? <div onClick={markAllReadNotifications} className='cursor-pointer'>
                <DoubleTickIcon />
                <StyledText $fontSize="13px" $fontWeight={500} color={theme.bluish}>
                {t('common:markAllAsRead')}
                </StyledText>
              </div> : null}
            </div>
            <hr className='mb-3 mx-2' />
            {notifications?.data?.length ?
              <>
                <StyledScrollNoteViewer>
                  {notifications?.data?.map((item, index) => (
                    <>
                    <div
                      key={index}
                      className={`${item.read === 0 ? 'bg-lightBlack' : ''} d-flex align-items-center justify-content-between p-2 pe-4 rounded-1 cursor-pointer`}
                      onClick={() => markRead(item)}
                    >
                      <div className="d-flex  align-items-center gap-3" key={Math.random()}>
                        <div className="">
                          <StyledImage height={'36px'} width={'36px'}>
                            <img
                              src={item?.profileImage || DefaulImage.image}
                              alt="img"
                              onError={handleErrorImg}
                            />
                          </StyledImage>
                        </div>
                        <div className="d-flex flex-column">
                          {/* {item?.message?.map((data) => ( */}
                          <StyledText
                            color={theme.darkBrown}
                            $fontFamily={fonts.raleway}
                            // className={`me-1 ${data?.bold ? 'fw-bold' : ''}`}
                            key={Math.random()}
                          >
                            {filterHtml(item?.message)}

                          </StyledText>
                          {/* // ))} */}
                          <div>
                            <StyledText
                            $fontFamily={fonts.darkBrown}
                              color={theme.doveGray}
                            >
                              {getRelativeTime(item?.createdAt)}
                            </StyledText>
                          </div>
                        </div>
                      </div>

                     {item?.read === 0 ? <div><StyledDot $size="8px" color={theme.primary}/> </div>: null}

                    </div>

                    </>
                  ))}
                </StyledScrollNoteViewer>


                <StyledButton
                  variant="white"
                  borderColor={theme.lavenderGray}
                  className='w-100 mt-3 d-none'
                // onClick={ViewAlNotifications}
                >
                  See All Notifications
                </StyledButton>
              </>
              :
              <div className='w-100 d-flex flex-column align-items-center justify-content-center pb-5'>
                <div className='my-4'>
                <NotificationZeroStateIcon/>
                </div>
                <StyledText $fontSize="16px" $fontWeight={600} color={theme.gray} className="text-center w-100">
                  No Notification
                </StyledText>
                <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray} className="text-center w-100">
                  Notification inbox Empty
                </StyledText>
              </div>
            }



          </div>
        }
      />
    </ProfileDropDownStyle>
  );
};

const ProfileDropDownStyle = styled.div`
  .menu-toggle {
    padding-block: 0;
  }
  .dropDown {
    width: 400px;
  }

  @media (max-width: 421px) {
   .dropDown {
    width: 300px;
  }
      
  }
  .account-menu {
    display: flex;
    align-items: center;
    h6 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  }
  h6 {
    word-break: break-all;
    white-space: pre-wrap;
  }
  .time {
    color: ${({ theme }) => theme.inputPlaceHolder};
  }
  .account-img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 8px;

    &.default {
      background-color: ${({ theme }) => theme.defaultImage};
      border-radius: 50%;
      color: ${({ theme }) => theme.white};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;
const StyledScrollNoteViewer = styled.div`
display: flex;
flex-direction: column;
gap:2px;
  max-height: 310px;
  max-width: 520px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
`;
export default NotificationDropDown;
