const SharedDocumentIcon = ({ size = 22, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M19.25 6.41683V15.5835C19.25 18.3335 17.875 20.1668 14.6667 20.1668H7.33333C4.125 20.1668 2.75 18.3335 2.75 15.5835V6.41683C2.75 3.66683 4.125 1.8335 7.33333 1.8335H14.6667C17.875 1.8335 19.25 3.66683 19.25 6.41683Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.2917 4.125V5.95833C13.2917 6.96667 14.1167 7.79167 15.125 7.79167H16.9584" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.33331 11.9165H11" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.33331 15.5835H14.6666" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    

  );
};

export default SharedDocumentIcon;
