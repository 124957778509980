import React from 'react';
import Proptypes from 'prop-types';
import { StyledRadio } from './StyledRadio';
import { capitalizeFirstLetter } from '../../Services/Utils/helper';

const StyledRadioButton = ({
  value,
  onChange,
  label,
  disabled,
  type,
  hasNoLabel,
  size,
  name,
  ...props
}) => {
  const onChangeRadio = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <>
      <label
        htmlFor={`${label}+${name}`}
        className={`${props?.className ?? ''
          } d-flex align-items-center mb-0 me-2`}
        style={{ cursor: 'pointer' }}
      >
        <StyledRadio value={value} size={size} {...props}>
          {value && <span className="dot" />}
        </StyledRadio>
        {label && !hasNoLabel && (
          type === 'jobOffer' ? <span className={`ms-2 text-break`}>{label?.toUpperCase()}</span> : <span className={`ms-2 text-break`}>{capitalizeFirstLetter(label)}</span>
        )}
      </label>
      <input
        id={`${label}+${name}`}
        type="radio"
        style={{ display: 'none' }}
        checked={value}
        disabled={disabled}
        onChange={onChangeRadio}
      />
    </>
  );
};

StyledRadioButton.propTypes = {
  value: Proptypes.bool,
  onChange: Proptypes.func,
  label: Proptypes.string,
};

export default StyledRadioButton;
