import React from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import StyledImage from "../../Components/StyledComponents/StyledImage";
import {  useParams } from "react-router-dom";
import { DefaulImage, fonts, handleErrorImg } from "../../Services/Utils/helper";
import { useGetSingleAdminQuery } from "../../Services/Admin Api/adminDashboardApi/adminWorkplaceApi/adminWPApiSlice";
import { useTranslation } from "react-i18next";
import StyledProfileSkeleton from "../../Components/StyledComponents/StyledProfileSkeleton";
import { CommonStyledContainer } from "../../Components/StyledComponents/CommonStyled";
import styled from "styled-components";

const SingleAdminView = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: getSingleAdmin, isLoading } = useGetSingleAdminQuery(id, {
    skip: !id,
  });

  return (
    !isLoading ? (
      <CommonStyledContainer>
        <div className="d-flex flex-column">

          <StyledText
            as="label"
            $fontWeight={600}
            $fontSize="14px"
            color={theme.secondary}
          >
            {t('admin:textBtnMyProfile')}
          </StyledText>

          <StyledText
            as="label"
            $fontWeight={400}
            $fontSize="12px"
            color={theme.gray}
          >
            {t('admin:allInfoRelatedToProfile')}
          </StyledText>
        </div>
        <StyledProfileContainer className="mt-4">
          <StyledProfileHeader className="d-flex align-items-center gap-2 px-3 px-md-5 py-3">
              <StyledImage
                height={"45px"}
                width={"45px"}
                className=" rounded-pill"
              >
                <img
                  src={getSingleAdmin?.data?.signedUrl || DefaulImage.image}
                  alt="img"
                  onError={handleErrorImg}
                />
              </StyledImage>
              <div className="d-flex flex-column ">
                <StyledText
                  $fontSize="14px"
                  $fontWeight={600}
                  $fontFamily={fonts.raleway}
                  color={theme.secondary}
                >
                  {`${getSingleAdmin?.data?.firstName || ""} ${getSingleAdmin?.data?.lastName || ""
                    }`}
                </StyledText>
                <StyledText $fontSize="12px" $fontWeight={500} color={theme.gray}>
                  {t('common:textPersonalNo')}&nbsp;:&nbsp;{getSingleAdmin?.data?.personalNumber}
                </StyledText>
              </div>
          </StyledProfileHeader>
          <div className=" px-3 px-md-5 py-4 ">
            <StyledText
              $fontSize="13px"
              $fontWeight={600}
              $fontFamily={fonts.raleway}
              color={theme.secondary}
            >
              {t('common:textPersonalInfo')}
            </StyledText>

            <div className="d-flex flex-column gap-3 mt-4">
              <div className="d-flex flex-column">
                <StyledText
                  $fontSize="12px"
                  $fontWeight={400}
                  $fontFamily={fonts.raleway}
                  color={theme.doveGray}
                >
                  {t('common:textFullName')}
                </StyledText>
                <StyledText
                  $fontSize="14px"
                  $fontFamily={fonts.raleway}
                  $fontWeight={600}
                  color={theme.secondary}
                >
                  {getSingleAdmin?.data?.firstName}&nbsp;{getSingleAdmin?.data?.lastName}
                </StyledText>
              </div>

              <hr/>

              <div className="d-flex flex-column">
                  <StyledText
                   $fontSize="12px"
                   $fontWeight={400}
                    color={theme.doveGray}
                    $fontFamily={fonts.raleway}
                  >
                    {t('common:textEmailAddress')}
                  </StyledText>
                  <StyledText
                    $fontSize="14px"
                    $fontFamily={fonts.raleway}
                    $fontWeight={600}
                    color={theme.secondary}
                    className="text-truncate"
                    noTextTransform
                  >
                    {getSingleAdmin?.data?.email}
                  </StyledText>
                </div>

                <hr/>

                <div className="d-flex flex-column">
                  <StyledText
                   $fontSize="12px"
                   $fontWeight={400}
                    color={theme.doveGray}
                    $fontFamily={fonts.raleway}
                  >
                    {t('common:textPersonalNo')}
                  </StyledText>
                  <StyledText
                   $fontFamily={fonts.raleway}
                    $fontSize="14px"
                    $fontWeight={600}
                    color={theme.secondary}
                  >
                    {getSingleAdmin?.data?.personalNumber}
                  </StyledText>
                </div>

            </div>
          </div>
        </StyledProfileContainer>
      </CommonStyledContainer>
    ) :
      (<StyledProfileSkeleton hide={true} />)
  );
};


const StyledProfileContainer = styled.div`
background-color: ${theme.white};
border-radius: 5px;
border:1px solid ${theme.lavenderGray};
`
const StyledProfileHeader = styled.div`
border-bottom: 1px solid ${theme.lavenderGray};
background-color: ${theme.snowWhite};
border-radius: 5px 5px 0 0;

`


export default SingleAdminView;
