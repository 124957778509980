import React from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { Row } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import EditIcon from "../../Components/Svgicons/EditIcon";
import { useTranslation } from "react-i18next";

function AdminDetails({ setEditProfile, user, setActiveUploadIcon }) {
  const { t } = useTranslation();
  return (
    <>
      <div className="border rounded-3 p-4 mt-4">
        <StyledText $fontSize="18px" $fontWeight={600} color={theme.secondary}>
          {t('common:textPersonalInfo')}
        </StyledText>
        <Row className="mt-4">
          <div className="col-12 col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
                {t('common:textFirstName')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.firstName || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('common:textLastName')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.lastName || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray} >
              {t('common:textEmailAddress')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
                noTextTransform
              >
                {user?.email || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('common:textPersonalNoFull')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.personalNumber || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
              {t('common:textPhoneNumber')}
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.phoneNumber || "-"}
              </StyledText>
            </div>
          </div>
        </Row>
      </div>

      {/* <div className="border rounded-3 p-4 mt-4">
        <StyledText $fontSize="18px" $fontWeight={600} color={theme.secondary}>
          Address
        </StyledText>
        <Row className="mt-4">
          <div className="col-6">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
                Country
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.country || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-6">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
                City/State
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.city || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-6 mt-4">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
                Postal code
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.postCode || "-"}
              </StyledText>
            </div>
          </div>
          <div className="col-6 mt-4">
            <div className="d-flex flex-column">
              <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray}>
                Personal No
              </StyledText>
              <StyledText
                $fontSize="16px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {user?.personalNumber || "-"}
              </StyledText>
            </div>
          </div>
        </Row>
      </div> */}
      <StyledButton
        icon={<EditIcon />}
        className="text-white rounded-2 mt-3"
        onClick={() => {
          setEditProfile(true);
          setActiveUploadIcon(true);
        }}
      >
        {t('common:textBtnEditProfile')}
      </StyledButton>
    </>
  );
}

export default AdminDetails;
