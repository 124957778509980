const PdfIcon = ({
  height = "42",
  width = "33",
  rotate = "down",
  transition,
  ...props
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 33 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.35714 0H22.7857L33 10.5V42H2.35714V0Z" fill="#ECECEC"/>
<path d="M0 4.84615H18.8571V16.1538H0V4.84615Z" fill="#DB4640"/>
<path d="M2.8644 13.7308V8.44406H4.79297C5.18806 8.44406 5.51953 8.51978 5.78739 8.67122C6.05692 8.82266 6.26032 9.03089 6.3976 9.29592C6.53655 9.55922 6.60603 9.85866 6.60603 10.1942C6.60603 10.5333 6.53655 10.8344 6.3976 11.0977C6.25865 11.361 6.05357 11.5684 5.78237 11.7199C5.51116 11.8696 5.17718 11.9444 4.78041 11.9444H3.50223V11.1571H4.65486C4.88588 11.1571 5.07506 11.1158 5.22238 11.0332C5.3697 10.9506 5.47852 10.837 5.54883 10.6925C5.62081 10.5479 5.65681 10.3818 5.65681 10.1942C5.65681 10.0067 5.62081 9.84146 5.54883 9.69862C5.47852 9.55578 5.36886 9.44478 5.21987 9.36562C5.07254 9.28473 4.88253 9.24429 4.64983 9.24429H3.79604V13.7308H2.8644Z" fill="#ECECEC"/>
<path d="M9.15799 13.7308H7.41525V8.44406H9.19315C9.70375 8.44406 10.1424 8.54989 10.509 8.76157C10.8773 8.97152 11.1602 9.27355 11.3578 9.66764C11.5553 10.0617 11.6541 10.5333 11.6541 11.0823C11.6541 11.6329 11.5545 12.1062 11.3553 12.502C11.1577 12.8978 10.8723 13.2016 10.499 13.4133C10.1273 13.6249 9.68032 13.7308 9.15799 13.7308ZM8.34689 12.9021H9.11279C9.47105 12.9021 9.76988 12.835 10.0093 12.7008C10.2487 12.5648 10.4286 12.3626 10.5492 12.0942C10.6697 11.824 10.73 11.4867 10.73 11.0823C10.73 10.6778 10.6697 10.3422 10.5492 10.0755C10.4286 9.80704 10.2503 9.60655 10.0143 9.47404C9.77992 9.3398 9.48863 9.27269 9.14042 9.27269H8.34689V12.9021Z" fill="#ECECEC"/>
<path d="M12.5393 13.7308V8.44406H15.8339V9.24687H13.4709V10.6821H15.6079V11.4849H13.4709V13.7308H12.5393Z" fill="#ECECEC"/>
<path d="M7.07143 18.5769H27.5V20.1923H7.07143V18.5769Z" fill="#CCCCCC"/>
<path d="M7.07143 22.6154H27.5V24.2308H7.07143V22.6154Z" fill="#CCCCCC"/>
<path d="M7.07143 26.6538H27.5V28.2692H7.07143V26.6538Z" fill="#CCCCCC"/>
<path d="M7.07143 30.6923H27.5V32.3077H7.07143V30.6923Z" fill="#CCCCCC"/>
<path d="M7.07143 34.7308H27.5V36.3462H7.07143V34.7308Z" fill="#CCCCCC"/>
<path d="M21.9981 10.5975C22.0781 7.55023 22.2783 2.62583 22.359 0.534447C22.3727 0.179831 22.7965 0.0104384 23.0408 0.261211L32.84 10.3205C33.0878 10.5748 32.9125 11.0101 32.5624 11.0101H22.3904C22.1696 11.0101 21.9922 10.8244 21.9981 10.5975Z" fill="#CCCCCC"/>
</svg>

  );
};

export default PdfIcon;
