import styled from "styled-components";
import theme from "../Services/Utils/themes";


export const SharedDocSidebarContainer = styled.div`
  background: ${theme.white};
  color: white;
  width: ${({ width }) => width ? width : '250px'};
  height: ${({ height }) => height && `calc(100vh - ${height}px)`}; ;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease;
  padding: ${({padding}) => padding ? padding : '20px 20px'};
  overflow-y: auto;
  border-left: 1px solid ${theme.lightGray};
  @media (max-width: 1183px) {
    position: fixed; /* Use fixed positioning for mobile sidebar */
    top: 0;
    right: ${({ isOpen }) => (isOpen ? '0' : '-250px')}; /* Adjust for sidebar width */
    height: 100vh; /* Ensure it covers the full height of the viewport */
    z-index: 1000; /* Ensure it's above other content */
    transition: left 0.3s ease;
    transition: right 0.3s ease;
  width:250px;

  }

   @media (max-width: 768px) {
    display:none;
  }
`;


export const DocumentCard = styled.div`
max-width: 214px;
max-height: 90px;
padding: 10px;
border-radius: 3px;
display: flex;
border: 1px solid ${theme.pastelGray};
box-shadow: 0px 4px 40px 0px #35292D0D;
display: flex;
align-items: center;
justify-content: between;

`

export const DocsOverlay = styled.div`
  display: none;
  @media (max-width: 1183px) {
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    z-index: 999; /* Ensure it's above other content except the sidebar */
  }
 
`;