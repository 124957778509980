import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const StyledInput = styled(Form.Control)`
  padding: 8px 14px;
  font-size: 14px;
  width: 100%;
  outline: none;
  background-color: ${({ theme }) => theme.white};
  border-radius: 5px;
  border: ${({ $borderWidth, isInvalid }) => $borderWidth ?? '1.5px '} solid
    ${({ theme, isInvalid }) => isInvalid ? theme.danger : theme.lavenderGray};

  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.headingColor} !important;
  font-size: ${({ $fontSize }) => $fontSize ?? '14px'};
  font-weight: ${({ $fontWeight }) => $fontWeight ?? '400'};

  &:focus {
    box-shadow: none;
    color: ${({ theme }) => theme.inputColor};
    border-color: ${({ theme }) => theme.lavenderGray};
  }

  //add styles when input has value
  //&:not(:placeholder-shown)
  &:valid {
    background-image: none;
  }

  &::placeholder {
    color: ${({ theme }) => theme.gray};
    font-size: ${({ $phFontSize }) => $phFontSize ?? '14px'};
    font-weight: ${({ $phFontWeight }) => $phFontWeight ?? '500px'};
    opacity: 1;
  }

  &.publicForms {
    color: ${({ theme }) => theme.primary} !important;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.primary};
    border-radius: 0;
    &:focus {
      outline: none;
      border-bottom: 2px solid ${({ theme }) => theme.primary};
    }
  }

  &[type='range'] {
    height: 2px;
    border: none;
    outline: none;
    padding: 15px 0;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: 3px;
      cursor: pointer;
      animate: 0.2s;
      background: ${({ theme }) => theme.primary};
      border-radius: 3px;
    }

    &::-webkit-slider-thumb {
      border: 1px solid ${({ theme }) => theme.inputBorder};
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background: ${({ theme }) => theme.white};
      cursor: pointer;
      -webkit-appearance: none;
      margin-top: -9px;
    }
  }

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }
`;

export default StyledInput;
