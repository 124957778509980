import React from "react";
import { useSelector } from "react-redux";
import ConversationHistory from "../ConversationHistory/ConversationHistory";
import SharedDocument from "../SharedDocument/SharedDocument";
import ClientResources from "../ClientResources/ClientResources";

const Dashboard = () => {
  const { activeTab } = useSelector((state) => state.activeTab);
  return activeTab === 1 ? <ConversationHistory activeTab={activeTab}/> : activeTab === 2 ?  <SharedDocument activeTab={activeTab}/> : activeTab === 3 && <ClientResources/> ;
};

export default Dashboard;
