const DefaultFileIcon = ({
  height = "42",
  width = "33",
  rotate = "down",
  transition,
  ...props
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7106 0.511719H6.98821C5.45178 0.511906 3.97833 1.12239 2.89197 2.20888C1.80561 3.29537 1.19531 4.76889 1.19531 6.30533V34.0957C1.1955 35.632 1.80588 37.1054 2.89222 38.1917C3.97856 39.278 5.4519 39.8884 6.98821 39.8886H23.7094C25.2457 39.8884 26.7191 39.278 27.8054 38.1917C28.8918 37.1054 29.5021 35.632 29.5023 34.0957V11.3063L18.7106 0.511719Z"
        fill="#6C6C6C"
        fillOpacity="0.1"
      />
      <path
        d="M23.7143 40.3399H6.99024C5.33244 40.3381 3.74307 39.6787 2.57082 38.5064C1.39857 37.3342 0.739178 35.7448 0.737305 34.087V6.29661C0.738991 4.63868 1.3983 3.04913 2.57057 1.87673C3.74284 0.704331 5.33231 0.0448423 6.99024 0.0429688H18.7126C18.7731 0.0429909 18.833 0.054941 18.8889 0.0781368C18.9448 0.101333 18.9955 0.135318 19.0382 0.178147L29.8356 10.9727C29.9216 11.0592 29.97 11.1762 29.9701 11.2983V34.0877C29.9678 35.7458 29.3079 37.3353 28.1351 38.5075C26.9623 39.6796 25.3724 40.3386 23.7143 40.3399ZM6.99024 0.963039C5.57627 0.964725 4.22071 1.52722 3.22095 2.52711C2.22119 3.52701 1.65887 4.88264 1.65738 6.29661V34.087C1.65906 35.5008 2.22146 36.8563 3.2212 37.856C4.22094 38.8558 5.5764 39.4182 6.99024 39.4199H23.7143C25.1281 39.4182 26.4836 38.8558 27.4833 37.856C28.4831 36.8563 29.0455 35.5008 29.0472 34.087V11.4879L18.5251 0.963039H6.99024Z"
        fill="#6C6C6C"
      />
      <path
        d="M18.7185 0.511719L29.513 11.3063H21.0201C20.4095 11.3063 19.8239 11.0637 19.3921 10.6319C18.9603 10.2002 18.7178 9.61458 18.7178 9.00397L18.7185 0.511719Z"
        fill="#6C6C6C"
      />
      <path
        d="M29.508 11.758H21.0151C20.2835 11.7565 19.5823 11.4652 19.065 10.9479C18.5477 10.4305 18.2564 9.72935 18.2549 8.99775V0.504789C18.2549 0.413784 18.2819 0.324835 18.3325 0.249192C18.3831 0.173548 18.455 0.114611 18.5391 0.0798349C18.6232 0.0450592 18.7157 0.036008 18.805 0.0538257C18.8942 0.0716434 18.9762 0.11553 19.0405 0.179933L29.811 10.9518C29.8813 11.0135 29.9313 11.0951 29.9541 11.1859C29.9769 11.2766 29.9714 11.3722 29.9385 11.4597C29.9056 11.5473 29.8468 11.6228 29.7699 11.6761C29.6929 11.7294 29.6016 11.7579 29.508 11.758ZM19.175 1.61454V8.99563C19.1761 9.48332 19.3703 9.95072 19.7152 10.2956C20.06 10.6404 20.5274 10.8346 21.0151 10.8358H28.3969L19.175 1.61454Z"
        fill="#6C6C6C"
      />
      <rect
        x="3.92578"
        y="14.4355"
        width="11.9931"
        height="0.959452"
        rx="0.479726"
        fill="#6C6C6C"
      />
      <rect
        x="3.92578"
        y="20.2012"
        width="20.1485"
        height="0.959452"
        rx="0.479726"
        fill="#6C6C6C"
      />
      <rect
        x="3.92578"
        y="25.9414"
        width="20.1485"
        height="0.959452"
        rx="0.479726"
        fill="#6C6C6C"
      />
      <rect
        x="3.92578"
        y="17.3145"
        width="11.9931"
        height="0.959452"
        rx="0.479726"
        fill="#6C6C6C"
      />
      <rect
        x="3.92578"
        y="23.0801"
        width="20.1485"
        height="0.959452"
        rx="0.479726"
        fill="#6C6C6C"
      />
    </svg>
  );
};

export default DefaultFileIcon;
