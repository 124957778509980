const EmailBox = ({
  size = 14,
  rotate = "down",
  color = "white",
  transition,
  ...props
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.91699 11.9596H4.08366C2.33366 11.9596 1.16699 11.0846 1.16699 9.04297V4.95964C1.16699 2.91797 2.33366 2.04297 4.08366 2.04297H9.91699C11.667 2.04297 12.8337 2.91797 12.8337 4.95964V9.04297C12.8337 11.0846 11.667 11.9596 9.91699 11.9596Z" stroke="#292D32" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M9.91634 5.25L8.09051 6.70833C7.48967 7.18667 6.50384 7.18667 5.90301 6.70833L4.08301 5.25" stroke="#292D32" stroke-width="1.2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


  );
};

export default EmailBox;
