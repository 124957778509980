import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import StyledText from "../Components/StyledComponents/StyledText";
import { svenskaLs } from "../Services/Utils/localStorage";
import { useGetOrderRefMutation } from "../Services/loginApi/loginApiSlice";
import { useDispatch } from "react-redux";
import { setCredentials } from "../Services/Auth/authSlice";
import { useNavigate } from "react-router-dom";

const RedirectPage = () => {
  let orderRef = svenskaLs.getObject("orderRef");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orderRefCollect] = useGetOrderRefMutation();

  useEffect(() => {
    if (svenskaLs.getItem('pageReload') === null) {
      svenskaLs.setItem('pageReload', 1);
      orderRefCollect(orderRef)
        .then((resp) => {
          if (resp?.data?.token) {
            dispatch(
              setCredentials({
                token: { accessToken: resp?.data?.token },
                account: resp?.data?.userInfo,
                userType: resp?.data?.userInfo?.userType,
              })
            );
            svenskaLs.removeItem("orderRef");
            svenskaLs.setItem("loadingComplete", "true");
            svenskaLs.removeItem('pageReload');
            navigate("/dashboard");
          } else {
            setError(resp?.error?.data?.message || "Unknown error occurred");
          }
        })
        .catch((error) => {
          setError("Failed to fetch data. Please check your internet connection.");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      navigate("/login");
      svenskaLs.removeItem('pageReload');
      svenskaLs.removeItem("orderRef");
    }
  }, []);
  if (loading) {
    return (
      <StyledContainer className="rounded px-5 pt-4 pb-3 d-flex flex-column align-items-center gap-3">
        <div className="spinner-border text-primary loader" role="status" />
        <StyledText>Redirecting...</StyledText>
      </StyledContainer>
    );
  }

  if (error) {
    return (
      <StyledContainer className="rounded px-0 px-md-5 pt-4 pb-3 d-flex flex-column align-items-center gap-3">
        <StyledText>Account not found. <StyledText onClick={() => navigate("/signup")} className="fw-bold text-decoration-underline">Sign up</StyledText> to get started!</StyledText>
      </StyledContainer>
    );
  }

  return null; // or any other component you want to render after redirection
};

const StyledContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  .loader {
    height: 50px;
    width: 50px;
  }
`;

export default RedirectPage;
