import React from 'react';
import { ErrorMessage, FastField } from 'formik';
import { formErrorMessage } from './FormErrorMessage';
import StyledButton from '../StyledComponents/StyledButton';
import StyledDropzone from '../StyledComponents/StyledDropzone';

const FormikDropZone = ({ name, maxChar, onDrop, setFieldName, setFieldValue, selectedFiles, ...props }) => {
  // const [preOnUpload] = usePreOnUploadMutation();
  return (
    <FastField name={name}>
      {({
        field,
        form: { touched, submitCount, errors, ...props },
      }) => (
        <>
          <StyledDropzone
            {...props}
            name={name}
            selectedFiles={selectedFiles}
            isInvalid={submitCount && touched[field.name] && errors[field.name]}
            onDrop={onDrop}
            setFieldValue={setFieldValue}
            label={'Drag and Drop a Resume here'}
            content={
              <StyledButton variant="text">Browse Your Computer</StyledButton>
            }
          />
          <ErrorMessage name={field.name} render={formErrorMessage} />
        </>
      )}
    </FastField>
  );
};

export default React.memo(FormikDropZone);
