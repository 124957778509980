const ChatZeroStateIcon = ({
  size = "20",
  rotate = "down",
  transition,
  color = "#6C6C6C",
  ...props
}) => {
  return (
    <svg width="200" height="160" viewBox="0 0 254 222" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M117 222C178.304 222 228 172.304 228 111C228 49.6964 178.304 0 117 0C55.6964 0 6 49.6964 6 111C6 172.304 55.6964 222 117 222Z" fill="url(#paint0_linear_392_80052)"/>
    <g filter="url(#filter0_d_392_80052)">
    <path d="M67.7118 89.2856H241.283C242.988 89.2856 244.623 89.9629 245.829 91.1685C247.035 92.3741 247.712 94.0093 247.712 95.7142V127.857C247.712 129.562 247.035 131.197 245.829 132.403C244.623 133.608 242.988 134.286 241.283 134.286H67.7118C66.0068 134.286 64.3717 133.608 63.1661 132.403C61.9605 131.197 61.2832 129.562 61.2832 127.857V95.7142C61.2832 94.0093 61.9605 92.3741 63.1661 91.1685C64.3717 89.9629 66.0068 89.2856 67.7118 89.2856V89.2856Z" fill="white"/>
    </g>
    <path d="M162.856 99.5713H129.428C127.297 99.5713 125.571 101.298 125.571 103.428C125.571 105.559 127.297 107.286 129.428 107.286H162.856C164.987 107.286 166.713 105.559 166.713 103.428C166.713 101.298 164.987 99.5713 162.856 99.5713Z" fill="#E1E1E1"/>
    <path d="M185.999 116.286H129.428C127.298 116.286 125.571 118.013 125.571 120.143C125.571 122.273 127.298 124 129.428 124H185.999C188.13 124 189.857 122.273 189.857 120.143C189.857 118.013 188.13 116.286 185.999 116.286Z" fill="#EFEFEF"/>
    <path d="M100.501 124C107.246 124 112.715 118.531 112.715 111.786C112.715 105.04 107.246 99.5713 100.501 99.5713C93.7548 99.5713 88.2863 105.04 88.2863 111.786C88.2863 118.531 93.7548 124 100.501 124Z" fill="#E1E1E1"/>
    <g filter="url(#filter1_d_392_80052)">
    <path d="M12.4286 144.571H186C187.705 144.571 189.34 145.249 190.546 146.454C191.751 147.66 192.429 149.295 192.429 151V183.143C192.429 184.848 191.751 186.483 190.546 187.688C189.34 188.894 187.705 189.571 186 189.571H12.4286C10.7236 189.571 9.08848 188.894 7.88289 187.688C6.67729 186.483 6 184.848 6 183.143V151C6 149.295 6.67729 147.66 7.88289 146.454C9.08848 145.249 10.7236 144.571 12.4286 144.571V144.571Z" fill="white"/>
    </g>
    <path d="M107.564 154.857H74.1355C72.0052 154.857 70.2783 156.584 70.2783 158.714C70.2783 160.844 72.0052 162.571 74.1355 162.571H107.564C109.694 162.571 111.421 160.844 111.421 158.714C111.421 156.584 109.694 154.857 107.564 154.857Z" fill="#E1E1E1"/>
    <path d="M130.707 171.571H74.1355C72.0052 171.571 70.2783 173.298 70.2783 175.428C70.2783 177.559 72.0052 179.286 74.1355 179.286H130.707C132.837 179.286 134.564 177.559 134.564 175.428C134.564 173.298 132.837 171.571 130.707 171.571Z" fill="#EFEFEF"/>
    <path d="M28.4985 179.285C35.2442 179.285 40.7128 173.817 40.7128 167.071C40.7128 160.325 35.2442 154.857 28.4985 154.857C21.7527 154.857 16.2842 160.325 16.2842 167.071C16.2842 173.817 21.7527 179.285 28.4985 179.285Z" fill="#E1E1E1"/>
    <g filter="url(#filter2_d_392_80052)">
    <path d="M186 34H12.4286C8.87817 34 6 36.8782 6 40.4286V72.5714C6 76.1218 8.87817 79 12.4286 79H186C189.55 79 192.429 76.1218 192.429 72.5714V40.4286C192.429 36.8782 189.55 34 186 34Z" fill="white"/>
    </g>
    <path d="M105 44.2856H71.5711C69.4408 44.2856 67.7139 46.0125 67.7139 48.1428C67.7139 50.273 69.4408 51.9999 71.5711 51.9999H105C107.13 51.9999 108.857 50.273 108.857 48.1428C108.857 46.0125 107.13 44.2856 105 44.2856Z" fill="#E1E1E1"/>
    <path d="M128.143 61H71.5711C69.4409 61 67.714 62.7269 67.714 64.8571C67.714 66.9874 69.4409 68.7143 71.5711 68.7143H128.143C130.273 68.7143 132 66.9874 132 64.8571C132 62.7269 130.273 61 128.143 61Z" fill="#EFEFEF"/>
    <path d="M45.2085 68.7142C51.9543 68.7142 57.4228 63.2457 57.4228 56.4999C57.4228 49.7542 51.9543 44.2856 45.2085 44.2856C38.4628 44.2856 32.9943 49.7542 32.9943 56.4999C32.9943 63.2457 38.4628 68.7142 45.2085 68.7142Z" fill="#E1E1E1"/>
    <defs>
    <filter id="filter0_d_392_80052" x="55.2832" y="86.2856" width="198.429" height="57" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.101 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_392_80052"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_392_80052" result="shape"/>
    </filter>
    <filter id="filter1_d_392_80052" x="0" y="141.571" width="198.429" height="57" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.101 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_392_80052"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_392_80052" result="shape"/>
    </filter>
    <filter id="filter2_d_392_80052" x="0" y="31" width="198.429" height="57" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    <feOffset dy="3"/>
    <feGaussianBlur stdDeviation="3"/>
    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.101 0"/>
    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_392_80052"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_392_80052" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_392_80052" x1="117" y1="0" x2="117" y2="222" gradientUnits="userSpaceOnUse">
    <stop stopColor="#F6F7FA"/>
    <stop offset="1" stopColor="#F3F3F3"/>
    </linearGradient>
    </defs>
    </svg>

  );
};

export default ChatZeroStateIcon;
