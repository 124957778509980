const SwedenFlagRounded = ({
  width = 30,
  height = 24,
  rotate = "down",
  transition,
  ...props
}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_663_4482)">
        <path
          d="M16.8436 0H5.15607C2.30835 0 -0.000177549 0.485318 -0.000177549 5.04167V13.75C-0.000177549 18.3063 -0.281193 22 2.56652 22H19.4332C22.2809 22 21.9998 18.3063 21.9998 13.75V5.04167C21.9998 0.485318 19.6913 0 16.8436 0Z"
          fill="#005AAD"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.875 0V8.8H0V13.2H6.875V22H9.625V13.2H22V8.8H9.625V0H6.875Z"
          fill="#FFC200"
        />
      </g>
      <defs>
        <clipPath id="clip0_663_4482">
          <rect width="22" height="22" rx="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SwedenFlagRounded;
