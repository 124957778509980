import React from 'react';
import styled from 'styled-components';
import StyledText from './StyledText';
import DropDownIcon from '../Svgicons/DropDownIcon';
import theme from '../../Services/Utils/themes';
import { StyledBadge, StyledIconBg } from './CommonStyled';
import { USERTYPE, documentStatus, useWindowSize } from '../../Services/Utils/helper';
import { DropdownItem } from 'react-bootstrap';
import CustomDropdown from '../dropdown/CustomDropDown';
import { svenskaLs } from '../../Services/Utils/localStorage';
import { useTranslation } from 'react-i18next';
import moment from 'moment';



const StyledAccordion = ({
  data,
  onToggle,
  isOpen,
  children,
  setIsOpen,
  headerColor,
  index,
  handleChangeDocStatus,
  statusLoading
}) => {
  const { t } = useTranslation();
  const userType = svenskaLs.getObject("userType");
  const isSmallScreen = useWindowSize();
  const StatusEnum = {
    5: 'Requested',
    6: 'Delivered',
  };

  const AdminStatusEnum = {
    5: 'Requested',
    6: 'Posted',
  };

  const documentStatus = [
    {
      label: t(`common:status.Declined`),
      value: 4,
    },
    {
      label: t(`common:status.Posted`),
      value: 6,
    }
  ];
  return (
    <>
      <StyledDiv
        className={` d-flex flex-column mb-2`}
        userType={userType}
      >
        <StyledAccordianHeader className={`d-flex justify-content-between align-items-center ${isSmallScreen && 'gap-3'} flex-wrap px-4 py-3 ${userType === USERTYPE.ADMIN ? 'border-bottom' : ''}`} headerColor={headerColor}>
          <div className='d-flex align-items-center gap-3'>
            <div>
            <StyledIconBg onClick={onToggle}>
              <DropDownIcon rotate={isOpen ? 'down' : 'right'} size={12} color={theme.cyanBlue} />
            </StyledIconBg>
            </div>

            <StyledText $fontSize='16px' color={theme.secondary} $fontWeight={600}>
              {userType === USERTYPE.ADMIN ? t('common:textPersonalNoFull') : t('common:postingDate')} : {userType === USERTYPE.ADMIN ? data?.user?.personalNumber : moment(data?.createdAt).format("DD-MM-YYYY")}
            </StyledText>
          </div>

          <StyledBadge fs="12px" className='py-1 px-3' fw={400} bgColor={userType === USERTYPE.ADMIN && theme.white} borderColor={userType === USERTYPE.ADMIN ? theme.lavenderGray : 'none'} width="117px">
            {t(`common:paymentStatus.${data?.statusType}`)}
          </StyledBadge>

          {userType === USERTYPE.ADMIN ?
            <div >
              <CustomDropdown
                toggleClassName={`m-0 p-0 rounded-pill bg-white ${statusLoading && 'disabled'}`}
                menuClassName="mt-2"
                dropdownToggle={

                  <StyledButtonContainer className="d-flex align-items-center justify-content-between gap-2 rounded-pill" selectedValue={AdminStatusEnum[data?.statusId]}>
                    <StyledText $fontSize="12px" $fontWeight={400} color={AdminStatusEnum[data?.statusId] === 'Requested' ? theme.doveGray : AdminStatusEnum[data?.statusId] === 'Posted' && theme.white} className="ms-2">
                      {t(`common:status.${AdminStatusEnum[data?.statusId]}`)}
                    </StyledText>
                    <DropDownIcon className="me-2" size="12" color={AdminStatusEnum[data?.statusId] === 'Requested' ? theme.doveGray : AdminStatusEnum[data?.statusId] === 'Posted' && theme.white} />
                  </StyledButtonContainer>




                }
                dropdownMenu={
                  <span>
                    {documentStatus?.map((item, index) => (
                      <DropdownItem
                        onClick={() =>
                          handleChangeDocStatus(item, data)
                        }
                        key={index}
                      >
                        <span className=" text-secondary">
                          {item?.label}
                        </span>
                      </DropdownItem>
                    ))}
                  </span>
                }
              />
            </div>
            :
            <StyledBadge fs="12px" className='py-1 px-3' fw={400} status={StatusEnum[data?.statusId]} width="98px">
              {t(`common:status.${StatusEnum[data?.statusId]}`)}
            </StyledBadge>}
        </StyledAccordianHeader>

        {isOpen && (
          <div className=' px-4'>
            <hr className='border-pastelGray' />
            <div className='py-4'>
              {children}
            </div>
          </div>
        )}
      </StyledDiv>
    </>
  );
}

export default StyledAccordion;

const StyledDiv = styled.div`
  display: flex;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 4px 4px 40px 4px #3869B217;
  border: ${({ userType }) => userType === USERTYPE.ADMIN ? `1px solid ${theme.pastelGray}` : 'none'};


`;

const StyledButtonContainer = styled.div`
  padding: 2px 6px;
  gap: 6px;
  background: ${({ selectedValue }) => (selectedValue === 'Requested' ? theme.white : selectedValue === 'Posted' ? theme.tealishGreen : theme.white)};
  border: 1px solid ${theme.lavenderGray};
  border-radius: 10px;
  width: 115px;
  height: 28px;
  cursor: pointer;
`;




const StyledAccordianHeader = styled.div`
 background-color: ${({ headerColor }) => (headerColor ? headerColor : 'white')};
 border-radius: 5px;
`;
