import React from "react";
import CrossIcon from "../../Components/Svgicons/CrossIcon";
import StyledText from "../../Components/StyledComponents/StyledText";
import { Modal } from "react-bootstrap";
import theme from "../../Services/Utils/themes";
import { useTranslation } from "react-i18next";
import NotesZeroStateIcon from "../../Components/Svgicons/NotesZeroStateIcon";

const NotesModal = ({notes , show, onHide}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} centered onHide={onHide} size={"lg"}>
      <Modal.Body className="d-flex flex-column bg-body rounded p-3 p-sm-4">
        <div className="d-flex align-item-center justify-content-between">
          <div>
            <StyledText
              as="h1"
              $fontSize="28px"
              $fontWeight={600}
              color={theme.secondary}
            >
              {t("common:Notes")}
            </StyledText>
          </div>
          <div onClick={onHide} className="cursor-pointer">
            <CrossIcon />
          </div>
        </div>

        {notes ? <div className="border rounded p-3 mt-4">
           <StyledText $fontSize="14px">
           {notes}
           </StyledText>
        </div> : 
        <div className="d-flex flex-column align-items-center justify-content-center my-4">
          <div><NotesZeroStateIcon/></div>
          <div className="d-flex flex-column align-items-center justify-content-center mt-3 gap-2">
          <StyledText $fontSize="16px" $fontWeight={600} color={theme.gray} className="text-center w-100">
          No Notes Available
                </StyledText>
                <StyledText $fontSize="14px" $fontWeight={500} color={theme.gray} className="text-center w-100">
                There are currently no notes to display.<br/>Please check back later for updates or new information
                </StyledText>
          </div>
        </div>
        }
      </Modal.Body>
    </Modal>
  );
};

export default NotesModal;
