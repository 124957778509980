const UserIcon = ({
  size = 22,
  rotate = "down",
  color = "white",
  transition,
  ...props
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.0003 10.9997C13.5316 10.9997 15.5837 8.94765 15.5837 6.41634C15.5837 3.88504 13.5316 1.83301 11.0003 1.83301C8.46902 1.83301 6.41699 3.88504 6.41699 6.41634C6.41699 8.94765 8.46902 10.9997 11.0003 10.9997Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.8743 20.1667C18.8743 16.6192 15.3451 13.75 11.0001 13.75C6.65514 13.75 3.12598 16.6192 3.12598 20.1667" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default UserIcon;
