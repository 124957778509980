const PhoneIcon = ({
  size = 14,
  rotate = "down",
  color = "white",
  transition,
  ...props
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.8162 10.6938C12.8162 10.9038 12.7695 11.1196 12.6703 11.3296C12.5712 11.5396 12.4428 11.738 12.2737 11.9246C11.9878 12.2396 11.6728 12.4671 11.317 12.613C10.967 12.7588 10.5878 12.8346 10.1795 12.8346C9.58449 12.8346 8.94866 12.6946 8.27783 12.4088C7.60699 12.123 6.93616 11.738 6.27116 11.2538C5.60033 10.7638 4.96449 10.2213 4.35783 9.62047C3.75699 9.0138 3.21449 8.37797 2.73033 7.71297C2.25199 7.04797 1.86699 6.38297 1.58699 5.7238C1.30699 5.0588 1.16699 4.42297 1.16699 3.8163C1.16699 3.41964 1.23699 3.04047 1.37699 2.69047C1.51699 2.33464 1.73866 2.00797 2.04783 1.7163C2.42116 1.3488 2.82949 1.16797 3.26116 1.16797C3.42449 1.16797 3.58783 1.20297 3.73366 1.27297C3.88533 1.34297 4.01949 1.44797 4.12449 1.59964L5.47783 3.50714C5.58283 3.65297 5.65866 3.78714 5.71116 3.91547C5.76366 4.03797 5.79283 4.16047 5.79283 4.2713C5.79283 4.4113 5.75199 4.5513 5.67033 4.68547C5.59449 4.81964 5.48366 4.95964 5.34366 5.09964L4.90033 5.56047C4.83616 5.62464 4.80699 5.70047 4.80699 5.7938C4.80699 5.84047 4.81283 5.8813 4.82449 5.92797C4.84199 5.97464 4.85949 6.00964 4.87116 6.04464C4.97616 6.23714 5.15699 6.48797 5.41366 6.7913C5.67616 7.09464 5.95616 7.4038 6.25949 7.71297C6.57449 8.02214 6.87783 8.30797 7.18699 8.57047C7.49033 8.82714 7.74116 9.00214 7.93949 9.10714C7.96866 9.1188 8.00366 9.1363 8.04449 9.1538C8.09116 9.1713 8.13783 9.17714 8.19033 9.17714C8.28949 9.17714 8.36533 9.14214 8.42949 9.07797L8.87283 8.64047C9.01866 8.49464 9.15866 8.3838 9.29283 8.3138C9.42699 8.23214 9.56116 8.1913 9.70699 8.1913C9.81782 8.1913 9.93449 8.21464 10.0628 8.26714C10.1912 8.31964 10.3253 8.39547 10.4712 8.49464L12.402 9.86547C12.5537 9.97047 12.6587 10.093 12.7228 10.2388C12.7812 10.3846 12.8162 10.5305 12.8162 10.6938Z" stroke="#292D32" stroke-width="1.2" stroke-miterlimit="10"/>
</svg>



  );
};

export default PhoneIcon;
