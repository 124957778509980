const CartIcon = ({ size = 25, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.8125 7.98941V6.97899C7.8125 4.63524 9.69792 2.33316 12.0417 2.11441C14.8333 1.84358 17.1875 4.04149 17.1875 6.78108V8.21858" stroke="white" strokeWidth="1.6" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.37519 22.9168H15.6252C19.8127 22.9168 20.5627 21.2397 20.7814 19.1981L21.5627 12.9481C21.8439 10.4064 21.1148 8.3335 16.6669 8.3335H8.33352C3.8856 8.3335 3.15644 10.4064 3.43769 12.9481L4.21894 19.1981C4.43769 21.2397 5.18769 22.9168 9.37519 22.9168Z" stroke="white" strokeWidth="1.6" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.1421 12.4998H16.1514" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M8.8491 12.4998H8.85845" stroke="white" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


  );
};

export default CartIcon;
