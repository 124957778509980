import React, { useCallback, useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import StyledSelectCss from './StyledSelectCss';
import theme from '../../../Services/Utils/themes';
import { components } from 'react-select';
import { StyledDropdownCard } from './StyledSelectCss';
import { getIconByFileExtension, USERTYPE } from '../../../Services/Utils/helper';
import StyledText from '../StyledText';
import SelectIcon from '../../Svgicons/SelectIcon';
import { StyledSelectOption } from './StyledSelectCss';
import { useGetAdminDocumentCategoriesMutation, useGetAdminDropdownSharedDocsMutation } from '../../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice';
import { useGetDropdownResourcesMutation, useGetDropdownSharedDocsMutation } from '../../../Services/DashboardApi/dashboardApiSlice';
import moment from 'moment';
import { svenskaLs } from '../../../Services/Utils/localStorage';

const AsyncSelect = ({
    placeholder = 'Select a form from Library',
    className,
    onSelect,
    value,
    type,
    userId,
    dropdownCustomStyle = true,
    ...props
}) => {

    const user = svenskaLs.getObject('userType');
    const [selectedValue, setSelectedValue] = useState(value);
    const [adminFileCategories] = useGetAdminDocumentCategoriesMutation();
    const [sharedDocs] = useGetDropdownSharedDocsMutation();
    const [adminSharedDocs] = useGetAdminDropdownSharedDocsMutation();

    let userType = USERTYPE.ADMIN === user ? adminSharedDocs : sharedDocs;




    useEffect(() => {
        if (props.isMulti && value) {
            setSelectedValue([...value]);
        } else if (!props?.isMulti && value) {
            setSelectedValue({
                ...value,
                label: value?.title || value?.label,
                value: value?.id || value?.value,
            });
        }
    }, [value, props.isMulti]);

    const getApiToCall = (type) => {
        switch (type) {
            case 'fileCategory':
                return adminFileCategories;
            case 'sharedDocs':
                return userType;
            default:
                return;
        }
    };

    const loadOptions = async (search, loadedOptions, { page }) => {
        let param = {
            perPage: 20,
            page,
            query: search,
            ...(USERTYPE.ADMIN === user && {
                clientId: userId,
            }),

        };
        try {
            let call = getApiToCall(type);
            const { data } = await call(param);
            const options = data?.options?.map((option) => ({
                ...option,
                value: option.value,
                label: option.label,
            }));
            return {
                options: options || [],
                hasMore: loadedOptions.length + options.length < data?.total,
                additional: {
                    page: page + 1,
                },
            };
        } catch (e) {
            return {
                options: [],
                hasMore: false,
            };
        }
    };


    const CustomOption = (props) => {
        return (
            <components.Option {...props}>

                {dropdownCustomStyle ?
                    <StyledDropdownCard isSelected={props.isSelected}>
                        {props.isSelected && <StyledSelectOption>
                            <SelectIcon />
                        </StyledSelectOption>}
                        <div>
                            {getIconByFileExtension(props.data?.label, "56px", "44px")}
                        </div>

                        <div>
                            <StyledText $fontWeight={600} $fontSize="12px" color={theme.secondary} truncatedlines={1} wordbreak>
                                {props.data?.label}
                            </StyledText>
                            <StyledText $fontWeight={400} $fontSize="9px" color={theme.stormDust} truncatedlines={1}>
                                Uploaded At&nbsp;:&nbsp;{moment(props.data?.updatedAt).format("MMM DD, YYYY")}
                            </StyledText>
                        </div>
                    </StyledDropdownCard> :


                    <StyledText $fontWeight={600} $fontSize="12px" color={theme.secondary} truncatedlines={1}>
                        {props.data?.label}
                    </StyledText>

                }





            </components.Option>
        );
    };

    const CustomMenuList = (props) => {
        let hasOptions = selectedValue?.length === props?.options?.length;
        return (
            <components.MenuList {...props}>
                <div className={`${dropdownCustomStyle ? 'd-flex flex-wrap' : ''} align-items-center ${hasOptions ? 'justify-content-center' : ''}`}>
                    {props.children}
                </div>
            </components.MenuList>
        );
    };

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? theme.lavenderGray : theme.white,
            borderRadius: 5,
            padding: '5px',
            gap: '10px',
            width: 'none',
            color: theme.textColor,
            cursor: 'pointer',


        }),
    };

    const handleChange = useCallback((val) => {
        if (onSelect) {
            onSelect(val);
        } else {
            setSelectedValue(val);
        }
    }, [onSelect]);

    return (
        <StyledSelectCss>
            <AsyncPaginate
                value={selectedValue}
                onChange={handleChange}
                styles={customStyles}
                placeholder={placeholder}
                loadOptions={loadOptions}
                components={{
                    Option: CustomOption,
                    MenuList: CustomMenuList,
                }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 10,
                })}
                className={`async-select ${className ?? ''}`}
                additional={{
                    page: 1,
                }}
                {...props}
            />
        </StyledSelectCss>
    );
};




export default AsyncSelect;
