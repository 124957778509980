const NotesIcon = ({ size = 20, rotate = "down", transition, ...props }) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.375 14.25H6C3 14.25 1.5 13.5 1.5 9.75V6C1.5 3 3 1.5 6 1.5H12C15 1.5 16.5 3 16.5 6V9.75C16.5 12.75 15 14.25 12 14.25H11.625C11.3925 14.25 11.1675 14.3625 11.025 14.55L9.9 16.05C9.405 16.71 8.595 16.71 8.1 16.05L6.975 14.55C6.855 14.385 6.5775 14.25 6.375 14.25Z" stroke="#292D32" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.25 6H12.75" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.25 9.75H9.75" stroke="#292D32" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
};

export default NotesIcon;
