const DropDownIcon = ({ size = 14, rotate = "down", transition,color="#6C6C6C", ...props }) => {
  const getRotateDeg = (val) => {
    switch (val) {
      case "left":
        return "90";
      case "up":
        return "180";
      case "right":
        return "270";
      default:
        return "0";
    }
  };
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transition: transition ? "transform 0.2s ease-in-out" : "",
        transform: `rotate(${getRotateDeg(rotate)}deg)`,
      }}
      {...props}
    >
      <path
        d="M1 1L7 7L13 1"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DropDownIcon;
