const DocumentDrawerIcon = ({ size = 40, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_185_996)">
        <circle cx="20" cy="19" r="16" fill="white" />
      </g>
      <path d="M27.5 17.5V21.25C27.5 25 26 26.5 22.25 26.5H17.75C14 26.5 12.5 25 12.5 21.25V16.75C12.5 13 14 11.5 17.75 11.5H21.5" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M27.5 17.5H24.5C22.25 17.5 21.5 16.75 21.5 14.5V11.5L27.5 17.5Z" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.25 19.75H20.75" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.25 22.75H19.25" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <defs>
        <filter id="filter0_d_185_996" x="0" y="0" width="40" height="40" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_185_996" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_185_996" result="shape" />
        </filter>
      </defs>
    </svg>

  );
};

export default DocumentDrawerIcon;
