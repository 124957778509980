import { omit } from "lodash";
import { apiSlice } from "../../../ApiSlice/apiSlice";

const ADMIN_URL = "/backoffice";

export const AdminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminConversation: builder.query({
      query: (params) => ({
        url: `${ADMIN_URL}/conversations/${params?.id}`,
        method: "GET",
        params: omit(params, "id"),
      }),
    }),
    postAdminConversation: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/conversations/${params?.userId}`,
        method: "POST",
        body: params,
      }),
    }),

    getUserList: builder.query({
      query: (params) => ({
        url: `${ADMIN_URL}/conversations`,
        method: "GET",
        params: params,
      }),
    }),

    editUserProfile: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/user`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
    }),

    postNewClient: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/admin/create-user`,
        method: "POST",
        body: params,
      }),
    }),

    updateClient: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/admin/user/${params?.id}`,
        method: "PUT",
        body: params,
      }),
    }),

    updateAdmin: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/admin/${params?.id}`,
        method: "PUT",
        body: params?.id ? params?.formData : params,
      }),
    }),

    getUserInfo: builder.query({
      query: (id) => ({
        url: `${ADMIN_URL}/admin/user/${id}`,
        method: "GET",
      }),
    }),

    getUserFiles: builder.query({
      query: ({ id, params }) => ({
        url: `${ADMIN_URL}/conversations/${id}/files`,
        method: "GET",
        params: params,
      }),
    }),

    deleteClient: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/admin/user/${data.userId}?trans=${data.trans}`,
        method: "DELETE",
      }),
    }),

    deleteAdminMessage: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/conversations/${params?.conId}/user/${params?.userId}`,
        method: "DELETE",
      }),
    }),

    readAdminMessage: builder.mutation({
      query: (id) => ({
        url: `${ADMIN_URL}/conversations/${id}/message-read`,
        method: "PUT",
      }),
    }),
    uploadAdminProfile: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/admin/self/profile-image`,
        method: "POST",
        body: params,
      }),
    }),

    updateAdminProfile: builder.mutation({
      query: ({ profileData }) => ({
        url: `${ADMIN_URL}/admin/self/profileImage`,
        method: "PUT",
        body: profileData,
      }),
    }),

    getAdminProfileImg: builder.mutation({
      query: (token) => ({
        url: `${ADMIN_URL}/user/profile-image?token=${token}`,
        method: "GET",
      }),
    }),

    editAdminProfile: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/admin/self`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
    }),

    removeNomineeAccess: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/nominees/${data?.userId}/files/${data?.fileId}`,
        method: "DELETE",
      }),
    }),

    adminPreSignedUrl: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/admin/presignedUrl`,
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),

    deleteAdminDocument: builder.mutation({
      query: (id) => ({
        url: `${ADMIN_URL}/conversations/${id}`,
        method: "DELETE",
      }),
    }),


    documnetPreSignedUrl: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/document/presignedUrl`,
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),

    uploadDocument: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/document`,
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),

    getDocuments: builder.query({
      query: (params) => ({
        url: `${ADMIN_URL}/document`,
        method: "GET",
        params: params,
      }),
    }),

    getAdminFileCategories: builder.mutation({
      query: () => ({
        url: `${ADMIN_URL}/conversations/files/categories`,
        method: "GET",
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.map((item) => ({
          ...item,
          label: item?.title,
          value: item?.id,
        }));
        return responseData || {};
      },
    }),

    getAdminDocumentCategories: builder.mutation({
      query: () => ({
        url: `${ADMIN_URL}/document/list/category`,
        method: "GET",
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.categories?.map((item) => ({
          ...item,
          label: item?.title,
          value: item?.id,
        }));
        return responseData || {};
      },
    }),

    deleteDocuments: builder.mutation({
      query: (id) => ({
        url: `${ADMIN_URL}/document/${id}`,
        method: "DELETE",
      }),
    }),

    getAdminRequestedDocs: builder.query({
      query: (params) => ({
        url: `${ADMIN_URL}/doc/posting`,
        method: "GET",
        params: params,
      }),
    }),

    changeStatus: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/doc/posting/${data?.docId}`,
        method: "PUT",
        body: {
          ...omit(data, 'docId')
        },
      }),
    }),

    getNotifications: builder.query({
      query: (params) => ({
        url: `${ADMIN_URL}/notifications`,
        method: "GET",
        params: params,
      }),
    }),

    readNotifications: builder.mutation({
      query: (id) => ({
        url: `${ADMIN_URL}/notifications/read/${id}`,
        method: "PUT",
        // params: params,
      }),
    }),

    markAllReadNotifications: builder.mutation({
      query: () => ({
        url: `${ADMIN_URL}/notifications/mark-read`,
        method: "PUT",
        // params: params,
      }),
    }),

    adminImagePreSignedUrl: builder.mutation({
      query: (data) => ({
        url: `${ADMIN_URL}/admin/presignedUrl`,
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),

    getAdminRequestStatus: builder.mutation({
      query: (id) => ({
        url: `${ADMIN_URL}/doc/posting/request-status?clientId=${id}`,
        method: "GET",
      }),
    }),


    getAdminDropdownSharedDocs: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/conversations/files`,
        method: "GET",
        params: params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.conversation?.map((item) => (
          {
            ...item,
            label: item?.file?.fileName,
            value: item?.file?.id,
          }));
        return responseData || {};
      },
    }),

    adminRequestDocument: builder.mutation({
      query: (data) => (
        {
          url: `${ADMIN_URL}/doc/posting/request?clientId=${data?.clientId}`,
          method: "POST",
          body: {
            ...omit(data, 'clientId'),
          },
        }),
    }),

    markAsUnread: builder.mutation({
      query: (params) => ({
        url: `${ADMIN_URL}/conversations/${params?.conversationId}/message-unread/${params?.userId}`,
        method: "PUT",
      }),
    }),

  }),
});

export const {
  useGetAdminConversationQuery,
  usePostAdminConversationMutation,
  useGetSharedDocsQuery,
  useEditUserProfileMutation,
  useGetUserListQuery,
  usePostNewClientMutation,
  useGetUserInfoQuery,
  useGetUserFilesQuery,
  useDeleteClientMutation,
  useDeleteAdminMessageMutation,
  useReadAdminMessageMutation,
  useUploadAdminProfileMutation,
  useGetAdminProfileImgMutation,
  useEditAdminProfileMutation,
  useUpdateClientMutation,
  useUpdateAdminMutation,
  useRemoveNomineeAccessMutation,
  useAdminPreSignedUrlMutation,
  useUpdateAdminProfileMutation,
  useDeleteAdminDocumentMutation,
  useDocumnetPreSignedUrlMutation,
  useUploadDocumentMutation,
  useGetDocumentsQuery,
  useDeleteDocumentsMutation,
  useGetAdminFileCategoriesMutation,
  useGetAdminDocumentCategoriesMutation,
  useGetAdminRequestedDocsQuery,
  useChangeStatusMutation,
  useGetNotificationsQuery,
  useReadNotificationsMutation,
  useMarkAllReadNotificationsMutation,
  useAdminImagePreSignedUrlMutation,
  useGetAdminRequestStatusMutation,
  useGetAdminDropdownSharedDocsMutation,
  useAdminRequestDocumentMutation,
  useMarkAsUnreadMutation
} = AdminApiSlice;
