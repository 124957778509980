import { configureStore, isRejectedWithValue } from "@reduxjs/toolkit";
import authSlice from "../Services/Auth/authSlice";
import tabSlice from "../Services/TabSlice/TabSlice";
import { apiSlice } from "../Services/ApiSlice/apiSlice";
import adminAuthSlice from "../Services/Auth/adminAuthSlice";
import SharedDocs from "../Services/SharedDocumentsSlice/SharedDocumentSlice";
import { toast } from "react-toastify";
import SidemenuSlice from "../Services/SharedDocumentsSlice/SidemenuSlice";
import { svenskaLs } from "../Services/Utils/localStorage";

const rtkQueryErrorLogger = (store) => (next) => (action) => {
  const { dispatch } = store;

  // Handle rejected actions globally
  if (isRejectedWithValue(action)) {
    const { status, data, error } = action.payload;

    // Handle 401 Unauthorized: Clear local storage and redirect
    if (status === 401) {
      toast.warn("Your session has expired. Redirecting to login...");

      // Clear local storage/session storage, reset API state, and redirect
      setTimeout(() => {
        svenskaLs.clear();
        window.location.href = "/login";
        dispatch(apiSlice.util.resetApiState());
      }, 1000); // Shortened the timeout for better UX
    }

    // Handle other errors: Show appropriate error messages
    if (![404, 400].includes(status)) {
      toast.error(data?.message || error || "Something went wrong!");
    }
  }

  return next(action);
};


const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice,
    adminAuth: adminAuthSlice,
    activeTab: tabSlice,
    sharedDocs: SharedDocs,
    docsSidebar: SidemenuSlice
  },
  middleware: (getDefaultMiddleware) => {
    return [
      rtkQueryErrorLogger,
      ...getDefaultMiddleware().concat(apiSlice.middleware),
    ];
  },
});

export default store;
