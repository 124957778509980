import React from "react";
import SwedenFlag from "../Svgicons/SwedenFlag";
import SwedenFlagRounded from "../Svgicons/SwedenFlagRounded";
import { useTranslation } from "react-i18next";
import UsFlagRounded from "../Svgicons/UsFlagRounded";
import CustomDropdown from "../dropdown/CustomDropDown";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import StyledImage from "./StyledImage";
import StyledText from "./StyledText";
import TickIcon from "../Svgicons/TickIcon";
import { svenskaLs } from "../../Services/Utils/localStorage";
import UsFlag from "../Svgicons/UsFlag";

const StyledLanguageSwitch = () => {
    const { i18n } = useTranslation();
    const lang = svenskaLs.getItem("lng") || 'swe';
    const handleChange = (lan) => {
        i18n.changeLanguage(lan);
        svenskaLs.setItem("lng", lan);
    }
    const Lang = [
        {
          id: "swe",
          name: "Swedish",
          icon: <SwedenFlagRounded />,
          action: () => {
            handleChange('swe');
          },
        },
        {
          id: "en",
          name: "English",
          icon: <UsFlagRounded  />,
          action: () => {
            handleChange('en');
          },
        },
      ];
      return (
      <CustomDropdown
            dropdownToggle={
              <span className="d-flex align-items-center  rounded">
                 {lang !== "en" ? <SwedenFlag rotate="down" size={"8px"} className="rounded-1"/> : 
                  <UsFlag rotate="down" size={"8px"} className="rounded-1"/>}
              </span>
            }
            toggleClassName="bg-body p-0 "
            menuClassName={"page mt-2"}
            dropdownMenu={
              <>
                {Lang?.map((item, index) => (
                  <DropdownItem className=" d-flex align-items-center justify-content-between " onClick={item.action} key={index}>
                    {/* <div > */}
                    <div className="d-flex align-items-center gap-2 cursor-pointer">
                      <StyledImage
                        height={"22px"}
                        width={"22px"}
                        className=" rounded-circle"
                      >
                        {item?.icon}
                      </StyledImage>
                      <StyledText>{item?.name}</StyledText>
                    </div>
                    
                    {item?.id === lang ? <TickIcon /> : null}
                  </DropdownItem>
                ))}
              </>
            }
          />
          );
}

export default StyledLanguageSwitch;