import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { getRandomInteger } from '../../Services/Utils/helper'
import theme from '../../Services/Utils/themes'
import { DocumentCard } from '../StyledComponents/CommonStyled'

const DocumentPostingSkeleton = () => {
    return (
        Array.from(new Array(4))?.map(() => (
            <div className='d-flex flex-column mb-3 w-100' >
                <Skeleton height={68}  baseColor={theme.lightBlue} // Change the base color here
                    highlightColor={theme.lavenderGray} />
              
            </div>

        ))

    )
}

export default DocumentPostingSkeleton