import React from "react";
import { styled } from "styled-components";
import theme from "../Services/Utils/themes";
import { useTranslation } from "react-i18next";
import StyledText from "./StyledComponents/StyledText";
import { fonts, TableFileExtensionIcon } from "../Services/Utils/helper";


const FileAttachmentLable = ({ fileName }) => {
  
  

  const { t } = useTranslation('admin');
  return (
    <StyledBox className="px-1" >
      <div className="d-flex align-items-center">{TableFileExtensionIcon(fileName)}</div>
      <StyledText truncatedlines={1} $fontWeight={500} $fontSize="12px" $fontFamily={fonts.quicksand} color={theme.stormDust}>
        {fileName}
      </StyledText>
    </StyledBox>
  );
};




const StyledBox = styled.div`
display: flex;
gap:5px;
align-items: center;
  height: 24px;
  width: 150px;
  background-color: ${theme.snowWhite};
  border: 1px solid ${theme.greenWhite};
  border-radius: 3px;
  `;



export default FileAttachmentLable;