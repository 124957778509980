import React from "react";
import {Modal, Row } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { useTranslation } from "react-i18next";
import CrossIcon from "../../Components/Svgicons/CrossIcon";
import StyledImage from "../../Components/StyledComponents/StyledImage";
import { DefaulImage, fonts, handleErrorImg } from "../../Services/Utils/helper";
import styled from "styled-components";
import moment from "moment";
import { DocumentCard, GridCardContainer, NomineeCard, NomineeGridCardContainer } from "../../Components/StyledComponents/CommonStyled";

const NomineeDetails = ({
  show,
  onHide,
  nomineeDetails,
  handleRemoveAccess,
  disabled,
  loading,
  loader
}) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} centered onHide={onHide} size={"lg"}>
      <Modal.Body className="d-flex flex-column bg-body rounded p-2 p-md-5">
        <div className="d-flex align-item-center justify-content-between p-3">
          <div className="d-flex flex-column align-items-center justify-content-center w-100">
            <StyledText
              as="h1"
              $fontSize="20px"
              $fontWeight={600}
              color={theme.secondary}
            >
              {t("admin:NomineeDetails")}
            </StyledText>
            <StyledText
              as="p"
              $fontSize="14px"
              $fontWeight={400}
              color={theme.gray}
              className="mb-0"
            >
              {t("admin:NomineeDescription")}
            </StyledText>
          </div>
          {/* <div onClick={onHide} className="cursor-pointer">
            <CrossIcon />{" "}
          </div> */}
        </div>
        <hr className="mt-2 mb-4"/>
        {/* <div className="px-3 pb-3 d-flex align-items-center justify-content-between gap-4"> */}
          <NomineeGridCardContainer itemCount={nomineeDetails?.nomineesFiles?.length || 0}>
          {nomineeDetails?.nomineesFiles?.map((item, index) => (
        
            <NomineeCard className="w-100 rounded-2 p-4" key={index}>
              <div className="d-flex  flex-column align-items-center">
                <StyledImage
                  height={"80px"}
                  width={"80px"}
                  className=" rounded-pill"
                >
                  <img
                    src={item?.user?.signedUrl || DefaulImage.image}
                    alt="img"
                    onError={handleErrorImg}
                  />
                </StyledImage>
                <div className="mt-2 d-flex flex-column align-items-center ">
                  <StyledText
                    $fontSize="18px"
                    $fontWeight={600}
                    $fontFamily={fonts.raleway}
                    color={theme.secondary}
                  >{`${item?.user?.firstName || ""} ${
                    item?.user?.lastName
                  }`}</StyledText>
                  <StyledText
                    $fontSize="14px"
                    $fontWeight={400}
                    $fontFamily={fonts.raleway}
                    color={theme.gray}
                  >
                   Personal No.&nbsp;{item?.user?.personalNumber || "-"}
                  </StyledText>
                </div>
              </div>
              <hr className="my-3 border-light-Gray" />
              <div className="d-flex align-items-center justify-content-center gap-1 mb-2">
                <StyledText
                  $fontSize="15px"
                  $fontWeight={600}
                  $fontFamily={fonts.raleway}
                  color={theme.secondary}
                >
                  {t("common:textFullName")} :
                </StyledText>
                <StyledText
                  $fontSize="15px"
                  $fontWeight={500}
                  color={theme.gray}
                  $fontFamily={fonts.raleway}
                >{`${item?.user?.firstName || ""} ${
                  item?.user?.lastName
                }`}</StyledText>
              </div>
              <div className="d-flex align-items-center justify-content-center gap-1 mb-2">
                <StyledText
                  $fontSize="15px"
                  $fontWeight={600}
                  color={theme.secondary}
                  $fontFamily={fonts.raleway}
                  className="text-nowrap"
                  truncatedlines={1}
                  wordbreak
                >
                  {t("common:textEmail")}&nbsp;:&nbsp;<StyledText
                  $fontSize="15px"
                  $fontWeight={500}
                  color={theme.gray}
                  $fontFamily={fonts.raleway}
                  noTextTransform
                >
                  {item?.user?.email}
                </StyledText>
                </StyledText>
                
              </div>
              <div className="d-flex align-items-center justify-content-center gap-1 mb-2">
                <StyledText
                  $fontSize="15px"
                  $fontWeight={600}
                  color={theme.secondary}
                  $fontFamily={fonts.raleway}
                >
                  {t("common:textPhoneNumber")} :
                </StyledText>
                <StyledText
                  $fontSize="15px"
                  $fontWeight={500}
                  color={theme.gray}
                  $fontFamily={fonts.raleway}
                >
                  {item?.user?.phoneNumber}
                </StyledText>
              </div>

              <div className="d-flex align-items-center justify-content-center gap-1 mb-2">
                <StyledText
                  $fontSize="15px"
                  $fontWeight={600}
                  color={theme.secondary}
                  $fontFamily={fonts.raleway}
                >
                  {t("common:RegistrationDate")} :
                </StyledText>
                <StyledText
                  $fontSize="15px"
                  $fontWeight={500}
                  color={theme.gray}
                  $fontFamily={fonts.raleway}
                >
                  {moment(item?.user?.createdAt).format(
                            "D MMM YYYY"
                          ) || '-'}
                </StyledText>
              </div>

              <div className="d-flex align-items-center justify-content-center gap-1">
                <StyledText
                  $fontSize="15px"
                  $fontWeight={600}
                  color={theme.secondary}
                  $fontFamily={fonts.raleway}
                >
                  {t("common:textPersonalNoFull")} :
                </StyledText>
                <StyledText
                  $fontSize="15px"
                  $fontWeight={500}
                  color={theme.gray}
                  $fontFamily={fonts.raleway}
                >
                  {item?.user?.personalNumber || "-"}
                </StyledText>
              </div>

             <div className="d-flex align-items-center justify-content-center">
             <StyledButton
                variant="gray"
                className="border-0 rounded mt-4 w-100"
                disabled={loader?.nomineeId === item?.id && disabled}
                loading={loader?.nomineeId === item?.id && loading}
                onClick={() =>
                  handleRemoveAccess({ ...nomineeDetails, nomineeId: item?.id })
                }
              >
                {t("admin:RemoveNomineeAccess")}
              </StyledButton>
             </div>
            </NomineeCard>
          ))}
          </NomineeGridCardContainer>
        {/* </div> */}
      </Modal.Body>
    </Modal>
  );
};

const StyledBorder = styled.div`
  border: 1px solid ${theme.lightGray};
  border-style: dashed;
`;

export default NomineeDetails;
