import React from 'react'
import Skeleton from "react-loading-skeleton";
import { styled } from 'styled-components';
import dottedBg from "../../Components/Svgicons/Dottedbg.png";
import { getRandomInteger } from '../../Services/Utils/helper';
import theme from '../../Services/Utils/themes';

export const StyledChatSkeleton = () => {
  return (
    <StyledContainer
      className={"d-flex flex-column  justify-content-between"}>
        {Array.from(new Array(4))?.map(
              (item, index) => (
                <div key={index}>
                <div className="w-100"  >
                <Skeleton height={60} width={`${getRandomInteger(50)}%`} className="me-2 mt-2 " baseColor={theme.lightBlue} // Change the base color here
              highlightColor={theme.lavenderGray}/>
                </div>
                <div className="w-100 text-end">
                <Skeleton height={60} width={`${getRandomInteger(50)}%`} className="me-2 mt-2" baseColor={theme.lightBlue} highlightColor={theme.lavenderGray}/>
              </div>
              </div>
              )
            )}
    </StyledContainer>
  )}

  const StyledContainer = styled.div`
//   background-image: url(${dottedBg});
//   position: absolute;
//   left: 50%;
//   top: 55%;
//   transform: translate(-50%, -50%);
  height: 100%;
  overflow:hidden;
//   @media screen and (max-width: 768px) {
//     top: 63%;
//   }
//   @media screen and (max-width: 575px) {
//     top: 65%;
//   }
`;