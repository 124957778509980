const UsFlag = ({ size = 14, rotate = "down", transition, ...props }) => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_657_4434)">
        <path d="M30.0006 0H0V24.0005H30.0006V0Z" fill="#000066" />
        <path
          d="M0 0V2.68333L26.6465 24.0006H30.0006V21.3173L3.35407 8.00016e-05L0 0ZM30.0006 0V2.68325L3.35407 24.0005H0V21.3172L26.6465 0H30.0006Z"
          fill="white"
        />
        <path
          d="M12.5003 0V24.0005H17.5004V0H12.5003ZM0 8.00016V16.0003H30.0006V8.00016H0Z"
          fill="white"
        />
        <path
          d="M0 9.60019V14.4003H30.0006V9.60019H0ZM13.5003 0V24.0005H16.5003V0H13.5003Z"
          fill="#CC0000"
        />
        <path
          d="M0 24.0005L10.0002 16.0003H12.2362L2.23604 24.0005H0ZM0 0L10.0002 8.00016H7.76416L0 1.78892L0 0ZM17.7644 8.00016L27.7646 0H30.0006L20.0004 8.00016H17.7644ZM30.0006 24.0005L20.0004 16.0003H22.2364L30.0006 22.2116V24.0005Z"
          fill="#CC0000"
        />
      </g>
      <defs>
        <clipPath id="clip0_657_4434">
          <rect width="30" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UsFlag;
