import React, { useEffect, useRef, useState } from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import DownloadIcon from "../../Components/Svgicons/DownloadIcon";
import {
  useDeleteNomineeMutation,
  useDeleteSharedFileMutation,
  useGetSharedDocsQuery,
} from "../../Services/DashboardApi/dashboardApiSlice";
import {
  downloadUrl,
  handleDownload,
  handleViewDocs,
  useWindowSize,
} from "../../Services/Utils/helper";
import { useDispatch } from "react-redux";
import PaginationPerPage from "../../Components/pagination/PaginationPerPage";
import { useTranslation } from "react-i18next";
import AddNomineeModal from "./AddNomineeModal";
import DeleteIcon from "../../Components/Svgicons/DeleteIcon";
import UserEditIcon from "../../Components/Svgicons/UserEditIcon";
import DeleteModal from "../../Admin/Modals/DeleteModal";
import { toast } from "react-toastify";
import ViewIcon from "../../Components/Svgicons/ViewIcon";
import { apiSlice } from "../../Services/ApiSlice/apiSlice";
import { GridCardContainer, PaginationCommonContainer, StyledContainer, TextCommonContainer } from "../../Components/StyledComponents/CommonStyled";
import StyledCard from "../../Components/StyledComponents/StyledCard";
import StyledIconInput from "../../Components/StyledComponents/StyledIconInput";
import ConfirmDeleteModal from "../../Admin/Modals/ConfirmDeleteModal";
import CardSkeleton from "../../Components/Skeletons/CardSkeleton";
import SharedDocZeroState from "../../Components/Svgicons/SharedDocZeroState";

const SharedDocument = ({ activeTab }) => {
  const { t } = useTranslation();
  const openRef = useRef(null);
  const dropdownRef = useRef(null);
  const [deleteNomineeData, { isLoading: deleteLoading }] =
    useDeleteNomineeMutation();
  const dispatch = useDispatch();
  const [deleteFile, { isLoading: deleteFileloading }] =
    useDeleteSharedFileMutation();
  const [showFilter, setShowFilter] = useState(false);
  const isSmallScreen = useWindowSize();
  // const [sorted, setSorted] = useState(null);
  const [timer, setSearchTimer] = useState(null);
  const [openNomineeModal, setOpenNomineeModal] = useState(false);

  const [fileDetails, setFileDetails] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [deleteNominee, setDeleteNominee] = useState(false);
  const [deleteShareFile, setDeleteSharedFile] = useState(false);

  const initialFilters = {
    perPage: 5,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [filters, setFilters] = useState({ ...initialFilters });
  // eslint-disable-next-line
  const [listing, setListing] = useState("");
  const [nomineeDetails, setNomineeDetails] = useState("");
  const {
    data: getSharedDocs,
    refetch: refetchDocs,
    isLoading
  } = useGetSharedDocsQuery(
    {
      perPage: filters.perPage,
      page: filters.page,
      query: filters.query && filters.query,
      sort: filters.sortedColumn.path || listing.sort,
      sortDir: filters.sortedColumn.order || listing.sortDir,
      ...listing,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const MenuItems = [
    {
      name: t("client:editBtnTitleNewNominee"),
      icon: <UserEditIcon />,
      action: (item) => {        
        setOpenNomineeModal(true);
        setNomineeDetails(item);
      },
    },
    {
      name: t("common:DeleteNominee"),
      icon: <DeleteIcon size={18} color={theme.dimGray} />,
      action: (item) => {
        setDeleteNominee(true);
        setNomineeDetails(item);
      },
    },
  ];


  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref?.current && !ref?.current?.contains(event?.target)) {
          setShowFilter(false);
          setShowDropdown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
      // eslint-disable-next-line
    }, [ref]);
  }
  useOutsideAlerter((showFilter && openRef) || (showDropdown && dropdownRef));

  useEffect(() => {
    activeTab && refetchDocs();
    // eslint-disable-next-line
  }, [activeTab]);

  const handlePerPageChange = (perPage) => {
    setFilters((prevState) => ({
      ...prevState,
      perPage: perPage.target.innerText,
      page: 1,
    }));
  };

  const handlePageChange = ({ selected }) => {
    setFilters((prevState) => ({
      ...prevState,
      page: selected + 1,
    }));
  };



  const handleSearch = (evt) => {
    clearTimeout(timer);
    const query = evt.target.value;
    const newTimer = setTimeout(() => {
      setFilters({ ...filters, page: 1, query: query });
    }, 1000);
    setSearchTimer(newTimer);
  };


  const handleDelete = () => {
    deleteFile(fileDetails?.conversationId).then((Resp) => {
      if (getSharedDocs?.data?.conversation?.length === 1 && getSharedDocs?.data?.page > 1) {
        // If only one item was on the current page and it is deleted, move to the previous page
        setFilters((prevState) => ({
          ...prevState,
          page: prevState?.page - 1, // Go to the previous page
        }));
      } else {
        refetchDocs(); // Refetch the current page
      }
      setDeleteSharedFile(false);
      setFileDetails("");
      toast.success(t("common:fileDeletedSuccessfully"));
      const removeCache = apiSlice.util.resetApiState("getConversation");
      dispatch(removeCache);
    });
  };


  const handleDeleteNominee = () => {
    let payload = {
      id: nomineeDetails?.id,
      fileId: nomineeDetails?.fileId,
    };
    deleteNomineeData(payload).then((Resp) => {
      refetchDocs();
      setDeleteNominee(false);
      toast.success(t("common:nomieeDeletedSuccessfully",{firstname:nomineeDetails?.user?.firstName,lastname:nomineeDetails?.user?.lastName}));
      setNomineeDetails("");
    });

  };
  

  const options = [
    {
      icon: <DownloadIcon size="18px" />,
      onClick: (item) => handleDownload(item?.file),
    },
    {
      icon: <DeleteIcon size="18px" />,
      onClick: (item) => {
        setDeleteSharedFile(true);
        setFileDetails(item?.file);
      },
    },
    {
      icon: <ViewIcon size="18px" />,
      onClick: (item) => { handleViewDocs(item?.file?.s3Object?.signedUrl) },
    },

  ]

  return (
    <div className="bg-white">
      <div className="container-fluid px-0">
        <TextCommonContainer className="row align-items-center m-0">
          <div className="col-md-6 col-sm-12 text-center text-md-start px-0">
            <div >
              <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary}>
                {t("client:chatHistory")}
              </StyledText>
              <StyledText as='p' $fontWeight={400} $fontSize="13px" color={theme.doveGray}>
                {t("client:yourLastLoginChat")}
              </StyledText>
            </div>
          </div>
          <div className="col-md-6 col-sm-12 px-0">
            <StyledIconInput onChange={handleSearch} placeholder={t("common:searchDocument")} $maxWidth={isSmallScreen ? '100%' : "360px"} className="d-flex justify-content-end" />
          </div>
        </TextCommonContainer>
      </div>

      <StyledContainer height={isSmallScreen && '235px'} zeroState={!isLoading && (getSharedDocs?.data?.conversation?.length === 0 || getSharedDocs?.data?.conversation?.length === undefined)}>
        {!isLoading && (getSharedDocs?.data?.conversation?.length === 0 || getSharedDocs?.data?.conversation?.length === undefined) ?
          <div className="d-flex flex-column align-items-center justify-content-center h-100">
            <div className="d-flex align-items-center justify-content-center">
              <SharedDocZeroState />
            </div>
            <StyledText
              color={theme.gray}
              $fontWeight={600}
              $fontSize="20px"
              className="text-center mt-3"
            >
              {t('common:noDocumentSharedYet')}
            </StyledText>
            <StyledText
              color={theme.gray}
              $fontWeight={500}
              $fontSize="14px"
              className="text-center"
            >
              {t('common:allDocumentShared')}
            </StyledText>
          </div> : isLoading ? <GridCardContainer><CardSkeleton /></GridCardContainer> :


        <GridCardContainer>
          {getSharedDocs?.data?.conversation?.map((item, index) => (
            <StyledCard options={options} showNomineeBtn={true} data={item} key={index} nomineeMenu={MenuItems} setOpenNomineeModal={setOpenNomineeModal} setShowDropdown={setShowDropdown} addNominee={() => {
              setOpenNomineeModal(true);
              setFileDetails(item?.file)
            }} />
          ))}
        </GridCardContainer>
        }

      </StyledContainer>
      {isLoading ? null : !isLoading && (getSharedDocs?.data?.conversation?.length === 0 || getSharedDocs?.data?.conversation?.length === undefined) ? null : <PaginationCommonContainer>
        <PaginationPerPage
          perPage={getSharedDocs?.data?.perPage || 0}
          pageNo={getSharedDocs?.data?.page || 0}
          onPerPageChange={handlePerPageChange}
          totalCount={getSharedDocs?.data?.total || 0}
          onPageChange={handlePageChange}
        />
      </PaginationCommonContainer>}

      {openNomineeModal && (
        <AddNomineeModal
          show={openNomineeModal}
          onHide={() => {
            setOpenNomineeModal(false);
            nomineeDetails && setNomineeDetails("");
          }}
          file={fileDetails}
          refetch={refetchDocs}
          isEdit={nomineeDetails ?? false}
          setNomineeDetails={setNomineeDetails}
        // setIsEditAdmin={setIsEditAdmin}
        />
      )}


      {deleteNominee && (
        <ConfirmDeleteModal
          show={deleteNominee}
          onHide={() => {
            setNomineeDetails("");
            setDeleteNominee(false);
          }}
          handleDelete={handleDeleteNominee}
          disabled={deleteLoading}
          loading={deleteLoading}
          placeholderText={t("client:typeYes")}
          descriptionText={t("client:byDeleteThisNominee")}

        />
      )}

      {deleteShareFile && (
        <DeleteModal
          show={deleteShareFile}
          onHide={() => {
            setDeleteSharedFile(false);
            setFileDetails(null);

          }}
          handleDelete={handleDelete}
          disabled={deleteFileloading}
          loading={deleteFileloading}

        />
      )}
    </div>
  );
};



export default SharedDocument;
