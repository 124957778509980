const ThreeDotsIcon = ({
  height = "33",
  width = "24",
  rotate = "right",
  transition,
  ...props
}) => {
  const getRotateDeg = (val) => {
    switch (val) {
      case 'down':
        return '90';
      case 'left':
        return '180';
      case 'up':
        return '270';
      default:
        return '0';
    }
  };
  return (
    <svg
      width="16"
      height="4"
      viewBox="0 0 16 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${getRotateDeg(rotate)}deg)` }}

    >
      <circle cx="2" cy="2" r="2" fill="#6C6C6C" />
      <circle cx="8" cy="2" r="2" fill="#6C6C6C" />
      <circle cx="14" cy="2" r="2" fill="#6C6C6C" />
    </svg>
  );
};

export default ThreeDotsIcon;
