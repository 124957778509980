import React from "react";
import { Row } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const StyledProfileSkeleton = ({ hide, isButton }) => {
    return (
        <div className="d-sm-flex justify-content-sm-center">
      <div className="col-sm col-sm-10 col-lg-8 mt-0 mt-sm-5 bg-white p-3 p-md-5 rounded-3">
        <div className="d-flex align-items-center gap-2">
            <Skeleton height={35} width={100} className="me-2 mt-2" />
        </div>
        <div className="d-block d-md-flex justify-content-between border rounded-3 p-4 mt-5 align-items-center">
          <div className="d-flex align-items-center gap-4 mb-2 mb-md-0">
            <Skeleton height={60} width={60} className="me-2 mt-2 rounded-circle" />
            <div className="d-flex flex-column ">
                <Skeleton height={20} width={120} className="me-2 mt-2" />
                <Skeleton height={20} width={120} className="me-2 mt-2" />
            </div>
          </div>
        </div>
        <div className="border rounded-3 p-4 mt-4">
            <Skeleton height={20} width={200} className="me-2 mt-2" />
        <Row className="mt-4">
          <div className="col-12 col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
                <Skeleton height={20} width={150} className="me-2 mt-2" />
                <Skeleton height={20} width={150} className="me-2 mt-2" />
            </div>
          </div>
          <div className="col-12  col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
                <Skeleton height={20} width={150} className="me-2 mt-2" />
                <Skeleton height={20} width={150} className="me-2 mt-2" />
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
                <Skeleton height={20} width={150} className="me-2 mt-2" />
                <Skeleton height={20} width={150} className="me-2 mt-2" />
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
                <Skeleton height={20} width={150} className="me-2 mt-2" />
                <Skeleton height={20} width={150} className="me-2 mt-2" />
            </div>
          </div>
        </Row>
      </div>
    {!hide ? (
      <div className="border rounded-3 p-4 mt-4">
        <Skeleton height={20} width={200} className="me-2 mt-2" />
        <Row className="mt-4">
          <div className="col-12 col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
                <Skeleton height={20} width={150} className="me-2 mt-2" />
                <Skeleton height={20} width={150} className="me-2 mt-2" />
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4 mt-sm-0">
            <div className="d-flex flex-column">
                <Skeleton height={20} width={150} className="me-2 mt-2" />
                <Skeleton height={20} width={150} className="me-2 mt-2" />
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
                <Skeleton height={20} width={150} className="me-2 mt-2" />
                <Skeleton height={20} width={150} className="me-2 mt-2" />
            </div>
          </div>
          <div className="col-12 col-sm-6 mt-4">
            <div className="d-flex flex-column">
                <Skeleton height={20} width={150} className="me-2 mt-2" />
                <Skeleton height={20} width={150} className="me-2 mt-2" />
            </div>
          </div>
        </Row> 
      </div>
      ) : null }
      {isButton ? <Skeleton height={35} width={150} className="me-2 mt-3" /> : null}
        
      </div>
    </div>
    );
};

export default StyledProfileSkeleton;