const AdminIcon = ({
  size = 22,
  rotate = "down",
  color = "#585558",
  transition,
  ...props
}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.0003 10.9997C13.5316 10.9997 15.5837 8.94765 15.5837 6.41634C15.5837 3.88504 13.5316 1.83301 11.0003 1.83301C8.46902 1.83301 6.41699 3.88504 6.41699 6.41634C6.41699 8.94765 8.46902 10.9997 11.0003 10.9997Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M3.12598 20.1667C3.12598 16.6192 6.65514 13.75 11.0001 13.75C11.8801 13.75 12.7326 13.8692 13.5301 14.0892" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.1663 16.4997C20.1663 17.1872 19.9739 17.838 19.6347 18.388C19.4422 18.718 19.1947 19.0113 18.9105 19.2497C18.2688 19.8272 17.4255 20.1663 16.4997 20.1663C15.1613 20.1663 13.9972 19.4513 13.3647 18.388C13.0255 17.838 12.833 17.1872 12.833 16.4997C12.833 15.3447 13.3647 14.3088 14.208 13.6397C14.8405 13.1355 15.638 12.833 16.4997 12.833C18.5255 12.833 20.1663 14.4738 20.1663 16.4997Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.0693 16.4999L15.9768 17.4074L17.9293 15.6016" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


  );
};

export default AdminIcon;
