const DocumentPostingIcon = ({ size = 22, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M18.3334 7.56266V16.5002C18.3334 19.2502 16.6925 20.1668 14.6667 20.1668H7.33335C5.30752 20.1668 3.66669 19.2502 3.66669 16.5002V7.56266C3.66669 4.5835 5.30752 3.896 7.33335 3.896C7.33335 4.46433 7.5625 4.97766 7.93833 5.35349C8.31416 5.72933 8.82752 5.9585 9.39585 5.9585H12.6042C13.7409 5.9585 14.6667 5.03266 14.6667 3.896C16.6925 3.896 18.3334 4.5835 18.3334 7.56266Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.6666 3.896C14.6666 5.03266 13.7408 5.9585 12.6041 5.9585H9.39581C8.82748 5.9585 8.31412 5.72933 7.93829 5.35349C7.56246 4.97766 7.33331 4.46433 7.33331 3.896C7.33331 2.75933 8.25915 1.8335 9.39581 1.8335H12.6041C13.1725 1.8335 13.6858 2.06267 14.0617 2.4385C14.4375 2.81433 14.6666 3.32766 14.6666 3.896Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.33331 11.9165H11" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.33331 15.5835H14.6666" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  );
};

export default DocumentPostingIcon;
