import React from 'react'
import { DocumentCard, StyledBadge } from './CommonStyled'
import StyledText from './StyledText'
import theme from '../../Services/Utils/themes'
import { DefaulImage, getIconByFileExtension, handleErrorImg } from '../../Services/Utils/helper'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import StyledImage from './StyledImage'

const StyledResourceCard = ({ options, data }) => {
    const { t } = useTranslation();
    return (
        <DocumentCard>

            <div className='p-3'>
                <div className='d-flex flex-column gap-3 w-100' >
                    <div className='d-flex gap-2 align-items-center'>
                        <div>
                            {data?.image ? <StyledImage height="72px" width="54px" radius="2px" rounded>
                                <img src={data?.image || DefaulImage.document } alt="image"/>
                            </StyledImage> : getIconByFileExtension(data?.fileName)}
                        </div>
                        <div className='d-flex flex-column'>
                            <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary} truncatedlines={1} truncateWidth="`180px">
                                {data?.fileName}
                            </StyledText>
                            <StyledText as='label' $fontWeight={400} $fontSize="12px" color={theme.stormDust}>
                                {t('common:uploadedBy')}&nbsp;:&nbsp;{`${data?.admin?.firstName || "-"} ${data?.admin?.lastName || "-"}`}
                            </StyledText>
                            <StyledText as='label' $fontWeight={400} $fontSize="12px" color={theme.stormDust}>
                                {t('common:uploadedAt')}&nbsp;:&nbsp;{data?.createdAt
                                    ? moment(data?.createdAt).format("MMMM DD, YYYY")
                                    : "-"}
                            </StyledText>
                        </div>
                    </div>
                    <StyledBadge>
                        {data?.resourceCategory?.title}
                    </StyledBadge>
                </div>

            </div>

            {options &&
                <div className='d-flex align-items-center justify-content-between py-3 border-top '>
                    {options?.map((item, index) => (
                        <div className={`d-flex align-items-center justify-content-center cursor-pointer w-100 ${index == options?.length - 1 ? '' : 'border-end'}`} key={index} onClick={() => item?.onClick(data)}>
                            {item?.icon}
                        </div>
                    ))}
                </div>}
        </DocumentCard>
    )
};


export default StyledResourceCard