const ConversationIcon = ({ size = 22, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M16.4817 9.89084V13.5575C16.4817 13.7958 16.4725 14.025 16.445 14.245C16.2341 16.72 14.7766 17.9483 12.0908 17.9483H11.7242C11.495 17.9483 11.275 18.0583 11.1375 18.2417L10.0375 19.7083C9.55166 20.3592 8.76331 20.3592 8.27748 19.7083L7.17747 18.2417C7.0583 18.0858 6.79248 17.9483 6.59082 17.9483H6.22415C3.29999 17.9483 1.83331 17.2242 1.83331 13.5575V9.89084C1.83331 7.20501 3.07082 5.74751 5.53665 5.53667C5.75665 5.50917 5.98582 5.5 6.22415 5.5H12.0908C15.015 5.5 16.4817 6.96667 16.4817 9.89084Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M20.1484 6.22434V9.891C20.1484 12.586 18.9108 14.0343 16.445 14.2452C16.4725 14.0252 16.4817 13.796 16.4817 13.5577V9.891C16.4817 6.96684 15.015 5.50016 12.0908 5.50016H6.22418C5.98585 5.50016 5.75668 5.50934 5.53668 5.53684C5.74752 3.071 7.20502 1.8335 9.89085 1.8335H15.7575C18.6817 1.8335 20.1484 3.30017 20.1484 6.22434Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.3709 12.1458H12.3791" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.16252 12.1458H9.17077" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.95421 12.1458H5.96246" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


  );
};

export default ConversationIcon;
