const UsFlagRounded = ({
  size = 14,
  rotate = "down",
  transition,
  ...props
}) => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="11" cy="11" r="11" fill="#D9D9D9" />
      <g clipPath="url(#clip0_663_4465)">
        <rect width="30" height="24" rx="2" fill="#005AAD" />
        <path d="M22.0004 0H0V22.0004H22.0004V0Z" fill="#000066" />
        <path
          d="M0 0V2.45972L19.5408 22.0005H22.0004V19.5409L2.45965 7.33348e-05L0 0ZM22.0004 0V2.45965L2.45965 22.0004H0V19.5408L19.5408 0H22.0004Z"
          fill="white"
        />
        <path
          d="M9.16685 0V22.0004H12.8336V0H9.16685ZM0 7.33348V14.667H22.0004V7.33348H0Z"
          fill="white"
        />
        <path
          d="M0 8.80018V13.2003H22.0004V8.80018H0ZM9.9002 0V22.0004H12.1002V0H9.9002Z"
          fill="#CC0000"
        />
        <path
          d="M0 22.0004L7.33348 14.667H8.97325L1.63977 22.0004H0ZM0 0L7.33348 7.33348H5.69371L0 1.63984L0 0ZM13.0272 7.33348L20.3607 0H22.0004L14.667 7.33348H13.0272ZM22.0004 22.0004L14.667 14.667H16.3067L22.0004 20.3607V22.0004Z"
          fill="#CC0000"
        />
      </g>
      <defs>
        <clipPath id="clip0_663_4465">
          <rect width="22" height="22" rx="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UsFlagRounded;
