// Dropzone.js

import React from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import AddFileIcon from '../Svgicons/AddFileIcon';
import theme from '../../Services/Utils/themes';
import StyledText from './StyledText';
import { formatFileSize, getIconByFileExtension } from '../../Services/Utils/helper';
import { isEmpty } from 'lodash';
import DeleteIcon from '../Svgicons/DeleteIcon';
import { useTranslation } from 'react-i18next';

function StyledDropzone({ onDrop, accept, selectedFiles, error, setFieldValue, name }) {
    const {t} =  useTranslation();
    const { getRootProps, getInputProps } = useDropzone({
        accept,
        onDrop,
    });

    const removeFile = () => {
        setFieldValue(name, null);
    };


    return (
        <DropzoneContainer className={`'flex-column`}>

            {!isEmpty(selectedFiles) ? (
                <ImageContainer className='d-flex align-items-center flex-wrap justify-content-start'>
                    <StyledImageCard>
                        <div>{getIconByFileExtension(selectedFiles?.path)}</div>
                        <div className='d-flex flex-column ml-2 text-truncate'>
                            <StyledText $fontSize={'13px'} $fontWeight={600} color={theme.secondary} truncatedlines={1}>{selectedFiles?.path}</StyledText>
                            <StyledText $fontWeight={400} $fontSize={'12px'} color={theme.gray}>
                                {formatFileSize(selectedFiles?.size)}
                            </StyledText>
                        </div>
                        <div className='d-flex flex-end'>
                            <StyledIcon
                                color='white'
                                className='cursor-pointer ml-2'
                                background={theme?.danger}
                                onClick={() => removeFile()}
                                width={32}
                                height={32}
                            >
                                <DeleteIcon color={theme.white} />
                            </StyledIcon>
                        </div>
                    </StyledImageCard>
                </ImageContainer>
            ) : null}

            <StyledDrop error={error} selectedFiles={selectedFiles} {...getRootProps()}>
                <input {...getInputProps()} />
                <div className='d-flex align-items-center justify-content-center flex-wrap gap-3'>
                    <AddFileIcon />
                    <TextContainer className='d-flex flex-column ml-2'>
                        <StyledText $fontSize={'14px'} $fontWeight={600} color={theme.secondary}>{t('admin:dragAndDropFile')}</StyledText>
                        <StyledText $fontSize={'12px'} $fontWeight={400} color={theme.gray}>
                        {t('admin:selectSingleFile')}
                        </StyledText>
                    </TextContainer>
                </div>
            </StyledDrop>

        </DropzoneContainer>
    );
}


const DropzoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 222px;
  max-height: max-content;
  background-color: #f7f7f7;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
`;


export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => width + 'px'};
  height: ${({ height }) => height + 'px'};
  border-radius: 50px;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
`;


const StyledDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ selectedFiles }) => (!isEmpty(selectedFiles) ? '90px' : '175px')};
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  border: ${({ error }) => (error ? `1px dashed ${theme.danger}` : `1px dashed #a5a5a5`)};

  @media only screen and (max-width: 459px) {
    height: ${({ selectedFiles }) => (!isEmpty(selectedFiles) ? '140px' : '175px')};
  }
`;

const TextContainer = styled.div`
  @media only screen and (max-width: 459px) {
    text-align: center;
  }
`;


const StyledImageCard = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  height: 67px;
  padding: 0 10px;
`;


const ImageContainer = styled.div`
  gap: 10px;
  width: 100%;
  margin-bottom: 10px;
`;


export default StyledDropzone;
