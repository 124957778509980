import React from "react";
import { Modal } from "react-bootstrap";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { Formik, Form } from "formik";
import AddIcon from "../../Components/Svgicons/AddIcon";
import CrossIcon from "../../Components/Svgicons/CrossIcon";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useDocumnetPreSignedUrlMutation, useUploadDocumentMutation } from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import FormikDropZone from "../../Components/Formik/FormikDropZone";
import FormikAsyncSelect from "../../Components/Formik/FormikAsyncSelect";

const AddDocumentModal = ({
  show,
  onHide,
  refetchDocs
}) => {
  const { t } = useTranslation();
  const [documnetPreSignedUrl] = useDocumnetPreSignedUrlMutation();
  const [uploadDcoument, { isLoading: loading }] = useUploadDocumentMutation();

  const handleUploadFile = async (acceptedFiles, setFieldValue) => {


    acceptedFiles?.map(async (file) => {
      const { data: { response: responseData } } = await documnetPreSignedUrl({
        fileName: file.name,
        fileType: file.type,
      });
      if (!responseData?.url) {
        toast.error(
          'Could not process the upload. Please make sure the file is valid and the internet connection is stable',
        );
      }
      await fetch(`${responseData?.url}`, {
        method: responseData?.method,
        headers: {
          Accept: 'application/json',
          'Content-Type': file.type,
        },
        body: file,
      });
      setFieldValue('document', { file, key: responseData.key });
      return file;
    });

  };

  const handleSubmit = (values) => {
    let payload = {
      resourceCategoryId: values?.fileType?.id,
      originalFilename: values?.document?.file?.name,
      s3Key: values?.document?.key,
    }

    uploadDcoument(payload).then((Resp) => {
      onHide();
      if (Resp?.error?.status === 400) {
        toast.error(Resp?.error?.data?.message);
      } else {
        toast.success(t('common:documentUploaded'));
        refetchDocs()
      }

    });
  };



  return (
    <Modal show={show} centered onHide={onHide} size={"lg"}>
      <Modal.Body className="d-flex flex-column bg-body rounded p-3 p-sm-5">
        <div className="d-flex align-item-center justify-content-between">
          <div className="d-flex flex-column w-100 align-items-center justify-content-center">
            <StyledText
              as="h1"
              $fontSize="20px"
              className="mb-0"
              $fontWeight={600}
              color={theme.secondary}
            >
              {t("admin:addNewDocument")}
            </StyledText>

            <StyledText
              as="p"
              className="mb-0"
              $fontSize="14px"
              $fontWeight={400}
              color={theme.gray}
            >
               {t("admin:uploadNewDocAsResource")}
            </StyledText>
          </div>
          <div onClick={onHide} className="cursor-pointer d-block d-md-none">
            <CrossIcon />
          </div>
        </div>
        <hr className="mt-4" />
        <Formik
          initialValues={{
            document: '',
            fileType: ''
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form>

              <div className="my-4">
                <div className='d-flex align-items-baseline mb-1'>

                  <StyledText
                    as='label'
                    htmlFor='fileType'
                    $fontWeight={600}
                    $fontSize="14px"
                    color={theme.headingTextColor}
                    className='ml-1'
                  >
                    {t("admin:selectDocumentType")}
                  </StyledText>
                  <StyledText
                    as='label'
                    htmlFor='fileType'
                    color={theme.danger}
                    className='mt-2'
                  >
                    *
                  </StyledText>
                </div>

                <FormikAsyncSelect
                  name="fileType"
                  id="fileType"
                  type="fileCategory"
                  dropdownCustomStyle={false}
                  placeholder={t("admin:selectDocumentTypeHere")}

                />
              </div>
              <FormikDropZone
                name='document'
                isShowError
                setFieldValue={setFieldValue}
                selectedFiles={values?.document?.file}
                onDrop={(val) =>
                  handleUploadFile(
                    val,
                    setFieldValue
                  )
                }
              />
              <StyledButton
                icon={
                  <AddIcon />
                }
                className={`mt-4 py-2 w-100 text-white d-inline-flex ${values?.document?.file && values?.fileType ? '' : 'feature-pending'}`}
                type="submit"
                loading={loading}
                disabled={loading}
              >
                {t("admin:addDocument")}
              </StyledButton>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddDocumentModal;
