const SelectIcon = ({ size = 21, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00065 0.333496C3.32732 0.333496 0.333984 3.32683 0.333984 7.00016C0.333984 10.6735 3.32732 13.6668 7.00065 13.6668C10.674 13.6668 13.6673 10.6735 13.6673 7.00016C13.6673 3.32683 10.674 0.333496 7.00065 0.333496ZM10.1873 5.46683L6.40732 9.24683C6.31398 9.34016 6.18732 9.3935 6.05398 9.3935C5.92065 9.3935 5.79398 9.34016 5.70065 9.24683L3.81398 7.36016C3.62065 7.16683 3.62065 6.84683 3.81398 6.6535C4.00732 6.46016 4.32732 6.46016 4.52065 6.6535L6.05398 8.18683L9.48065 4.76016C9.67398 4.56683 9.99398 4.56683 10.1873 4.76016C10.3807 4.9535 10.3807 5.26683 10.1873 5.46683Z" fill="#C77B21" />
    </svg>


  );
};

export default SelectIcon;
