import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import theme from "../../Services/Utils/themes";
import {
  handleViewDocs,
} from "../../Services/Utils/helper";
import {
  useGetUserFilesQuery,
  useDeleteAdminMessageMutation,
  useGetAdminConversationQuery,
  usePostAdminConversationMutation,
  useReadAdminMessageMutation,
  useRemoveNomineeAccessMutation,
  useDeleteAdminDocumentMutation,
} from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import { cloneDeep, uniqBy } from "lodash";
import StyledChatBox from "../../Components/Chat/StyledChatBox";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { usePreSignedUrlMutation } from "../../Services/DashboardApi/dashboardApiSlice";
import NomineeDetails from "../Modals/NomineeDetails";
import ViewIcon from "../../Components/Svgicons/ViewIcon";
import DeleteIcon from "../../Components/Svgicons/DeleteIcon";
import DeleteModal from "../Modals/DeleteModal";
import { toast } from "react-toastify";
import { apiSlice } from "../../Services/ApiSlice/apiSlice";
import AdminSharedDocSidebar from "../AdminComponents/AdminSidebar/AdminSharedDocSidebar";
import { SetIsSidemenu } from "../../Services/SharedDocumentsSlice/SidemenuSlice";
import { DocsOverlay } from "../../CommonComponents/SharedDocSidebarStyling";
import { svenskaLs } from "../../Services/Utils/localStorage";

const AdminChat = (props) => {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state?.auth);
  const [deleteAdminMessage] = useDeleteAdminMessageMutation();
  const [getPreSignedUrl] = usePreSignedUrlMutation();
  const { isDocsSidemenu } = useSelector(state => state?.docsSidebar);
  const lang = svenskaLs.getItem("lng") || "swe";

  const dispatch = useDispatch();
  const messageEndRef = useRef(null);
  const [fileCategory, setFileCategory] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const [nomineeDetails, setNomineeDetails] = useState([]);
  const [dropdown, setDropdown] = useState("");
  const [deleteShareFile, setDeleteSharedFile] = useState(false);
  const [deletedFile, setDeletedFile] = useState(null);
  const [progress, setProgress] = useState(false);

  const [loader, setLoader] = useState("");
  const dropRef = useRef();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const openRef = useRef(null);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState(null);
  const [removeNomineeAccess, { isLoading: removeAccessLoading }] =
    useRemoveNomineeAccessMutation();
  const [readMessage] = useReadAdminMessageMutation();
  const [deleteAdminDocument, { isLoading: deleteLoading }] =
    useDeleteAdminDocumentMutation();
  const initialFilters = {
    perPage: 20,
    page: 1,
    query: "",
  };

  const initialSharedDocsFilters = {
    perPage: 10,
    page: 1,
    query: "",
    total: 0,
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [sharedDocsfilters, setSharedDocsFilters] = useState({ ...initialSharedDocsFilters });
  const [filters, setFilters] = useState({ ...initialFilters });

  const fileref = useRef(null);

  const [postConversation, { isLoading: submitLoading }] =
    usePostAdminConversationMutation();

  const {
    data: getAdminConversation,
    refetch: refetchConversation,
    isLoading: loadMessage,
  } = useGetAdminConversationQuery(
    {
      id: id,
      perPage: filters.perPage,
      page: filters.page,
      ...filters,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const [hasReadMessages, setHasReadMessages] = useState(false);

  useEffect(() => {
    if (getAdminConversation?.data?.conversation?.conversation?.length && !hasReadMessages) {
      readMessage(id);
      setHasReadMessages(true);
    }
  }, [getAdminConversation?.data?.conversation?.conversation, hasReadMessages, id]);

  useEffect(() => {
    if (getAdminConversation?.data?.conversation?.conversation?.length) {
      let response =
        getAdminConversation?.data?.conversation?.conversation &&
        cloneDeep([
          ...getAdminConversation?.data?.conversation?.conversation,
        ]).reverse();
      setData((prev) => uniqBy(response?.concat(prev), "id"));
    }
    // eslint-disable-next-line
  }, [getAdminConversation?.data?.conversation?.conversation]);


  const { data: getUserFiles, refetch: isRefetch, isLoading } = useGetUserFilesQuery(
    {
      id, params: {
        perPage: sharedDocsfilters.perPage,
        page: sharedDocsfilters.page,
        query: sharedDocsfilters.query,
        sort: sharedDocsfilters.sortedColumn.path,
        sortDir: sharedDocsfilters.sortedColumn.order,
      }
    },
    {
      skip: !id,
    }
  );

  const handleSubmit = () => {

    const cleanedMessage = message?.replace(/<p><br><\/p>/g, '')?.trim();
    const textOnlyContent = cleanedMessage?.replace(/<\/?[^>]+(>|$)/g, '')?.trim();

    if (textOnlyContent !== '' || file) {
      let payloadData = {
        message: textOnlyContent !== '' ? cleanedMessage : undefined,
        originalFilename: file ? file?.file?.name : undefined,
        s3Key: file ? file?.key : undefined,
        categoryId: fileCategory ? fileCategory?.id : undefined,
        userId: id,
        trans : lang
      };

      postConversation(payloadData).then((resp) => {
        file && isRefetch();
        refetchConversation();
        setMessage("");
        setFile(null);
        setFilters(initialFilters);
        setFileCategory("");
      });
    }
    else {
      toast.error(t("common:emptyMessage"));
    }
  };

  const MenuItems = [
    {
      name: t("common:DeleteDocument"),
      icon: <DeleteIcon size={18} color={theme.dimGray} />,
      action: (item) => {
        setDeletedFile(item?.file);
        setDeleteSharedFile(true);
      },
    },
    {
      name: t("common:ViewDocument"),
      icon: <ViewIcon />,
      action: (item) => {
        handleViewDocs(item?.file?.s3Object?.signedUrl);
      },
    },
  ];

  // const handleUploadFile = async (e) => {
  //   setShowDropdown(false);
  //   const file = e?.target?.files[0];
  //   setFile({ file });
  //   setProgress(true);

  //   if (!file) return;
  //   const {
  //     data: { response: responseData },
  //   } = await getPreSignedUrl({
  //     fileName: file.name,
  //     fileType: file.type,
  //   });
  //   if (!responseData?.url) {
  //     return;
  //   }
  //   await fetch(`${responseData?.url}`, {
  //     method: responseData?.method,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": file.type,
  //     },
  //     body: file,
  //   });
  //   setFile({ file, key: responseData.key });
  //   setProgress(false);

  // };


  const handleUploadFile = async (e) => {
    setShowDropdown(false);
    const file = e?.target?.files[0];
    setFile({ file });
    setProgress(true);
  
    if (!file) return;
  
    try {
      const result = await getPreSignedUrl({
        fileName: file.name,
        fileType: file.type,
      });
  
      // Ensure that data and responseData exist
      const responseData = result?.data?.response;
      
      if (!responseData || !responseData.url) {
        console.error("Error: Pre-signed URL not found or invalid");
        setProgress(false);
        return;
      }
  
      // Perform the file upload using the pre-signed URL
      await fetch(responseData.url, {
        method: responseData.method || 'PUT', // Default to 'PUT' if method is missing
        headers: {
          Accept: "application/json",
          "Content-Type": file.type,
        },
        body: file,
      });
  
      // Set the file and key after upload is successful
      setFile({ file, key: responseData.key });
      
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setProgress(false);
    }
  };
  


  const handleDownload = async (fileId) => {
    const downloadUrl = `${process.env.REACT_APP_API_URL}/backoffice/conversations/${id}/files/download/${fileId}?token=${token}`;
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const viewDocument = async (fileId) => {
    const viewUrl = `${process.env.REACT_APP_API_URL}/backoffice/conversations/${id}/files/download/${fileId}?token=${token}`;
    window.open(viewUrl, "_blank");
  };

  const DeleteMessage = (conId) => {
    let val = {
      conId: conId,
      userId: id,
    };
    deleteAdminMessage(val).then((resp) => {
      setData((prev) => prev.filter((item) => item.id !== conId));
      isRefetch();
      const removeCache = apiSlice.util.resetApiState("getUserFiles");
      dispatch(removeCache);
    });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref?.current && !ref?.current?.contains(event?.target)) {
          setDropdown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  useOutsideAlerter(dropdown && dropRef);

  const handleRemoveAccess = (nominessAccess) => {
    setLoader(nominessAccess);
    let payload = {
      fileId: nominessAccess?.id,
      userId: nominessAccess?.nomineeId,
    };
    removeNomineeAccess(payload).then((resp) => {
      try {
        isRefetch();
        setDeleteSharedFile(false);
        setNomineeDetails([]);
        toast.success(
          resp?.data?.message || "Nominee access Remove Successfully"
        );
      } catch (error) {
        console.log(error);
      }
    });
  };

  const handleDelete = () => {
    deleteAdminDocument(deletedFile?.conversationId).then((Resp) => {
      try {
        setData((prev) =>
          prev.filter((item) => item.id !== deletedFile?.conversationId)
        );
        isRefetch();
        setDeleteSharedFile(false);
        toast.success(t("common:fileDeletedSuccessfully"));
        const removeCache = apiSlice.util.resetApiState("getAdminConversation");
        dispatch(removeCache);
        // refetchConversation();
      } catch (error) {
        console.log(error);
      }
    });
  };






  return (
    <div className="d-block d-md-flex justify-content-sm-center  px-xl-0">
      <StyledChatBox
        fileProgess={progress}
        className={"w-100 adminChatView"}
        chatFor={"admin"}
        messageData={data}
        token={token}
        allowOptions={true}
        messageEndRef={messageEndRef}
        handleSubmit={handleSubmit}
        handleAttachment={handleUploadFile}
        handleDeleteMessage={DeleteMessage}
        openRef={openRef}
        DeleteFile={() => setFile(null)}
        HandleSetMessage={(value) => setMessage(value)}
        fileref={fileref}
        file={file?.file}
        message={message}
        handleDownload={handleDownload}
        handleViewDocs={viewDocument}
        loadMessage={loadMessage}
        submitLoading={submitLoading}
        setFilters={setFilters}
        filters={filters}
        setFileCategory={setFileCategory}
        showDropdown={showDropdown}
        setShowDropdown={setShowDropdown}
        userId={id}
      />
      <AdminSharedDocSidebar isOpen={isDocsSidemenu} data={getUserFiles?.data?.conversation?.conversation} setNomineeDetails={setNomineeDetails} MenuItems={MenuItems} setFilters={setSharedDocsFilters} filters={sharedDocsfilters} paginations={getUserFiles?.data?.conversation} isLoading={isLoading} />
      <DocsOverlay isOpen={isDocsSidemenu} onClick={() => dispatch(SetIsSidemenu({ values: false }))} />

      <NomineeDetails
        show={nomineeDetails?.nomineesFiles?.length ? true : false}
        onHide={() => setNomineeDetails([])}
        nomineeDetails={nomineeDetails}
        handleRemoveAccess={handleRemoveAccess}
        disabled={removeAccessLoading}
        loading={removeAccessLoading}
        loader={loader}
      />
      {deleteShareFile && (
        <DeleteModal
          show={deleteShareFile}
          onHide={() => {
            setDeleteSharedFile(false);
            setDeletedFile(null);
          }}
          handleDelete={handleDelete}
          disabled={deleteLoading}
          loading={deleteLoading}
        />
      )}
    </div>
  );
};


export default AdminChat;
