import { apiSlice } from "../ApiSlice/apiSlice";

const LOGIN_URL = "/api/bankId";
const SIGNUP_URL = "/api/bankId/signup";

export const LoginApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSameDeviceData: builder.mutation({
      query: (params) => ({
        url: `${LOGIN_URL}/auth?isSameDevice=${params}`,
        method: "GET",
      }),
    }),

    getAnotherDeviceData: builder.mutation({
      query: () => ({
        url: `${LOGIN_URL}/auth`,
        method: "GET",
      }),
    }),

    createUser: builder.mutation({
      query: (data) => ({
        url: `/api/user/create-user`,
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),

    getOrderRef: builder.mutation({
      query: (params) => ({
        url: `${LOGIN_URL}/collect?orderRef=${params}`,
        method: "GET",
      }),
    }),

    getSignupOrderRef: builder.mutation({
      query: (params) => ({
        url: `${SIGNUP_URL}/order-status?orderRef=${params}`,
        method: "GET",
      }),
    }),

    getQrCodeData: builder.mutation({
      query: (params) => ({
        url: `${LOGIN_URL}/order-status?orderRef=${params}`,
        method: "GET",
      }),
    }),
    getSignupQrCodeData: builder.mutation({
      query: (params) => ({
        url: `${SIGNUP_URL}/order-status?orderRef=${params}`,
        method: "GET",
      }),
    }),

    userLogin: builder.mutation({
      query: (data) => ({
        url: `/api/user/self/login?email=${data.email}&personalNumber=${data.personalNumber}`,
        method: "GET",
      }),
    }),

  }),
});

export const {
  useGetSameDeviceDataMutation,
  useGetOrderRefMutation,
  useCreateUserMutation,
  useGetSignupQrCodeDataMutation,
  useGetAnotherDeviceDataMutation,
  useGetQrCodeDataMutation,
  useGetSignupOrderRefMutation,
  useUserLoginMutation
} = LoginApiSlice;
