import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: 1,
  profileImage: {},
  adminProfile: {},
  unreadMessage:false,
};

const tabSlice = createSlice({
  name: "activeTab",
  initialState,
  reducers: {
    SetActiveTab: (state, { payload: { values } }) => {
      state.activeTab = values;
    },
    SetProfileImage: (state, { payload: { values } }) => {
      state.profileImage = values;
    },

    SetAdminProfileImage: (state, { payload: { values } }) => {
      state.adminProfile = values;
    },



  },
});

export const { SetActiveTab, SetProfileImage ,SetAdminProfileImage} = tabSlice.actions;

export default tabSlice.reducer;
