import React from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { Row } from "react-bootstrap";
import * as Yup from "yup";
import FormikInputField from "../../Components/Formik/FormikInputField";
import { Form, Formik } from "formik";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import FileIcon2 from "../../Components/Svgicons/FileIcon2";
import moment from "moment";
import { useEditUserProfileMutation } from "../../Services/DashboardApi/dashboardApiSlice";
import { useTranslation } from "react-i18next";

function EditProfile({ setEditProfile, user, refetchData ,setActiveUploadIcon}) {
  const { t } = useTranslation('common');
  const [editProfile, { isLoading: ProfileLoadling }] =
    useEditUserProfileMutation();
  const handleSubmit = (values) => {
    delete values.registeredAt;
    delete values.personalNumber;

    editProfile(values).then((resp) => {
      setEditProfile(false);
      refetchData();
      setActiveUploadIcon(false);
    });
  };

  const userProfileValidation = Yup.object().shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),

    email: Yup.string().email("Invalid email").required("Required"),
    country: Yup.string().required("Required"),

    city: Yup.string().required("Required"),

    postalCode: Yup.string().required("Required"),
    personalNumber: Yup.string().required("Required"),
  });

  let customUser = {
    ...user,
    registeredAt: moment(user?.registeredAt).format("DD/M/YYYY"),
  };
  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          registeredAt: "",
          country: "",
          city: "",
          postalCode: "",
          personalNumber: "",
          ...customUser,
        }}
        validationSchema={userProfileValidation}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <div className="border rounded-3 p-4 mt-4">
              <StyledText
                $fontSize="18px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {t('textPersonalInfo')}
              </StyledText>
              <Row className="mt-4">
                <div className="col-12 col-md-6">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('textFirstName')}
                    </StyledText>
                    <FormikInputField name="firstName" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4 mt-md-0">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('textLastName')}
                    </StyledText>
                    <FormikInputField name="lastName" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('textEmailAddress')}
                    </StyledText>
                    <FormikInputField name="email" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4 feature-pending">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('textRegistrationDate')}
                    </StyledText>
                    <FormikInputField name="registeredAt" />
                  </div>
                </div>

                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('textCountry')}
                    </StyledText>
                    <FormikInputField name="country" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('textCityState')}
                    </StyledText>
                    <FormikInputField name="city" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('textPostalCode')}
                    </StyledText>
                    <FormikInputField name="postalCode" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4 feature-pending">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('textPersonalNo')}
                    </StyledText>
                    <FormikInputField name="personalNumber" />
                  </div>
                </div>
              </Row>
            </div>
            <StyledButton
              icon={<FileIcon2 />}
              className="text-white rounded-2 mt-3"
              type="submit"
              disabled={ProfileLoadling}
              loading={ProfileLoadling}
            >
              {t('textBtnSaveProfile')}
            </StyledButton>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default EditProfile;
