const UnreadIcon = ({ size = 21, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.4355 7.6087V9.89131C17.4355 12.9348 15.9137 14.4565 12.8703 14.4565H12.4898C12.2539 14.4565 12.0257 14.5707 11.8811 14.7609L10.7398 16.2826C10.2376 16.9522 9.41591 16.9522 8.91373 16.2826L7.77243 14.7609C7.65069 14.5935 7.36917 14.4565 7.16373 14.4565H6.7833C3.73982 14.4565 2.21808 13.6957 2.21808 9.89131V6.08696C2.21808 3.04348 3.73982 1.52174 6.7833 1.52174H11.3485" stroke="#35292D" strokeWidth="1.27826" stroke-miterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M15.5333 5.32609C16.5839 5.32609 17.4355 4.47446 17.4355 3.42392C17.4355 2.37338 16.5839 1.52174 15.5333 1.52174C14.4828 1.52174 13.6312 2.37338 13.6312 3.42392C13.6312 4.47446 14.4828 5.32609 15.5333 5.32609Z" stroke="#35292D" strokeWidth="1.27826" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M12.8675 8.36957H12.8744" stroke="#35292D" strokeWidth="1.82609" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M9.82334 8.36957H9.83017" stroke="#35292D" strokeWidth="1.82609" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M6.77915 8.36957H6.78598" stroke="#35292D" strokeWidth="1.82609" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


  );
};

export default UnreadIcon;
