import React, { useEffect, useRef, useState } from 'react';
import { DocumentCard, SharedDocSidebarContainer } from '../../CommonComponents/SharedDocSidebarStyling';
import StyledText from '../../Components/StyledComponents/StyledText';
import theme from '../../Services/Utils/themes';
import { StyledBadge } from '../../Components/StyledComponents/CommonStyled';
import ThreeDotsIcon from '../../Components/Svgicons/ThreeDotsIcon';
import { useGetSharedDocsQuery } from '../../Services/DashboardApi/dashboardApiSlice';
import { downloadUrl, getIconByFileExtension, handleDownload, handleViewDocs } from '../../Services/Utils/helper';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { SetSharedDocs } from '../../Services/SharedDocumentsSlice/SharedDocumentSlice';
import { uniqBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import CustomDropdown from '../../Components/dropdown/CustomDropDown';
import { DropdownItem } from 'react-bootstrap';
import ViewIcon from '../../Components/Svgicons/ViewIcon';
import DownloadIcon from '../../Components/Svgicons/DownloadIcon';
import SidebarDocsSkeleton from '../../Components/Skeletons/SidebarDocsSkeleton';
import SharedDocZeroState from '../../Components/Svgicons/SharedDocZeroState';
import { apiSlice } from '../../Services/ApiSlice/apiSlice';

const SharedDocSidebar = ({ isOpen }) => {

  const { sharedDocs } = useSelector((state) => state?.sharedDocs);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const [docsdata, setDocsData] = useState([]);
  const { t } = useTranslation();
  const initialFilters = {
    perPage: 10,
    page: 1,
    query: "",
    total: 0,
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const [filters, setFilters] = useState({ ...initialFilters });

  const { data, refetch: refetchDocs, isLoading } = useGetSharedDocsQuery(
    {
      perPage: filters.perPage,
      page: filters.page,
      query: filters.query,
      sort: filters.sortedColumn.path,
      sortDir: filters.sortedColumn.order,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const maxPage = Math.ceil(data?.data?.total / data?.data?.perPage);


  useEffect(() => {
    // if (data?.data?.conversation?.length) {

      let response = data?.data?.conversation;

      setDocsData((prev) => uniqBy(response?.concat(prev), "id"));
    // }
  }, [data?.data?.conversation])

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight && data?.data?.page !== maxPage) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: prevFilters.page + 1,
        }));
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [filters?.page]);




  useEffect(() => {

    if (sharedDocs) {

      refetchDocs();
      dispatch(SetSharedDocs({ values: false }));
    }
  }, [sharedDocs])

  const MenuItems = [
    {
      name: t("common:ViewDocument"),
      icon: <ViewIcon />,
      action: (item) => {
        handleViewDocs(item?.file?.s3Object?.signedUrl);
      },
    },
    {
      name: t("common:downloadDocument"),
      icon: <DownloadIcon size={18} color={theme.dimGray} />,
      action: (item) => handleDownload(item?.file),
    },
  ];


  return (
    <SharedDocSidebarContainer isOpen={isOpen} ref={containerRef}>
      <div>
        <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary}>{t('client:sharedDocument')}</StyledText>
        <StyledText as='p' $fontSize="11px" color={theme.doveGray} fontWeight={400}>{t('common:allDocumentShared')}</StyledText>
      </div>
      <div className='d-flex flex-column gap-3'>

        {!isLoading && docsdata?.length === 0 ?

          <div className="h-100 d-flex flex-column align-items-center justify-content-center mt-5">
            <div className="d-flex align-items-center justify-content-center">
              <SharedDocZeroState />
            </div>
            <StyledText
              color={theme.gray}
              $fontWeight={600}
              $fontSize="14px"
              className="text-center mt-3"
              as={"div"}
            >
              {t('common:noDocumentSharedYet')}
            </StyledText>
            <StyledText
              color={theme.gray}
              $fontWeight={400}
              $fontSize="12px"
              className="text-center"
              as={"div"}
            >
              {t('common:allDocumentShared')}
            </StyledText>
          </div>

          : isLoading ? <SidebarDocsSkeleton /> :


            docsdata?.map((docs , index) => (
              <DocumentCard key={index}>
                <div className='d-flex flex-column gap-2 w-100' >
                  <div className='d-flex gap-2'>
                    <div>
                      {getIconByFileExtension(docs?.file?.fileName)}
                    </div>
                    <div>
                      <StyledText as='label' $fontWeight={600} $fontSize="12px" color={theme.secondary} truncatedlines={1} truncateWidth="100px">
                        {docs?.file?.fileName}
                      </StyledText>
                      <StyledText as='label' $fontWeight={400} $fontSize="9px" color={theme.stormDust}>
                        {moment(docs?.file?.createdAt).format("DD MMM YY / h:mm A")}
                      </StyledText>
                    </div>
                  </div>
                  <StyledBadge bor>
                    {docs?.file?.category?.title}
                  </StyledBadge>
                </div>
                <div>
                  <CustomDropdown
                    toggleClassName="bg-white p-0 m-0 rounded-circle"
                    menuClassName={"page"}
                    dropdownToggle={<ThreeDotsIcon rotate='down' />
                    }

                    dropdownMenu={
                      <>
                        {MenuItems?.map((menuItem, index, array) => (
                          <DropdownItem
                            key={index}
                            className={`d-flex align-items-start gap-1 cursor-pointer ${index === array.length - 1 ? "" : "pb-3"
                              }`}
                            onClick={() =>
                              menuItem.action(docs)
                            }
                          >
                            {menuItem?.icon}
                            <StyledText color={theme.dimGray} $fontSize="14px">
                              {menuItem?.name}
                            </StyledText>
                          </DropdownItem>
                        ))}
                      </>
                    }
                  />
                </div>
              </DocumentCard>
            ))}
      </div>
    </SharedDocSidebarContainer>
  );
};



export default SharedDocSidebar;
