import React from 'react';
import { DocumentCard, StyledBadge } from './CommonStyled';
import StyledText from './StyledText';
import theme from '../../Services/Utils/themes';
import styled from 'styled-components';
import FillAddIcon from '../Svgicons/FillAddIcon';
import { DefaulImage, fonts, getIconByFileExtension, handleErrorImg, USERTYPE } from '../../Services/Utils/helper';
import { DropdownItem } from 'react-bootstrap';
import CustomDropdown from '../dropdown/CustomDropDown';
import StyledToolTip from './StyledToolTip';
import StyledDot from './StyledDot';
import StyledImage from './StyledImage';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useAuth from '../../Services/Hooks/useAuth';
import { useLocation } from 'react-router-dom';

const StyledCard = ({ options, showNomineeBtn, data, addNominee, nomineeMenu, setShowDropdown }) => {

    const { t } = useTranslation();
        const loc = useLocation();     



    return (
     <DocumentCard>

                <div className='p-3'>
                    <div className='d-flex flex-column gap-2 w-100' >
                        <div className='d-flex gap-2'>
                            <div>
                                {getIconByFileExtension(data?.file?.fileName)}
                            </div>
                            <div className='d-flex flex-column'>
                                <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary} truncatedlines={1} truncateWidth="auto" wordbreak>
                                    {data?.file?.fileName}
                                </StyledText>
                                <StyledText as='label' $fontWeight={400} $fontSize="12px" color={theme.stormDust}>
                                    {t('common:sharedBy')}&nbsp;:&nbsp;{data?.messageDirection === "OUTGOING"
                                        ? "You"
                                        : `${ loc?.pathname?.includes('nominee-document') ? data?.file?.conversation?.user?.firstName : data?.user?.firstName} ${ loc?.pathname?.includes('nominee-document') ? data?.file?.conversation?.user?.lastName : data?.user?.lastName}`}
                                </StyledText>
                                <StyledText as='label' $fontWeight={400} $fontSize="12px" color={theme.stormDust}>
                                {t('common:createdAt')}&nbsp;:&nbsp;{data?.createdAt
                                        ? moment(data?.createdAt).format("MMMM DD, YYYY")
                                        : "-"}
                                </StyledText>
                            </div>
                        </div>
                        <StyledBadge>
                            {data?.file?.category?.title}
                        </StyledBadge>
                    </div>
                   {loc?.pathname?.includes('nominee-document') ? null :<hr className='my-3' />}
                    {showNomineeBtn ? <DashedButton className='cursor-pointer' onClick={() => {
                        if (data?.file?.nomineesFiles?.length === 0) {
                            addNominee()
                        }
                    }}>
                        {data?.file?.nomineesFiles?.length ? <div>
                                <div className="d-flex align-items-center gap-1">
                                    {data?.file?.nomineesFiles?.map((item, index) => (
                                        <div className="d-flex align-items-center">
                                            <CustomDropdown
                                                toggleClassName="bg-white p-0 m-0 rounded-circle"
                                                menuClassName={"page"}
                                                dropdownToggle={
                                                    <span className="d-flex align-items-center ">
                                                        <StyledToolTip
                                                            title={`${item?.user?.firstName} ${item?.user?.lastName}`}
                                                            className={`position-fixed`}
                                                            childrenClass={"ms-0"}
                                                        >
                                                            <div className=" position-relative">
                                                                <StyledDot
                                                                    $position="absolute"
                                                                    color={
                                                                        item?.user?.statusId === 1
                                                                            ? theme.pending
                                                                            : item?.user?.statusId === 2 && theme.live
                                                                    }
                                                                    $top="-1px"
                                                                    $right="1px"
                                                                    $border={"1px solid white"}
                                                                />

                                                                <StyledImage
                                                                    height={"24px"}
                                                                    width={"24px"}

                                                                    className="rounded-circle"
                                                                    onClick={() => setShowDropdown(item?.user?.id)}
                                                                >
                                                                    <img
                                                                        src={item?.user?.signedUrl || DefaulImage}
                                                                        alt="img"
                                                                        onError={handleErrorImg}
                                                                    />
                                                                </StyledImage>
                                                            </div>
                                                        </StyledToolTip>
                                                    </span>
                                                }

                                                dropdownMenu={
                                                    <>
                                                        {nomineeMenu?.map((menuItem, index, array) => (
                                                            <DropdownItem
                                                                key={index}
                                                                className={`d-flex align-items-start gap-1 cursor-pointer ${index === array.length - 1 ? "" : "pb-3"
                                                                    }`}
                                                                onClick={() =>
                                                                    menuItem.action({
                                                                        ...item,
                                                                        fileId: data.file?.id,
                                                                    })
                                                                }
                                                            >
                                                                {menuItem?.icon}
                                                                <StyledText color={theme.dimGray} $fontSize="14px">
                                                                    {menuItem?.name}
                                                                </StyledText>
                                                            </DropdownItem>
                                                        ))}
                                                    </>
                                                }
                                            />


                                        </div>
                                    ))}
                                    {data?.file?.nomineesFiles?.length === 6 ? null : (
                                        <div
                                            onClick={addNominee}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <StyledToolTip
                                                title={t("client:AssignNominee")}
                                                className={`position-fixed`}
                                                childrenClass={"ms-0"}
                                            >
                                                <FillAddIcon />
                                            </StyledToolTip>
                                        </div>
                                    )}
                                </div>
                        </div> : <div className='d-flex align-items-center justify-content-center gap-2'><FillAddIcon />
                            <StyledText $fontSize="12px" $fontWeight={500} $fontFamily={fonts.raleway} color={theme.stormDust}>{t("common:addNominee")}</StyledText></div>}
                    </DashedButton> : null}
                </div>

                {options &&
                    <div className='d-flex align-items-center justify-content-between py-3 border-top mt-2'>
                        {options?.map((item, index) => (
                            <div className={`d-flex align-items-center justify-content-center cursor-pointer w-100 ${index == 2 ? '' : 'border-end'}`} key={index} onClick={() => item?.onClick(data)}>
                                {item?.icon}
                            </div>
                        ))}
                    </div>}
            </DocumentCard>
    )
};


const DashedButton = styled.div`
min-width: 224px;
width:100%;
height: 46px;
border: 1.5px dashed ${theme.dawn};
background-color: ${theme.whiteSmoke};
border-radius: 5px;
display: flex;
align-items: center;
justify-content: center;
gap:7px;
`

export default StyledCard