const DownloadIcon = ({ size = 20, rotate = "down", transition, ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.7 7.41669C16.7 7.67502 17.925 9.21669 17.925 12.5917V12.7C17.925 16.425 16.4333 17.9167 12.7083 17.9167H7.28335C3.55835 17.9167 2.06668 16.425 2.06668 12.7V12.5917C2.06668 9.24169 3.27501 7.70002 6.22501 7.42502"
        stroke="#6C6C6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 1.66669V12.4"
        stroke="#6C6C6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7917 10.5417L10 13.3334L7.20834 10.5417"
        stroke="#6C6C6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
