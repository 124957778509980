const SharedDocZeroState = ({
  size = "20",
  rotate = "down",
  transition,
  color = "#6C6C6C",
  ...props
}) => {
  return (
    <svg width="136" height="118" viewBox="0 0 136 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M116.274 52.716C116.274 81.8303 92.7221 105.432 63.6699 105.432C34.6177 105.432 11.0662 81.8303 11.0662 52.716C11.0662 23.6018 34.6177 0 63.6699 0C92.7221 0 116.274 23.6018 116.274 52.716Z" fill="#F3F4F4" />
      <path d="M13.6964 13.179C13.6964 16.0904 11.3412 18.4506 8.43599 18.4506C5.53076 18.4506 3.17561 16.0904 3.17561 13.179C3.17561 10.2676 5.53076 7.90741 8.43599 7.90741C11.3412 7.90741 13.6964 10.2676 13.6964 13.179Z" fill="#F2F4F7" />
      <path d="M125.479 83.0278C125.479 85.2113 123.713 86.9815 121.534 86.9815C119.355 86.9815 117.589 85.2113 117.589 83.0278C117.589 80.8442 119.355 79.0741 121.534 79.0741C123.713 79.0741 125.479 80.8442 125.479 83.0278Z" fill="#F2F4F7" />
      <path d="M14.3539 90.9352C14.3539 94.5745 11.41 97.5247 7.77844 97.5247C4.14691 97.5247 1.20297 94.5745 1.20297 90.9352C1.20297 87.2959 4.14691 84.3457 7.77844 84.3457C11.41 84.3457 14.3539 87.2959 14.3539 90.9352Z" fill="#F2F4F7" />
      <path d="M136 25.0401C136 28.6794 133.056 31.6296 129.425 31.6296C125.793 31.6296 122.849 28.6794 122.849 25.0401C122.849 21.4008 125.793 18.4506 129.425 18.4506C133.056 18.4506 136 21.4008 136 25.0401Z" fill="#F2F4F7" />
      <path d="M121.534 7.24846C121.534 9.79596 119.473 11.8611 116.931 11.8611C114.389 11.8611 112.328 9.79596 112.328 7.24846C112.328 4.70096 114.389 2.6358 116.931 2.6358C119.473 2.6358 121.534 4.70096 121.534 7.24846Z" fill="#F2F4F7" />
      <path d="M28.2144 81.8507L62.7035 65.7338C64.4351 64.9246 65.1843 62.8619 64.3768 61.1266L44.009 17.3542L30.2167 12.3235L1.99828 25.5101C0.266659 26.3193 -0.482514 28.382 0.324955 30.1173L23.6169 80.1738C24.4244 81.9091 26.4827 82.6599 28.2144 81.8507Z" fill="url(#paint0_linear_192_23747)" />
      <path d="M30.2167 12.3235L44.009 17.3542L34.6032 21.7497L30.2167 12.3235Z" fill="#D0D5DD" />
      <path d="M44.7009 70.0724H82.7555C84.6661 70.0724 86.215 68.5202 86.215 66.6055V18.3081L75.8365 7.90737H44.7009C42.7903 7.90737 41.2414 9.45955 41.2414 11.3743V66.6055C41.2414 68.5202 42.7903 70.0724 44.7009 70.0724Z" fill="url(#paint1_linear_192_23747)" />
      <path d="M75.8365 7.90737L86.215 18.3081L75.8371 18.308L75.8365 7.90737Z" fill="#D0D5DD" />
      <path d="M64.2699 65.7132L98.759 81.8301C100.491 82.6393 102.549 81.8885 103.356 80.1532L123.724 36.3808L118.704 22.5591L90.4859 9.37254C88.7543 8.56335 86.696 9.31412 85.8885 11.0494L62.5965 61.1059C61.7891 62.8412 62.5382 64.904 64.2699 65.7132Z" fill="url(#paint2_linear_192_23747)" />
      <path d="M118.704 22.5591L123.724 36.3808L114.319 31.9853L118.704 22.5591Z" fill="#D0D5DD" />
      <path d="M66.7384 79.5134L63.6699 76.4383L60.6013 79.5134" fill="#E1E1E1" />
      <path d="M70.1062 81.3508C70.8544 80.942 71.4455 80.2951 71.7861 79.5123C72.1267 78.7295 72.1976 77.8553 71.9874 77.0277C71.7772 76.2001 71.2979 75.4662 70.6253 74.9418C69.9526 74.4174 69.1249 74.1325 68.2727 74.1319H67.3061C67.0739 73.2319 66.6411 72.3963 66.0403 71.688C65.4395 70.9797 64.6862 70.4171 63.8372 70.0425C62.9882 69.6679 62.0655 69.4911 61.1384 69.5254C60.2114 69.5596 59.3042 69.804 58.4849 70.2401C57.6657 70.6763 56.9558 71.2929 56.4086 72.0436C55.8614 72.7943 55.4912 73.6596 55.3257 74.5743C55.1602 75.489 55.2038 76.4295 55.4532 77.3249C55.7026 78.2203 56.1514 79.0474 56.7657 79.744" fill="#E1E1E1" />
      <path d="M66.7384 79.5134L63.6699 76.4383L60.6013 79.5134" fill="#E1E1E1" />
      <path d="M113.288 31.111H28.6541C27.5123 31.1117 26.4175 31.5665 25.6101 32.3756C24.8027 33.1847 24.3488 34.2819 24.3481 35.4262V113.244C24.3488 114.389 24.8027 115.486 25.6101 116.295C26.4175 117.104 27.5123 117.559 28.6541 117.56H113.288C114.43 117.559 115.525 117.104 116.332 116.295C117.14 115.486 117.594 114.389 117.594 113.244V35.4262C117.594 34.2818 117.14 33.1846 116.332 32.3755C115.525 31.5664 114.43 31.1116 113.288 31.111Z" fill="#C6C6C6" />
      <path d="M107.716 90.5182H34.2266C31.8486 90.5182 29.9205 88.5861 29.9205 86.203V17.4466C29.9205 15.0635 31.8486 13.1314 34.2266 13.1314H107.716C110.094 13.1314 112.022 15.0635 112.022 17.4466V86.203C112.022 88.5861 110.094 90.5182 107.716 90.5182Z" fill="white" />
      <path d="M31.2838 87.5687V18.8128C31.2838 16.4297 33.2119 14.4976 35.5899 14.4976H109.08C109.92 14.4976 110.701 14.7422 111.363 15.1589C110.601 13.9424 109.255 13.1314 107.716 13.1314H34.2266C31.8486 13.1314 29.9205 15.0635 29.9205 17.4466V86.203C29.9205 87.7452 30.7298 89.094 31.9437 89.8574C31.528 89.1934 31.2838 88.4105 31.2838 87.5687Z" fill="url(#paint3_linear_192_23747)" />
      <path d="M98.6743 27.6586H43.2693V30.8228H98.6743V27.6586ZM98.6743 39.7406H43.2693V42.9048H98.6743V39.7406ZM98.6743 51.8253H43.2693V54.9895H98.6743V51.8253ZM98.6743 63.9074H43.2693V67.072H98.6743V63.9074Z" fill="#E1E1E1" />
      <path d="M49.8717 53.6942L63.7942 67.6469H49.8717V53.6942Z" fill="url(#paint4_linear_192_23747)" />
      <path d="M117.595 68.7977V36.6952L112.023 31.111V68.7973L117.595 68.7977Z" fill="#C6C6C6" />
      <path d="M124.121 71.7015L118.118 114.289C117.818 116.418 115.999 118 113.854 118H28.0886C25.9431 118 24.125 116.418 23.8248 114.289L15.7121 56.7431C15.3462 54.1453 17.3578 51.8241 19.9759 51.8241H46.4621C48.6075 51.8241 50.4257 53.4064 50.7258 55.5351L51.789 63.0711C52.0891 65.2002 53.9073 66.7825 56.0528 66.7825H119.858C122.475 66.7825 124.487 69.1037 124.121 71.7015Z" fill="url(#paint5_linear_192_23747)" />
      <path d="M102.851 99.0044H39.0926C38.7431 99.0043 38.4057 98.8765 38.1435 98.6449C37.8813 98.4134 37.7124 98.0939 37.6683 97.7465L36.8706 91.4172C36.8453 91.2147 36.8632 91.0091 36.9232 90.8141C36.9831 90.6191 37.0838 90.4391 37.2185 90.2861C37.3532 90.133 37.5188 90.0104 37.7044 89.9264C37.8899 89.8424 38.0912 89.7989 38.2948 89.7988H103.649C103.852 89.7989 104.053 89.8424 104.239 89.9264C104.425 90.0104 104.59 90.133 104.725 90.2861C104.86 90.4391 104.96 90.6191 105.02 90.8141C105.08 91.0091 105.098 91.2147 105.073 91.4172L104.275 97.7465C104.231 98.0939 104.062 98.4134 103.8 98.6449C103.538 98.8765 103.2 99.0043 102.851 99.0044Z" fill="#E1E1E1" />
      <defs>
        <linearGradient id="paint0_linear_192_23747" x1="4.7362" y1="116.603" x2="-0.00945571" y2="8.705" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
        <linearGradient id="paint1_linear_192_23747" x1="4.7362" y1="116.603" x2="-0.00945571" y2="8.705" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
        <linearGradient id="paint2_linear_192_23747" x1="4.7362" y1="116.603" x2="-0.00945571" y2="8.705" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E4E7EC" />
          <stop offset="1" stopColor="#F9FAFB" />
        </linearGradient>
        <linearGradient id="paint3_linear_192_23747" x1="72.7674" y1="63.3925" x2="-10.8933" y2="-27.2536" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" />
        </linearGradient>
        <linearGradient id="paint4_linear_192_23747" x1="89.8544" y1="136.933" x2="-51.3474" y2="-25.8089" gradientUnits="userSpaceOnUse">
          <stop stopColor="#C2CECE" stopOpacity="0" />
          <stop offset="0.179" stopColor="#AFBCBC" stopOpacity="0.179" />
          <stop offset="1" stopColor="#5B6A6A" />
        </linearGradient>
        <linearGradient id="paint5_linear_192_23747" x1="67.9997" y1="0" x2="67.9997" y2="118" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EEF0F4" />
          <stop offset="0.927" stopColor="#F4F4F5" />
        </linearGradient>
      </defs>
    </svg>


  );
};

export default SharedDocZeroState;
