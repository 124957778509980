import React from 'react'
import StyledText from '../../../Components/StyledComponents/StyledText'
import CustomDropdown from '../../../Components/dropdown/CustomDropDown'
import { DropdownItem } from 'react-bootstrap'
import StyledRadioButton from '../../../Components/StyledComponents/StyledRadioButton'
import SortIcon from '../../../Components/Svgicons/SortIcon'
import StyledIconInput from '../../../Components/StyledComponents/StyledIconInput'
import StyledButton from '../../../Components/StyledComponents/StyledButton'
import AddIcon from '../../../Components/Svgicons/AddIcon'
import { useTranslation } from 'react-i18next'
import theme from '../../../Services/Utils/themes'
import styled from 'styled-components'
import { svenskaLs } from '../../../Services/Utils/localStorage'

const AdminMenuSection = ({ sorted, handleSortBy, handleSearch, setaddAdminModalOpen, setIsEditAdmin }) => {
    const { t } = useTranslation();
    const lang = svenskaLs.getItem("lng");

    const sort = [
        {
            id: 1,
            label: `${t('common:textNone')}`,
            value: 'createdAt',
            dir: 'DESC',
            match: "checked1"
        },
        {
            id: 2,
            label: `${t('common:TitleAtoZ')}`,
            value: 'firstName',
            dir: 'ASC',
            match: "checked2"
        },
        {
            id: 3,
            label: `${t('common:textOldestToNewest')}`,
            value: 'createdAt',
            dir: 'ASC',
            match: "checked3"
        },
        {
            id: 4,
            label: `${t('common:textNewwstToOldest')}`,
            value: 'createdAt',
            dir: 'DESC',
            match: "checked4"
        },
    ];

    return (
        <Container className='d-flex justify-content-between align-items-center' lang={lang}>
            <MobileHeaderContainer lang={lang}>
                <div className='d-flex flex-column'>
                    <StyledText $fontWeight={600} color={theme.secondary} $fontSize="14px">{t('admin:adminListing')}</StyledText>
                    <StyledText $fontWeight={400} color={theme.doveGray} $fontSize="12px">{t('admin:allAdminsManage')}</StyledText>
                </div>
                <MobileSortContainer lang={lang}>
                    <CustomDropdown
                        toggleClassName="m-0 p-0"
                        menuClassName="mt-3"
                        dropdownToggle={
                            <StyledButton icon={<SortIcon className="m-0" />} variant="snowWhite" />




                        }
                        dropdownMenu={
                            <span>
                                {sort?.map((item, index) => (
                                    <DropdownItem className={`${item.value === "nearest"
                                        ? "feature-pending"
                                        : ""
                                        } d-flex align-items-center py-2 px-2 `}
                                        key={index}
                                        onClick={() => handleSortBy(item)}
                                    >
                                        <StyledRadioButton
                                            size={14}
                                            value={
                                                sorted === item.match ||
                                                (!sorted && index === 0 && true)
                                            }
                                            label={item.label}
                                        />
                                    </DropdownItem>
                                ))}
                            </span>
                        }
                    />

                </MobileSortContainer>
            </MobileHeaderContainer>

            <SeacrhContainer className='d-flex align-items-center gap-2' lang={lang}>
                <SortContainer lang={lang}>
                    <CustomDropdown
                        toggleClassName="m-0 p-0"
                        menuClassName="mt-3"
                        dropdownToggle={
                            <StyledButton icon={<SortIcon className="m-0" />} variant="snowWhite" />




                        }
                        dropdownMenu={
                            <span>
                                {sort?.map((item, index) => (
                                    <DropdownItem className={`${item.value === "nearest"
                                        ? "feature-pending"
                                        : ""
                                        } d-flex align-items-center py-2 px-2 `}
                                        key={index}
                                        onClick={() => handleSortBy(item)}
                                    >
                                        <StyledRadioButton
                                            size={14}
                                            value={
                                                sorted === item.match ||
                                                (!sorted && index === 0 && true)
                                            }
                                            label={item.label}
                                        />
                                    </DropdownItem>
                                ))}
                            </span>
                        }
                    />

                </SortContainer>

                <ResponsiveSearch onChange={handleSearch} placeholder={t('admin:searchAdmins')} />
                <IconButtonContainer lang={lang}>

                    <StyledButton variant="primary" icon={<AddIcon color={theme.white} />} onClick={() => { setIsEditAdmin({ data: {}, state: false }); setaddAdminModalOpen(true) }} />
                </IconButtonContainer>
                <TextButtonContainer lang={lang}>
                    <StyledButton variant="primary" icon={<AddIcon color={theme.white} />} className="w-100" onClick={() => { setIsEditAdmin({ data: {}, state: false }); setaddAdminModalOpen(true) }}>
                        {t('admin:btnLblNewAdmin')}
                    </StyledButton>
                </TextButtonContainer>

            </SeacrhContainer>
        </Container>
    )
}


const TextButtonContainer = styled.div`
display:block;
 @media (max-width: ${({lang}) => (lang === 'en' ? '1077px' : '1233px')}) {
            display: none;
        
    }
`;
const IconButtonContainer = styled.div`
display:none;
 @media (max-width: ${({lang}) => (lang === 'en' ? '1077px' : '1233px')}) {
            display: block;
        
    }
`;

const SortContainer = styled.div`
display:block;
 @media (max-width: ${({lang}) => (lang === 'en' ? '960px' : '1052px')}) {
            display: none;
        
    }
`;

const MobileSortContainer = styled.div`
display:none;
 @media (max-width: ${({lang}) => (lang === 'en' ? '960px' : '1052px')}) {
            display: block;
        
    }
`;

const Container = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
 @media (max-width: ${({lang}) => (lang === 'en' ? '960px' : '1052px')}) {
            flex-direction: column;
            gap:10px;
        
    }
`;

const MobileHeaderContainer = styled.div`

 @media (max-width: ${({lang}) => (lang === 'en' ? '960px' : '1052px')}) {
            display:flex;
align-items:center;
justify-content:space-between;
width: 100%;
        
    }
`;

const SeacrhContainer = styled.div`
display:flex;
align-items:center;
gap:10px;
 @media (max-width: ${({lang}) => (lang === 'en' ? '960px' : '1052px')}) {
width: 100%;
        
    }
`;


const ResponsiveSearch = styled(StyledIconInput)`
width: 264px;
 @media (max-width: ${({lang}) => (lang === 'en' ? '960px' : '1052px')}) {
            width: 100%;
        
    }

`

export default AdminMenuSection