import { apiSlice } from "../ApiSlice/apiSlice";
import { svenskaLs } from "../Utils/localStorage";
import { USERTYPE } from "../Utils/helper";

const DASHBOARD_URL = "/api";
let userType = svenskaLs.getObject("userType");

export const DashboardApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getConversation: builder.query({
      query: (params) => ({
        url: `${DASHBOARD_URL}/conversations`,
        method: "GET",
        params: params,
      }),
    }),
    postConversation: builder.mutation({
      query: (params) => ({
        url: `${DASHBOARD_URL}/conversations`,
        method: "POST",
        body: params,
      }),
    }),

    getSharedDocs: builder.query({
      query: (params) => ({
        url: `${DASHBOARD_URL}/conversations/files`,
        method: "GET",
        params: params,
      }),
    }),



    getUserProfile: builder.query({
      query: () => ({
        url: `${DASHBOARD_URL}/user`,
        method: "GET",
      }),
    }),

    editUserProfile: builder.mutation({
      query: (data) => ({
        url: `${DASHBOARD_URL}/user`,
        method: "PUT",
        body: {
          ...data,
        },
      }),
    }),

    uploadProfile: builder.mutation({
      query: (params) => ({
        url: `${DASHBOARD_URL}/user/profile-image`,
        method: "POST",
        body: params,
      }),
    }),

    preSignedUrl: builder.mutation({
      query: (data) => ({
        url: userType === USERTYPE.ADMIN ? `backoffice/conversations/presignedUrl` : `${DASHBOARD_URL}/conversations/presignedUrl`,
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),

    userPreSignedUrl: builder.mutation({
      query: (data) => ({
        url: `${DASHBOARD_URL}/user/presignedUrl`,
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),

    createNominee: builder.mutation({
      query: (data) => ({
        url: `${DASHBOARD_URL}/nominees`,
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),

    requestDocument: builder.mutation({
      query: (data) => ({
        url: `${DASHBOARD_URL}/doc/posting/request`,
        method: "POST",
        body: {
          ...data,
        },
      }),
    }),

    getRequestedDocs: builder.query({
      query: (params) => ({
        url: `${DASHBOARD_URL}/doc/posting`,
        method: "GET",
        params: params,
      }),
    }),

    updateNominee: builder.mutation({
      query: (data) => ({
        url: `${DASHBOARD_URL}/nominees/${data.id}`,
        method: "PUT",
        body: { ...data }
      }),
    }),

    uploadFile: builder.mutation({
      query: ({ data }) => ({
        url: data.url,
        method: data.method,
        body: data.body,
      }),
    }),

    getProfileImg: builder.mutation({
      query: (token) => ({
        url: `${DASHBOARD_URL}/user/profile-image?token=${token}`,
        method: "GET",
      }),
    }),

    downloadFile: builder.mutation({
      query: (fileDescriptor) => ({
        url: `${DASHBOARD_URL}/document/download?fileDescriptor=${fileDescriptor}`,
        method: "GET",
      }),
    }),

    deleteMessage: builder.mutation({
      query: (id) => ({
        url: `${DASHBOARD_URL}/conversations/${id}`,
        method: "DELETE",
      }),
    }),

    getFileCategories: builder.mutation({
      query: () => ({
        url: `${DASHBOARD_URL}/conversations/files/categories`,
        method: "GET",
      }),
    }),

    deleteNominee: builder.mutation({
      query: ({ id, fileId }) => ({
        url: `${DASHBOARD_URL}/nominees/${id}/files/${fileId}`,
        method: "DELETE",
      }),
    }),

    deleteSharedFile: builder.mutation({
      query: (fileId) => ({
        url: `${DASHBOARD_URL}/conversations/file/${fileId}`,
        method: "DELETE",
      }),
    }),

    updateProfile: builder.mutation({
      query: ({ profileData }) => ({
        url: `${DASHBOARD_URL}/user/profileImage`,
        method: 'PUT',
        body: profileData,
      }),
    }),

    nomineesFile: builder.query({
      query: (params) => ({
        url: `${DASHBOARD_URL}/nominees/files`,
        method: "GET",
        params: params,
      }),
    }),

    getResources: builder.query({
      query: (params) => ({
        url: `${DASHBOARD_URL}/document`,
        method: "GET",
        params: params,
      }),
    }),

    getDropdownResources: builder.mutation({
      query: (params) => ({
        url: `${DASHBOARD_URL}/document`,
        method: "GET",
        params: params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.map((item) => ({
          ...item,
          label: item?.fileName,
          value: item?.id,
        }));
        return responseData || {};
      },
    }),

    getDropdownSharedDocs: builder.mutation({
      query: (params) => ({
        url: `${DASHBOARD_URL}/conversations/files`,
        method: "GET",
        params: params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.conversation?.map((item) => (          
          {
            ...item,
          label: item?.file?.fileName,
          value: item?.file?.id,
        }));
        return responseData || {};
      },
    }),


    getRequestStatus: builder.query({
      query: (params) => ({
        url: `${DASHBOARD_URL}/doc/posting/request-status`,
        method: "GET",
        params: params,
      }),
    }),

    getClientNotifications: builder.query({
      query: (params) => ({
        url: `${DASHBOARD_URL}/notifications`,
        method: "GET",
        params: params,
      }),
    }),

    readClientNotifications: builder.mutation({
      query: (id) => ({
        url: `${DASHBOARD_URL}/notifications/read/${id}`,
        method: "PUT",
        // params: params,
      }),
    }),

    markAllClientReadNotifications: builder.mutation({
      query: () => ({
        url: `${DASHBOARD_URL}/notifications/mark-read`,
        method: "PUT",
        // params: params,
      }),
    }),


    getUserAdminList: builder.query({
      query: (params) => ({
        url: `${DASHBOARD_URL}/user/admin/listing`,
        method: "GET",
        params: params,
      }),
    }),



  }),
});

export const {
  useGetConversationQuery,
  usePostConversationMutation,
  useGetSharedDocsQuery,
  useGetUserProfileQuery,
  useEditUserProfileMutation,
  useUploadProfileMutation,
  useGetProfileImgMutation,
  useDownloadFileMutation,
  useDeleteMessageMutation,
  usePreSignedUrlMutation,
  useUploadFileMutation,
  useCreateNomineeMutation,
  useUpdateNomineeMutation,
  useDeleteNomineeMutation,
  useGetFileCategoriesMutation,
  useDeleteSharedFileMutation,
  useUserPreSignedUrlMutation,
  useUpdateProfileMutation,
  useNomineesFileQuery,
  useGetResourcesQuery,
  useGetDropdownResourcesMutation,
  useRequestDocumentMutation,
  useGetRequestedDocsQuery,
  useGetRequestStatusQuery,
  useGetClientNotificationsQuery,
  useReadClientNotificationsMutation,
  useMarkAllClientReadNotificationsMutation,
  useGetDropdownSharedDocsMutation,
  useGetUserAdminListQuery
} = DashboardApiSlice;
