// import SortingIcon from '../svgIcons/SortingIcon';

import theme from "../../Services/Utils/themes";
import StyledText from "../StyledComponents/StyledText";

const TableHeader = ({ sortColumn, columns, onSort }) => {
  // const raiseSort = (path) => {
  //   if (!path) return;
  //   const sort = { ...sortColumn };
  //   if (sort.path === path)
  //     sort.order = sortColumn.order === 'ASC' ? 'DESC' : 'ASC';
  //   else {
  //     sort.path = path;
  //     sort.order = 'ASC';
  //   }
  //   onSort(sort);
  // };

  // const renderSortIcon = (column) => {
  //   if (column.path !== sortColumn.path) return null;
  //   if (column.path === 'hired') return null;
  //   if (sortColumn.order === 'ASC') return <SortingIcon />;
  //   return <SortingIcon rotate="up" />;
  // };

  return (
    <thead>
      <tr>
        {columns?.map((column) => (
          <th
            style={{ width: column.width }}
            className={`${column.alignHeader} bg-snowWhite`}
            key={Math.random()}
          // onClick={() => onSort && raiseSort(column.path)}
          >
            <StyledText $fontWeight={600} $fontSize="14px" color={theme.secondary}>
              {column.label}
            </StyledText>
            {/* {renderSortIcon(column)} */}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
