import React, { useEffect } from "react";
import useAuth from "../../Services/Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import {
  useGetAnotherDeviceDataMutation,
  useGetSameDeviceDataMutation,
} from "../../Services/loginApi/loginApiSlice";
import { svenskaLs } from "../../Services/Utils/localStorage";
import { isEmpty } from "lodash";
import AuthForm from "../../modules/authentication/AuthForm";

const Signup = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const fullRoute = new URL(window.location.href);
  const getUrl = fullRoute.origin;
  const encodedUrl = encodeURIComponent(getUrl);
  const [accessData] = useGetSameDeviceDataMutation();
  const [QrData] = useGetAnotherDeviceDataMutation();


  useEffect(() => {
    if (!isEmpty(user)) {
      navigate("/dashboard");
    } else {
      navigate("/signup");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(checkLoadingStatus, 1000);
    svenskaLs.removeItem("loadingComplete");
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const checkLoadingStatus = () => {
    const loadingComplete = svenskaLs.getItem("loadingComplete");

    if (loadingComplete === "true") {
      window.location.reload();
      localStorage.removeItem("loadingComplete");
      // navigate('/dashboard');
    }
  };

  const handleSubmit = () => {
    let isSameDevice = "1";
    accessData(isSameDevice).then(({ data }) => {
      let bankIdAppurl = `bankid:///?autostarttoken=${data?.autoStartToken}&redirect=${encodedUrl}/signup/redirect`;
      window.open(bankIdAppurl, "_self");
      svenskaLs.setObject("orderRef", data?.orderRef);
    });
  };

  return (
    <div>
      <AuthForm
        encodedUrl={encodedUrl}
        handleSubmit={handleSubmit}
        QrData={QrData}
        path={"/login"}
      />
    </div>
  );
};

export default Signup;