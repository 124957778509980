import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./Redux/store";
import { ThemeProvider } from "styled-components";
import theme from "./Services/Utils/themes";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import { svenskaLs } from "./Services/Utils/localStorage";

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: svenskaLs.getItem("lng") || 'swe',
    fallbackLng: 'en',
    // ns: [ 'client', 'admin'],
    // defaultNS: 'common',
    resources: {
      en: {
        common: require('./Locales/en/common.json'),
        client: require('./Locales/en/client.json'),
        admin: require('./Locales/en/admin.json'),
      },
      swe: {
        common: require('./Locales/swe/common.json'),
        client: require('./Locales/swe/client.json'),
        admin: require('./Locales/swe/admin.json'),
      },
      // Add more language resources here
    },
    react: {
      useSuspense: false, // Set to true if you want to use Suspense for loading translations
    },
  });

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme || {}}>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
