import React from "react";
import { styled } from "styled-components";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import StyledLanguageSwitch from "../../Components/StyledComponents/StyledLanguageSwitch";
import DrawerIcon from "../../Components/Svgicons/DrawerIcon";
import svenskaLogo from "../../Assets/Images/svenskaLogo.png"
import DocumentDrawerIcon from "../../Components/Svgicons/DocumentDrawerIcon";
import { useLocation } from "react-router-dom";
import NotificationDropDown from "../../Components/StyledComponents/NotificationDropdown";
import { useTranslation } from "react-i18next";
import { useGetNotificationsQuery } from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import { svenskaLs } from "../../Services/Utils/localStorage";
import StyledImage from "../../Components/StyledComponents/StyledImage";
import { DefaulImage, handleErrorImg } from "../../Services/Utils/helper";

const AdminHeader = ({ adminDetails, toggleSidebar, docToggleSidebar }) => {
  const location = useLocation();
  let userData = svenskaLs.getObject("userData");
  
  const { t } = useTranslation();
  const initialFilters = {
    perPage: 5,
    page: 1,
    query: "",
    sortedColumn: { path: "createdAt", order: "DESC" },
  };
  const { data: notifications, refetch: refetchNotification } = useGetNotificationsQuery({
    sortDir: initialFilters.sortedColumn.order,
    sort: initialFilters.sortedColumn.path
  });

  return (
    <StyledContainer>
      <div className="d-md-none cursor-pointer" onClick={toggleSidebar}>
        <DrawerIcon />
      </div>
      <div className="d-md-none">
        <img src={svenskaLogo} alt="svenskaLogo" width={150} />
      </div>
      <div className="d-none d-md-block">
        <div className="d-flex align-items-center gap-2">
          {location?.pathname?.includes('admin-chat') ? <div className="d-flex align-items-center gap-2">
            <StyledImage className="rounded-pill">
              <img src={userData?.signedUrl || DefaulImage.image} alt="svenskaLogo" style={{objectFit: "cover"}} onError={handleErrorImg}/>
            </StyledImage>
            <StyledText $fontWeight={600} $fontSize="14px" color={theme.secondary}>{`${userData?.firstName || ""} ${userData?.lastName || ""
              }`}</StyledText><StyledText $fontWeight={500} $fontSize="14px" color={theme.secondary}>({userData?.personalNumber})</StyledText>
          </div> : <StyledText $fontWeight={400} $fontSize="14px" color={theme.secondary}>
            <b>{t('admin:welcomeBack')}</b>&nbsp;{`${adminDetails?.data?.firstName || ""} ${adminDetails?.data?.lastName || ""
              }`}
          </StyledText>}
        </div>
      </div>
      {/* <StyledLanguageSwitch /> */}
      <div className="d-flex align-items-center justify-content-center gap-4">
        <NotificationDropDown toggleClassName="px-0 bg-white me-2 me-md-3" notifications={notifications} refetch={refetchNotification} />

        <StyledLanguageSwitch />
        <SharedDocDrawer onClick={docToggleSidebar} route={location?.pathname.includes('admin-chat')} className={`cursor-pointer`}>
          <DocumentDrawerIcon size={35} />
        </SharedDocDrawer>
      </div>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
 display: flex;
 justify-content: space-between;
 align-items: center;
 height: 52px;
`;

const SharedDocDrawer = styled.div`
display:none;
@media (max-width: 1183px){
display:${({ route }) => route ? 'block' : 'none'};
}

@media (max-width: 768px){
display:none;
}
`;




export default AdminHeader;
