import React, { useEffect } from "react";
import { styled } from "styled-components";
import StyledText from "../Components/StyledComponents/StyledText";
import { svenskaLs } from "../Services/Utils/localStorage";
import { useDispatch } from "react-redux";
import { setAdminCredentials } from "../Services/Auth/adminAuthSlice";
import { useGetAdminOrderRefMutation } from "../Services/Admin Api/loginApi/adminloginApiSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const AdminRedirectPage = () => {
  let orderRef = svenskaLs.getObject("orderRef");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [adminOrderRefCollect] = useGetAdminOrderRefMutation();
  useEffect(() => {
    if (svenskaLs.getItem('pageReload') === null) {
      svenskaLs.setItem('pageReload', 1);
    adminOrderRefCollect(orderRef).then((resp) => {
      if (resp?.data?.token) {
        dispatch(
          setAdminCredentials({
            token: { accessToken: resp?.data?.token },
            account: resp?.data?.userInfo,
            userType:"admin"
          })
        );
        svenskaLs.removeItem("orderRef");
        svenskaLs.setItem("loadingComplete", "true");
        svenskaLs.removeItem('pageReload');
        navigate("/user-workplace");
        // window.location.reload();
        // window.close();
      } else {
        toast.error(resp?.error?.data?.message);
        // window.close();
      }
    });
  }
  else {
    navigate("/admin-login");
    svenskaLs.removeItem('pageReload');
    svenskaLs.removeItem("orderRef");
  }
    // eslint-disable-next-line
  }, []);

  return (
    <StyledContainer className="rounded px-5 pt-4 pb-3 d-flex flex-column align-items-center gap-3">
      <div className="spinner-border text-primary loader" role="status" />
      <StyledText>Redirecting...</StyledText>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 55%;
  transform: translate(-50%, -50%);
  .loader {
    height: 50px;
    width: 50px;
  }
`;

export default AdminRedirectPage;
