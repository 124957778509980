import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Sidebar from '../Sidebar/Sidebar';
import SharedDocSidebar from '../SharedDocumentMenu/SharedDocSidebar';
import { useLocation } from 'react-router-dom';
import theme from '../../Services/Utils/themes';
import Header from '../Header/Header';
import { HeaderContainer } from '../../CommonComponents/ReusableComponents';
import { Overlay } from '../../CommonComponents/SidebarStyling';
import { DocsOverlay } from '../../CommonComponents/SharedDocSidebarStyling';
import { useGetUserProfileQuery } from '../../Services/DashboardApi/dashboardApiSlice';

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
  padding: 10px;
  background-color: ${theme.aliceBlue};
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0 0 10px;
  background-color: ${theme.white};
  border-radius: 8px;
width: calc(100% - 500px );
  @media (max-width: 768px) {
  margin:0}

`;



const CommonOutlet = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDocSidebarOpen, setIsDocSidebarOpen] = useState(false);

    const {
        data: getUserDetail,
    } = useGetUserProfileQuery();

    const location = useLocation();
    const route = location?.pathname?.includes('dashboard');
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setIsSidebarOpen(false);
        } else {
            setIsSidebarOpen(true);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <AppContainer>
            <Sidebar isOpen={isSidebarOpen} />
            <Overlay isOpen={isSidebarOpen} onClick={() => setIsSidebarOpen(false)} />


            <MainContent>
                <HeaderContainer>
                    <Header toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} docToggleSidebar={() => setIsDocSidebarOpen(!isDocSidebarOpen)} user={getUserDetail}/>
                </HeaderContainer>
                <div className='overflow-auto'>
                    {children}
                </div>
            </MainContent>
            {route ? <>
                <SharedDocSidebar isOpen={isDocSidebarOpen} />  <DocsOverlay isOpen={isDocSidebarOpen} onClick={() => setIsDocSidebarOpen(false)} />
            </> : null}

        </AppContainer>
    );
};

export default CommonOutlet;
