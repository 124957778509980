import React from "react";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import { Row } from "react-bootstrap";
import * as Yup from "yup";
import FormikInputField from "../../Components/Formik/FormikInputField";
import { Form, Formik } from "formik";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import FileIcon2 from "../../Components/Svgicons/FileIcon2";
import { useEditAdminProfileMutation } from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import { useTranslation } from "react-i18next";

function AdminEditProfile({
  setEditProfile,
  user,
  refetchData,
  setActiveUploadIcon,
}) {
  const { t } = useTranslation();
  const [editAdminProfile, { isLoading: ProfileLoadling }] =
    useEditAdminProfileMutation();
  const handleSubmit = (values) => {
    delete values.personalNumber;

    editAdminProfile(values).then((resp) => {
      setEditProfile(false);
      refetchData();
      setActiveUploadIcon(false);
    });
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    personalNumber: Yup.string().required("Personal Number is required"),
  });

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          personalNumber: "",
          phoneNumber: "",
          ...user,
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <div className="border rounded-3 p-4 mt-4">
              <StyledText
                $fontSize="18px"
                $fontWeight={600}
                color={theme.secondary}
              >
                {t('common:textPersonalInfo')}
              </StyledText>
              <Row className="mt-4">
                <div className="col-12 col-md-6">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('common:textFirstName')}
                    </StyledText>
                    <FormikInputField name="firstName" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4 mt-md-0">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('common:textLastName')}
                    </StyledText>
                    <FormikInputField name="lastName" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('common:textEmailAddress')}
                    </StyledText>
                    <FormikInputField name="email" />
                  </div>
                </div>

                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('common:textPhoneNumber')}
                    </StyledText>
                    <FormikInputField name="phoneNumber" />
                  </div>
                </div>

                <div className="col-12 col-md-6 mt-4 feature-pending">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      {t('common:textPersonalNo')}
                    </StyledText>
                    <FormikInputField name="personalNumber" />
                  </div>
                </div>

                {/* <div className="col-12 col-md-6 mt-4 feature-pending">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      Registration date
                    </StyledText>
                    <FormikInputField name="registeredAt" />
                  </div>
                </div> */}

                {/* <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      Country
                    </StyledText>
                    <FormikInputField name="country" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      City/State
                    </StyledText>
                    <FormikInputField name="city" />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-4">
                  <div className="d-flex flex-column">
                    <StyledText
                      $fontSize="14px"
                      $fontWeight={500}
                      color={theme.gray}
                    >
                      Postal code
                    </StyledText>
                    <FormikInputField name="postCode" />
                  </div>
                </div> */}
              </Row>
            </div>
            <StyledButton
              icon={<FileIcon2 />}
              className="text-white rounded-2 mt-3"
              type="submit"
              disabled={ProfileLoadling}
              loading={ProfileLoadling}
            >
              {t('common:textBtnSaveProfile')}
            </StyledButton>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default AdminEditProfile;
