import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isDocsSidemenu: false,
};

const SidemenuDrawer = createSlice({
  name: "activeDocsDrawer",
  initialState,
  reducers: {
    SetIsSidemenu: (state, { payload: { values } }) => {
      state.isDocsSidemenu = values;
    },

  },
});

export const { SetIsSidemenu } = SidemenuDrawer.actions;

export default SidemenuDrawer.reducer;
