import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import useAdminAuth from "../Services/Hooks/useAdminAuth";
import { svenskaLs } from "../Services/Utils/localStorage";
import AdminOutlet from "../Admin/AdminLayout/AdminOutlet";

function AdminProtectedRoute() {
  const { admin } = useAdminAuth();
  let userType = svenskaLs.getObject("userType");

  if (admin && userType === "admin") {
    return (
      <AdminOutlet>
        <Outlet />
      </AdminOutlet>
    );
  }

  return <Navigate to="/admin-login" />;
}

export default AdminProtectedRoute;
