// import { omit } from "lodash";
import { apiSlice } from "../../ApiSlice/apiSlice";

const ADMIN_URL = "/backoffice";

export const AdminProfileApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAdminProfile: builder.query({
        query: () => ({
          url: `${ADMIN_URL}/admin/self`,
          method: "GET",
        }),
      }),

  }),
});

export const {
    useGetAdminProfileQuery,
} = AdminProfileApiSlice;
