import React, { useEffect, useState } from 'react';
import { svenskaLs } from './Services/Utils/localStorage';

const SessionTimeoutWrapper = ({ children }) => {
  const [isSessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    let sessionTimer;

    const resetTimer = () => {
      clearTimeout(sessionTimer);
      sessionTimer = setTimeout(() => setSessionExpired(true),  60 * 60 * 1000); // 1 hour in milliseconds
    };

    const handleUserActivity = () => {
      resetTimer();
      // Perform any other actions related to user activity if needed.
    };

    // Set up event listeners for user activity (mousemove and keydown in this example).
    document.addEventListener('mousemove', handleUserActivity);
    document.addEventListener('keydown', handleUserActivity);

    resetTimer(); // Start the timer initially.

    return () => {
      // Clean up event listeners when the component is unmounted.
      document.removeEventListener('mousemove', handleUserActivity);
      document.removeEventListener('keydown', handleUserActivity);
      clearTimeout(sessionTimer);
    };
  }, []);

  useEffect(() => {
    const userType = svenskaLs.getItem("userType")?.replace(/"/g, '');
    if (isSessionExpired) {

    if (userType === 'admin') {
        window.location.href = '/admin-login';
      } else {
        window.location.href = '/login';
      }
      localStorage.clear();
    }
  }, [isSessionExpired]);

  return <>{children}</>;
};

export default SessionTimeoutWrapper;