import React from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
// import PortletBody from '../../app/components/portlet/PortletBody';

const StyledTableSkeleton = ({
  cols,
  show,
  customeColWidth = 100,
  isFilter,
  tableClassName,
}) => {
  const location = useLocation();
  const { t: client } = useTranslation("client");
  return (
    <>
      
      <div className={`rounded ${tableClassName}`}>
        <table className="table p-0 mb-0">
          <thead>
            <tr>
              {cols?.map((col, ind) =>
                col?.label !== "Actions" ? (
                  <th
                    className="p-3 bg-body text-left "
                    key={`skeleteon-header-${ind}`}
                  >
                    {col?.label}
                  </th>
                ) : (
                  <th
                    className="p-3 bg-body text-center "
                    key={`skeleteon-header-${ind}`}
                  >
                    {col?.label}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody>
            {Array.from(new Array(4)).map((ind, inde) => (
              <tr key={`skleton-main-${inde}`}>
                {cols?.map((col, index) => (
                  <StyledTableBody
                    className={
                      col?.label === client("shareDocsTblTitle4")
                        ? "actions"
                        : ""
                    }
                    key={`skelton_temp-${index}`}
                  >
                    {col?.label !== client("shareDocsTblTitle4") ? (
                      <StyledTableWrap>
                        <Skeleton
                          height={20}
                          width={
                            location?.pathname?.includes("dashboard")
                              ? 100
                              : customeColWidth
                          }
                          key={col?.label}
                        />
                      </StyledTableWrap>
                    ) : location?.pathname === "/dashboard" ? (
                      <StyledTableWrap>
                        <td>
                          <Skeleton
                            height={50}
                            width={50}
                            key={col?.label}
                            className="me-2"
                          />
                        </td>
                      </StyledTableWrap>
                    ) : (
                      <StyledTableWrap>
                        <td>
                          <Skeleton
                            height={50}
                            width={50}
                            key={col?.label}
                            className="me-2"
                          />
                        </td>
                        <td>
                          <Skeleton
                            height={50}
                            width={50}
                            key={col?.label}
                            className="me-2"
                          />
                        </td>
                        <td>
                          <Skeleton height={50} width={50} key={col?.label} />
                        </td>
                      </StyledTableWrap>
                    )}
                  </StyledTableBody>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between  mt-3">
        <div className="d-flex">
          <Skeleton height={30} className="me-2" width={100} />
          <Skeleton height={30} className="me-2" width={30} />
        </div>
        <div className="d-flex">
          {Array.from(new Array(4)).map((ind, index) => (
            <Skeleton
              height={30}
              className="me-2"
              width={30}
              key={`skl-${index}`}
            />
          ))}
        </div>
      </div>
      {/* </PortletBody> */}
    </>
  );
};

const StyledTableBody = styled.td`
  padding: 5px;
  vertical-align: middle;
  &.actions {
    text-align: -webkit-center;
  }
`;

const StyledTableWrap = styled.div`
  padding: 5px;
  vertical-align: middle;
  &.actions {
    text-align: -webkit-center;
  }
`;

export default StyledTableSkeleton;
