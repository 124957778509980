import React from 'react';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const Table = ({ columns, data, className }) => {
  return (
    <div className={`rounded ${className} w-100`}>
      <table className="table mb-0 table-responsive w-100">
        <TableHeader
          columns={columns}
          // sortColumn={sortColumn}
          // onSort={onSort && onSort}
        />
        <TableBody
          columns={columns}
          data={data}
          // ref={tableRef}
          // loading={loading}
        />
      </table>
    </div>
  );
};

export default Table;
