import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { svenskaLs } from "../Utils/localStorage";
import { isEmpty } from "lodash";
import useAdminAuth from "../Hooks/useAdminAuth";
import AdminLoginContainer from "../../Admin/AdminLoginForm/AdminLoginContainer";
import {
  useGetAdminAnotherDeviceDataMutation,
  useGetAdminSameDeviceDataMutation,
} from "../Admin Api/loginApi/adminloginApiSlice";

export const AdminLogin = () => {
  const { admin } = useAdminAuth();
  const navigate = useNavigate();
  const fullRoute = new URL(window.location.href);
  const getUrl = fullRoute.origin;
  const encodedUrl = encodeURIComponent(getUrl);
  const [accessAdminData] = useGetAdminSameDeviceDataMutation();
  const [AdminQrData] = useGetAdminAnotherDeviceDataMutation();

  useEffect(() => {
    if (!isEmpty(admin)) {
      navigate("/user-workplace");
    } else {
      navigate("/admin-login");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const interval = setInterval(checkLoadingStatus, 1000);
    svenskaLs.removeItem("loadingComplete");
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  const checkLoadingStatus = () => {
    const loadingComplete = svenskaLs.getItem("loadingComplete");

    if (loadingComplete === "true") {
      window.location.reload();
      localStorage.removeItem("loadingComplete");
    }
  };

  const handleSubmit = () => {
    let isSameDevice = "1";
    accessAdminData(isSameDevice).then(({ data }) => {
      let bankIdAppurl = `bankid:///?autostarttoken=${data?.autoStartToken}&redirect=${encodedUrl}/adminRedirect`;
      window.open(bankIdAppurl, "_self");
      svenskaLs.setObject("orderRef", data?.orderRef);
    });
  };

  return (
    <div>
      <AdminLoginContainer
        encodedUrl={encodedUrl}
        handleSubmit={handleSubmit}
        QrData={AdminQrData}
      />
    </div>
  );
};
