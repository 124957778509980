const UserEditIcon = ({
  size = 18,
  rotate = "down",
  color = "#585558",
  transition,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 9C11.0711 9 12.75 7.32107 12.75 5.25C12.75 3.17893 11.0711 1.5 9 1.5C6.92893 1.5 5.25 3.17893 5.25 5.25C5.25 7.32107 6.92893 9 9 9Z"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4075 11.805L11.7525 14.46C11.6475 14.565 11.55 14.76 11.5275 14.9025L11.385 15.915C11.3325 16.2825 11.5875 16.5375 11.955 16.485L12.9675 16.3425C13.11 16.32 13.3125 16.2225 13.41 16.1175L16.065 13.4625C16.5225 13.005 16.74 12.4725 16.065 11.7975C15.3975 11.13 14.865 11.3475 14.4075 11.805Z"
        stroke={color}
        strokeWidth="1.4"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0251 12.1875C14.2501 12.9975 14.8801 13.6275 15.6901 13.8525"
        stroke={color}
        strokeWidth="1.4"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.55737 16.5C2.55737 13.5975 5.44489 11.25 8.99989 11.25C9.77989 11.25 10.5299 11.3625 11.2274 11.5725"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserEditIcon;
