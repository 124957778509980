const ResourcesZeroState = ({
  size = "20",
  rotate = "down",
  transition,
  color = "#6C6C6C",
  ...props
}) => {
  return (
    <svg width="180" height="212" viewBox="0 0 180 212" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M90 198.6C139.706 198.6 180 158.306 180 108.6C180 58.8945 139.706 18.6001 90 18.6001C40.2944 18.6001 0 58.8945 0 108.6C0 158.306 40.2944 198.6 90 198.6Z" fill="#F4F4F5"/>
<g filter="url(#filter0_d_614_7960)">
<path d="M141.599 70.1997H38.3994C35.0857 70.1997 32.3994 72.886 32.3994 76.1997V202.2C32.3994 205.513 35.0857 208.2 38.3994 208.2H141.599C144.913 208.2 147.599 205.513 147.599 202.2V76.1997C147.599 72.886 144.913 70.1997 141.599 70.1997Z" fill="white"/>
</g>
<path d="M77.9982 88.1997H46.7982C44.81 88.1997 43.1982 89.8115 43.1982 91.7997C43.1982 93.7879 44.81 95.3997 46.7982 95.3997H77.9982C79.9865 95.3997 81.5982 93.7879 81.5982 91.7997C81.5982 89.8115 79.9865 88.1997 77.9982 88.1997Z" fill="#E1E1E1"/>
<path d="M99.5982 103.8H46.7982C44.81 103.8 43.1982 105.412 43.1982 107.4C43.1982 109.389 44.81 111 46.7982 111H99.5982C101.586 111 103.198 109.389 103.198 107.4C103.198 105.412 101.586 103.8 99.5982 103.8Z" fill="#EFEFEF"/>
<path d="M77.9982 120.6H46.7982C44.81 120.6 43.1982 122.212 43.1982 124.2C43.1982 126.188 44.81 127.8 46.7982 127.8H77.9982C79.9865 127.8 81.5982 126.188 81.5982 124.2C81.5982 122.212 79.9865 120.6 77.9982 120.6Z" fill="#E1E1E1"/>
<path d="M99.5982 136.2H46.7982C44.81 136.2 43.1982 137.812 43.1982 139.8C43.1982 141.788 44.81 143.4 46.7982 143.4H99.5982C101.586 143.4 103.198 141.788 103.198 139.8C103.198 137.812 101.586 136.2 99.5982 136.2Z" fill="#EFEFEF"/>
<path d="M77.9982 153H46.7982C44.81 153 43.1982 154.611 43.1982 156.6C43.1982 158.588 44.81 160.2 46.7982 160.2H77.9982C79.9865 160.2 81.5982 158.588 81.5982 156.6C81.5982 154.611 79.9865 153 77.9982 153Z" fill="#E1E1E1"/>
<path d="M99.5982 168.6H46.7982C44.81 168.6 43.1982 170.212 43.1982 172.2C43.1982 174.188 44.81 175.8 46.7982 175.8H99.5982C101.586 175.8 103.198 174.188 103.198 172.2C103.198 170.212 101.586 168.6 99.5982 168.6Z" fill="#EFEFEF"/>
<g filter="url(#filter1_d_614_7960)">
<path d="M141.599 9H38.3994C35.0857 9 32.3994 11.6863 32.3994 15V51C32.3994 54.3137 35.0857 57 38.3994 57H141.599C144.913 57 147.599 54.3137 147.599 51V15C147.599 11.6863 144.913 9 141.599 9Z" fill="#C6C6C6"/>
</g>
<path d="M77.9982 22.1997H46.7982C44.81 22.1997 43.1982 23.8115 43.1982 25.7997C43.1982 27.7879 44.81 29.3997 46.7982 29.3997H77.9982C79.9865 29.3997 81.5982 27.7879 81.5982 25.7997C81.5982 23.8115 79.9865 22.1997 77.9982 22.1997Z" fill="white"/>
<path d="M99.5982 37.7998H46.7982C44.81 37.7998 43.1982 39.4116 43.1982 41.3998C43.1982 43.388 44.81 44.9998 46.7982 44.9998H99.5982C101.586 44.9998 103.198 43.388 103.198 41.3998C103.198 39.4116 101.586 37.7998 99.5982 37.7998Z" fill="white"/>
<defs>
<filter id="filter0_d_614_7960" x="26.3994" y="61.1997" width="127.2" height="150" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="-3"/>
<feGaussianBlur stdDeviation="3"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_614_7960"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_614_7960" result="shape"/>
</filter>
<filter id="filter1_d_614_7960" x="26.3994" y="0" width="127.2" height="60" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="-3"/>
<feGaussianBlur stdDeviation="3"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_614_7960"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_614_7960" result="shape"/>
</filter>
</defs>
</svg>



  );
};

export default ResourcesZeroState;
