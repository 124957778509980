import { createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import { USERTYPE } from "../Utils/helper";
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}`,
    prepareHeaders: (headers, { getState }) => {
      const { userType: admin } = getState().adminAuth;
      const { userType } = getState().auth;

      const { token } =
        admin === "admin"
          ? getState().adminAuth
          : (userType === USERTYPE?.CLIENT || userType === USERTYPE?.NOMINEE) &&
            getState().auth;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  // tagTypes: ['Jobs'],
  endpoints: (builder) => ({}),
});

