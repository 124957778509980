import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { DocumentCard, SharedDocSidebarContainer } from '../../../CommonComponents/SharedDocSidebarStyling';
import StyledText from '../../../Components/StyledComponents/StyledText';
import theme from '../../../Services/Utils/themes';
import { StyledBadge } from '../../../Components/StyledComponents/CommonStyled';
import ThreeDotsIcon from '../../../Components/Svgicons/ThreeDotsIcon';
import { DefaulImage, getIconByFileExtension } from '../../../Services/Utils/helper';
import CustomDropdown from '../../../Components/dropdown/CustomDropDown';
import { DropdownItem } from 'react-bootstrap';
import StyledImage from '../../../Components/StyledComponents/StyledImage';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash';
import SharedDocZeroState from '../../../Components/Svgicons/SharedDocZeroState';
import SidebarDocsSkeleton from '../../../Components/Skeletons/SidebarDocsSkeleton';

const AdminSharedDocSidebar = ({ isOpen, data, setNomineeDetails, MenuItems,setFilters,filters ,paginations , isLoading}) => {
  const {t} = useTranslation();
  const containerRef = useRef(null);
  const [docsdata, setDocsData] = useState([]);


  const maxPage = Math.ceil(paginations?.total / paginations?.perPage);

  useEffect(() => {
    // if (data) {

      let response = data;

      setDocsData((prev) => uniqBy(response?.concat(prev), "id"));
    // }
  }, [data])

  const handleScroll = () => {
    const container = containerRef.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight && paginations?.page !== maxPage) {
        setFilters((prevFilters) => ({
          ...prevFilters,
          page: prevFilters.page + 1,
        }));
      }
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [filters?.page]);

  return (
    <SharedDocSidebarContainer isOpen={isOpen} ref={containerRef} height={71} width={'300px'}>
      <div>
        <StyledText as='label' $fontWeight={600} $fontSize="14px" color={theme.secondary}>{t("client:sharedDocument")}</StyledText>
        <StyledText as='p' $fontSize="11px" color={theme.doveGray} fontWeight={400}>{t("common:allDocumentShared")}</StyledText>
      </div>
      <div className='d-flex flex-column gap-3'>
        {
          !isLoading && (docsdata?.length === 0 || docsdata?.length === undefined) ?

          <div className="h-100 d-flex flex-column align-items-center justify-content-center mt-5">
            <div className="d-flex align-items-center justify-content-center">
              <SharedDocZeroState />
            </div>
            <StyledText
              color={theme.gray}
              $fontWeight={600}
              $fontSize="14px"
              className="text-center mt-3"
              as={"div"}
            >
              {t('common:noDocumentSharedYet')}
            </StyledText>
            <StyledText
              color={theme.gray}
              $fontWeight={400}
              $fontSize="12px"
              className="text-center"
              as={"div"}
            >
              {t('common:allDocumentShared')}
            </StyledText>
          </div>

          : isLoading ? <SidebarDocsSkeleton /> :
        
        docsdata?.map((docs) => (
          <DocumentCard>
            <div className='d-flex flex-column gap-2 w-100' >

              <div className='d-flex align-items-center justify-content-between'>

                <div className='d-flex gap-2'>
                  <div>
                    {getIconByFileExtension(docs?.file?.fileName)}
                  </div>
                  <div>
                    <StyledText as='label' $fontWeight={600} $fontSize="12px" color={theme.secondary} truncatedlines={1} truncateWidth="100px">
                      {docs?.file?.fileName}
                    </StyledText>
                    <StyledText as='label' $fontWeight={400} $fontSize="9px" color={theme.stormDust}>
                      {moment(docs?.file?.createdAt).format("DD MMM YY / h:mm A")}
                    </StyledText>
                  </div>
                </div>

                <CustomDropdown
                  toggleClassName="m-0 p-0 bg-white"
                  menuClassName="mt-3"
                  dropdownToggle={
                    <ThreeDotsIcon rotate='down' />}
                  dropdownMenu={
                    <span>
                      {MenuItems?.map((menuItem, index, array) => (
                        <DropdownItem key={index}
                          className={`d-flex align-items-center gap-2 cursor-pointer ${index === array.length - 1 ? "" : "pb-3"
                            }`}
                          onClick={() => menuItem.action({ ...docs })}
                        >
                          <div>
                            {menuItem?.icon}
                          </div>
                          <StyledText
                            color={theme.dimGray}
                            $fontSize="14px"
                          >
                            {menuItem?.name}
                          </StyledText>
                        </DropdownItem>
                      ))}
                    </span>
                  }
                />
              </div>
              <div className='d-flex align-items-center justify-content-between '>
                <StyledBadge className='text-truncate'>
                  {docs?.file?.category?.title}
                </StyledBadge>
                <div className='d-flex gap-1'>
                {docs?.file?.nomineesFiles?.length > 0 ? (
                  docs?.file?.nomineesFiles?.map((item, index) => (
                    <div
                      className="d-flex align-items-center cursor-pointer"
                      onClick={() => setNomineeDetails(docs?.file)}
                    >
                      <StyledImage className='rounded-pill' height="18px" width="18px">
                        <img
                          src={item?.user?.signedUrl || DefaulImage.image}
                          alt='nominee'

                        />
                      </StyledImage>
                    </div>
                  ))

                ) : null}
                </div>
              </div>
            </div>

          </DocumentCard>
        ))}
      </div>
    </SharedDocSidebarContainer>
  );
};



export default AdminSharedDocSidebar;
