import ReactPaginate from 'react-paginate';
import './_pagination.scss';
import CustomDropdown from '../dropdown/CustomDropDown';
import DropDownIcon from '../Svgicons/DropDownIcon';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import StyledText from '../StyledComponents/StyledText';
import theme from '../../Services/Utils/themes';
import { useTranslation } from 'react-i18next';

const PaginationPerPage = ({
  perPage,
  onPerPageChange,
  pageNo,
  onPageChange,
  totalCount,
}) => {
  const { t } = useTranslation('common');
  const pageSizes = [5, 10, 20, 50];
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center me-lg-3 pb-md-0">
        <StyledText color={theme.stormDustGray} $fontSize="14px" $fontWeight={500} className="d-none d-md-block">
          {t("perPageItemPagination")} :
          {/* : {pageNo * perPage - perPage + 1} -{' '}
          {pageNo * perPage > totalCount ? totalCount : pageNo * perPage} of
          <span className="ms-1 me-2">{totalCount}</span> */}
        </StyledText>
        <CustomDropdown
          dropdownToggle={
            <span className="d-flex align-items-center justify-content-center border  py-1 rounded px-3 gap-2">
              <StyledText color={theme.stormDustGray} $fontSize="14px" $fontWeight={500}>{perPage}</StyledText>
              <DropDownIcon rotate="down" size={'10px'} className="mt-1"/>
            </span>
          }
          toggleClassName="bg-body p-0 ms-2"
          menuClassName={'page'}
          dropdownMenu={
            <>
              {pageSizes?.map((p) => (
                <DropdownItem key={p} onClick={(p) => onPerPageChange(p)}>
                  {p}
                </DropdownItem>
              ))}
            </>
          }
        />
      </div>

     <div>
     <ReactPaginate
        pageCount={totalCount / perPage}
        onPageChange={onPageChange}
        forcePage={pageNo - 1}
        marginPagesDisplayed={1}
        containerClassName="pagination__container"
        pageLinkClassName="pagination__page"
        previousClassName="pagination__page-prev-next border"
        nextClassName="pagination__page-prev-next border"
        previousLabel={<DropDownIcon rotate="left" size={'12px'} />}
        nextLabel={<DropDownIcon rotate="right" size={'12px'} />}
        activeLinkClassName="pagination__page-active px-3 rounded"
        
      />
     </div>
    </div>
  );
};

export default PaginationPerPage;
