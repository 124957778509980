import React, { useRef, useState } from "react";
import BackArrowIcon from "../../Components/Svgicons/BackArrowIcon";
import StyledText from "../../Components/StyledComponents/StyledText";
import theme from "../../Services/Utils/themes";
import StyledImage from "../../Components/StyledComponents/StyledImage";
import StyledButton from "../../Components/StyledComponents/StyledButton";
import UploadIcon from "../../Components/Svgicons/UploadIcon";
import { useNavigate } from "react-router-dom";
import { DefaulImage, handleErrorImg, useWindowSize } from "../../Services/Utils/helper";
import { useGetAdminProfileQuery } from "../../Services/Admin Api/adminDashboardApi/adminProfileApiSlice";
import { useDispatch } from "react-redux";
import { SetActiveTab } from "../../Services/TabSlice/TabSlice";
import AdminEditProfile from "./AdminEditProfile";
import AdminDetails from "./AdminDetails";
import { useAdminPreSignedUrlMutation, useUpdateAdminProfileMutation } from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import { useTranslation } from "react-i18next";
import StyledProfileSkeleton from "../../Components/StyledComponents/StyledProfileSkeleton";
import { toast } from "react-toastify";
import { svenskaLs } from "../../Services/Utils/localStorage";
import styled from "styled-components";
import { CommonStyledContainer } from "../../Components/StyledComponents/CommonStyled";

const AdminProfile = () => {
  const { t } = useTranslation();
  const lang = svenskaLs.getItem("lng");
  const isSmallScreen = useWindowSize();
  const dispatch = useDispatch();
  const [getAdminPreSignedUrl] = useAdminPreSignedUrlMutation();
  const {
    data: getAdminProfile,
    refetch: refetchData,
    isLoading,
  } = useGetAdminProfileQuery();
  const [updateAdminProfile] = useUpdateAdminProfileMutation();
  const [activeUploadIcon, setActiveUploadIcon] = useState(false);
  const fileInputRef = useRef(null);
  const [editProfile, setEditProfile] = useState(false);
  const navigate = useNavigate();
  const handleButtonClick = (evt) => {
    fileInputRef.current.click();
  };



  // const handleAttachment = async (e) => {
  //   const file = e?.target?.files[0];
  //   if (!file) return;
  //   const {
  //     data: { response: responseData },
  //   } = await getAdminPreSignedUrl({
  //     fileName: file.name,
  //     fileType: file.type,
  //   });
  //   if (!responseData?.url) {
  //     toast.error(
  //       "Could not process the upload. Please make sure the file is valid and the internet connection is stable"
  //     );
  //   }
  //   // await uploadFile({url: responseData?.url, method: responseData.method, body: file})
  //   await fetch(`${responseData?.url}`, {
  //     method: responseData?.method,
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": file.type,
  //     },
  //     body: file,
  //   });
  //   // setFile(file);
  //   await updateAdminProfile({
  //     profileData: { profileImage: responseData.key },
  //   }).unwrap();
  //   toast.success("Profile updated successfully");
  //   refetchData();
  //   // setFile({ file, key: responseData.key });
  // };


  const handleAttachment = async (e) => {
    const file = e?.target?.files[0];
    if (!file) return;
  
    try {
      const result = await getAdminPreSignedUrl({
        fileName: file?.name,
        fileType: file?.type,
      });
  
      // Ensure responseData exists before accessing it
      const responseData = result?.data?.response;
  
      if (!responseData || !responseData.url) {
        toast.error(
          "Could not process the upload. Please make sure the file is valid and the internet connection is stable"
        );
        return;
      }
  
      // Perform the file upload using the pre-signed URL
      await fetch(responseData.url, {
        method: responseData.method || 'PUT', // Default to 'PUT' if method is not provided
        headers: {
          Accept: "application/json",
          "Content-Type": file?.type,
        },
        body: file,
      });
  
      // Update the profile after successful file upload
      await updateAdminProfile({
        profileData: { profileImage: responseData.key },
      }).unwrap();
  
      toast.success("Profile updated successfully");
      refetchData();
  
    } catch (error) {
      console.error("Error during file upload:", error);
      toast.error("An error occurred while uploading the file.");
    }
  };
  

  return !isLoading ? (
    <CommonStyledContainer>
        <div className="d-flex align-items-center gap-2">
          <BackArrowIcon
            onClick={() => {
              navigate("/user-workplace");
              dispatch(SetActiveTab({ values: 1 }));
              setActiveUploadIcon(false);
            }}
            className="cursor-pointer"
          />
          <StyledText
            as="label"
            $fontWeight={500}
            $fontSize="20px"
            color={theme.secondary}
          >
            {t("admin:textBtnMyProfile")}
          </StyledText>
        </div>
        <div className="d-block d-flex justify-content-between border rounded-3 p-4 mt-3 align-items-center">
          <div className="d-flex align-items-center gap-2 mb-2 mb-lg-0">
            <div>
            <StyledImage
              height={isSmallScreen ? "40px":"81px"}
              width={isSmallScreen ? "40px":"81px"}
              className=" rounded-pill"
            >
              <img
                src={getAdminProfile?.data?.signedUrl ||
                  DefaulImage.image
                }
                alt="img"
                onError={handleErrorImg}
                style={{objectFit: "cover"}}
              />
            </StyledImage>
            </div>
            <div className="d-flex flex-column ">
              <StyledText
                $fontSize={isSmallScreen ? "16px":"18px"}
                $fontWeight={600}
                color={theme.secondary}
              >
                {`${getAdminProfile?.data?.firstName || ""} ${
                  getAdminProfile?.data?.lastName || ""
                }`}
              </StyledText>
              <StyledText $fontSize={isSmallScreen ? "14px": "16px"} $fontWeight={500} color={theme.gray} truncatedlines={1}>
                {t("common:textPersonalNo")}&nbsp;:&nbsp;
                {getAdminProfile?.data?.personalNumber || ""}
              </StyledText>
            </div>
          </div>
          {activeUploadIcon ? (
            <>
           <TextButtonContainer>
           <StyledButton
              icon={<UploadIcon />}
              variant="silver"
              mh
              className={editProfile ? "" : "feature-pending"}
              onClick={handleButtonClick}
            >
              <div className="d-flex flex-column align-items-start">
                <input
                  ref={fileInputRef}
                  type="file"
                  id="attachments"
                  name="attachments"
                  accept="image/png, image/jpeg"
                  hidden
                  onChange={(evt) => handleAttachment(evt)}
                />
                <StyledText
                  $fontSize="14px"
                  $fontWeight={600}
                  color={theme.secondary}
                  className="d-none d-sm-block"
                >
                  {t("common:textBtnUploadImg1")}
                </StyledText>
                <StyledText
                  $fontSize="14px"
                  $fontWeight={500}
                  color={theme.gray}
                >
                  SVG, PNG , JPEG {t("common:textBtnUploadImg2")}
                </StyledText>
              </div>
            </StyledButton>
           </TextButtonContainer>
           <IconButtonContainer>
           <StyledButton
              icon={<UploadIcon />}
              variant="silver"
              mh
              className={editProfile ? "pe-2" : "feature-pending"}
              onClick={handleButtonClick}
            >
              <div className="d-flex flex-column align-items-start">
                <input
                  ref={fileInputRef}
                  type="file"
                  id="attachments"
                  name="attachments"
                  accept="image/png, image/jpeg"
                  hidden
                  onChange={(evt) => handleAttachment(evt)}
                />
              </div>
            </StyledButton>
           </IconButtonContainer>
            </>
          ) : null}
        </div>
        {editProfile ? (
          <AdminEditProfile
            setEditProfile={setEditProfile}
            user={getAdminProfile?.data}
            refetchData={refetchData}
            setActiveUploadIcon={setActiveUploadIcon}
          />
        ) : (
          <AdminDetails
            setEditProfile={setEditProfile}
            user={getAdminProfile?.data}
            setActiveUploadIcon={setActiveUploadIcon}
          />
        )}
    </CommonStyledContainer>
  ) : (
    <StyledProfileSkeleton hide={true} isButton={true} />
  );
};

const TextButtonContainer = styled.div`
display:block;
 @media (max-width: ${({ lang }) => (lang === 'en' ? '1071px' : '1028px')}) {
            display: none;
        
    }
`;
const IconButtonContainer = styled.div`
display:none;
 @media (max-width: ${({ lang }) => (lang === 'en' ? '1071px' : '1028px')}) {
            display: block;
        
    }
`;

export default AdminProfile;
