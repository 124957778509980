import styled from 'styled-components';

export const StyledRadio = styled.span`
  height: ${({ size }) => (size ? size + 'px' : '20px')};
  width: ${({ size }) => (size ? size + 'px' : '20px')};
  border-radius: 50%;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ value, theme }) => (value ? theme.primary : theme.inputBorder)};
  transition: background-color 0.3s ease;
  border: 2px solid ${({ value, theme }) =>
    value ? theme.primary : theme.inputBorder};
  cursor: pointer;

  .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.primary};
  }

  &.publicForms {
    border: 2px solid ${({ theme, value }) =>
      value ? theme.backgroundColor : theme.primary};

    .dot {
      background-color: ${({ theme, value }) =>
        value ? theme.backgroundColor : theme.primary};
    }

  }
}
`;
