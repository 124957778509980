const ViewIcon = ({
  size = 20,
  rotate = "down",
  color = "#6C6C6C",
  transition,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.9833 10C12.9833 11.65 11.6499 12.9833 9.99994 12.9833C8.34993 12.9833 7.0166 11.65 7.0166 10C7.0166 8.35 8.34993 7.01666 9.99994 7.01666C11.6499 7.01666 12.9833 8.35 12.9833 10Z"
        stroke="#6C6C6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0001 16.8917C12.9418 16.8917 15.6834 15.1583 17.5918 12.1583C18.3418 10.9833 18.3418 9.00834 17.5918 7.83334C15.6834 4.83334 12.9418 3.10001 10.0001 3.10001C7.05845 3.10001 4.31678 4.83334 2.40845 7.83334C1.65845 9.00834 1.65845 10.9833 2.40845 12.1583C4.31678 15.1583 7.05845 16.8917 10.0001 16.8917Z"
        stroke="#6C6C6C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ViewIcon;
