import React from 'react'
import styled from 'styled-components'
import { downloadUrl, formatFileSize, getIconByFileExtension } from '../../Services/Utils/helper'
import StyledText from '../../Components/StyledComponents/StyledText'
import theme from '../../Services/Utils/themes'
import { useTranslation } from 'react-i18next'
import DownloadIcon from '../../Components/Svgicons/DownloadIcon'

const DocumentListing = ({ file, index, totalFiles }) => {
    
    const { t } = useTranslation();
    return (
        <>
            <StyledDocumentListing>
                <div className='d-flex gap-2'>
                    <div>
                        {getIconByFileExtension(file?.file?.s3Object?.fileName, "65px", "51px")}
                    </div>
                    <div className='d-flex flex-column'>
                        <StyledText $fontSize="14px" $fontWeight={600} color={theme.secondary} truncatedlines={1} wordbreak
                        >
                            {file?.file?.s3Object?.fileName}
                        </StyledText>
                        <StyledText $fontSize="12px" $fontWeight={400} color={theme.doveGray} truncatedlines={1}>
                            {t('common:fileSize&Type')} : {formatFileSize(file?.file?.s3Object?.fileSize)}({file?.file?.s3Object?.fileType})
                        </StyledText>
                    </div>
                </div>
                <div
                 onClick={()=>window.open(downloadUrl + file?.file?.fileDescriptor, "_blank")}
                    >
                    <StyledIcon >
                        <DownloadIcon />
                    </StyledIcon>   </div>
            </StyledDocumentListing>

            {index < totalFiles - 1 && <HR />}




        </>
    )
}







const StyledDocumentListing = styled.div`
display: flex;
border: 1px solid ${theme.pastelGray};
justify-content: space-between;
border-radius: 8px;
padding:20px;
box-shadow: 0px 4px 40px 0px rgb(53, 41, 45,0.1);




// max-width:257px;

@media (min-width: 768px) {
  width: auto;
}
`

const StyledIcon = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 42px;
height: 42px;
border-radius: 34px;
border: 1px solid ${theme.pastelGray};
background-color: ${theme.desertStormWhite};
`

const VR = styled.div`
display:block;
height: 60px;
margin: 0 20px;
border-left: 1px solid ${theme.pastelGray};
@media (max-width:1030px){
display:none;
}

@media (max-width:768px){
display:block;
}

@media (max-width:626px){
display:none;
}
`
const HR = styled.hr`
display:none;
@media (max-width:1030px){
display:block;
width:auto;
}

@media (max-width:768px){
display:none;
}

@media (max-width:626px){
display:block;
}

`

export default DocumentListing