import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import EditorToolbar, { formats, getModules } from "./Toolbar";
import StyledButton from "../StyledComponents/StyledButton";
import AttachmentIcon from "../Svgicons/AttachmentIcon";
import CrossIcon from "../Svgicons/CrossIcon";
import { USERTYPE, getIconByFileExtension } from "../../Services/Utils/helper";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import DocumentIcon from "../Svgicons/DocumentIcon";
import StyledText from "../StyledComponents/StyledText";
import { svenskaLs } from "../../Services/Utils/localStorage";
import { useGetFileCategoriesMutation } from "../../Services/DashboardApi/dashboardApiSlice";
import { useGetAdminFileCategoriesMutation } from "../../Services/Admin Api/adminDashboardApi/clientWorkplaceApi/adminApiSlice";
import theme from "../../Services/Utils/themes";


const Align = Quill.import('attributors/style/align');
Quill.register(Align, true);

const StyledTextEditor = ({
  title,
  value,
  onChange,
  placeholder,
  id,
  border,
  borderradius,
  borderradiuseditor,
  wrapperborder,
  elementRef,
  isReadOnly,
  borderTop,
  toolbar,
  borderapp = "",
  minHeight,
  isInvalid,
  bgcolor,
  children,
  handleAttachment,
  setValues,
  setFile,
  removeFile,
  file,
  values,
  fileref,
  setFileCategory,
  showDropdown,
  setShowDropdown,
  fileProgess,
  ...props
}) => {
  const ref = useRef();
  const { t } = useTranslation("common");
  let userType = svenskaLs.getObject("userType");
  const [categories, setCategories] = useState(null);
  const [fileCategories] = useGetFileCategoriesMutation();
  const [adminFileCategories] = useGetAdminFileCategoriesMutation();

  useEffect(() => {
    if (userType === USERTYPE?.ADMIN) {
      adminFileCategories().then((res) => {
        if (res?.data?.data?.length) {
          setCategories(res?.data?.data);
        }
      })
    }
    else if (userType === USERTYPE?.CLIENT || userType === USERTYPE?.NOMINEE) {
      fileCategories().then((res) => {
        if (res?.data?.data?.length) {
          setCategories(res?.data?.data);
        }
      })
    }
    // eslint-disable-next-line
  }, [userType])




  useEffect(() => {
    ref.current.getEditor().root.dataset.placeholder = placeholder || "";
  }, [ref, placeholder]);

  const openRef = useRef();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref?.current && !ref?.current?.contains(event?.target)) {
          setShowDropdown(false);
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(openRef);

  return (
    <>
      <StyledContainer
        {...props}
        borderapp={borderapp}
        border={border}
        isInvalid={isInvalid}
        borderradius={borderradius}
        toolbar={toolbar}
        borderTop={borderTop}
        borderradiuseditor={borderradiuseditor}
        minHeight={minHeight}
        wrapperborder={wrapperborder}
        bgcolor={bgcolor}
        file={file}
      >
        <h5 className="m-0 fw-bold">{title}</h5>
        <ReactQuill
          ref={ref}
          modules={getModules(id)}
          formats={formats}
          readOnly={isReadOnly}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          preserveWhitespace={false}
        />
        {file ? (
          <div className="d-flex align-items-center justify-content-between bg-light-gray p-2 mx-3 rounded bg-opacity-50">
            <div className="d-flex align-items-center gap-3">
              <div>
              {getIconByFileExtension(file?.name)}
              </div>
              <StyledText truncatedlines={1}>
              {file?.name}
              </StyledText>
            </div>
            {fileProgess ? <div className="spinner-border spinner-border-sm" /> : <div
              className="bg-secondary bg-opacity-25 rounded cursor-pointer"
              onClick={() => {
                removeFile();
                // fileref.current.value = null;
              }}
            >
              <CrossIcon />
            </div>}
          </div>
        ) : null}
        <div
          className="d-flex align-items-center justify-content-between position-absolute"
          style={{ width: "100%", bottom: "0" }}
        >
          <div className="d-flex align-items-center ">
            <EditorToolbar id={id} />
            <div className="position-relative">
              <AttachIcon>
              <StyledButton
                variant="snowWhite"
                borderColor={theme.lavenderGray}
                icon={<AttachmentIcon />}
                onClick={() => setShowDropdown(true)}
                
              />
              </AttachIcon>
              
              <AttachmentTextIcon >
                <StyledButton
                  variant="snowWhite"
                borderColor={theme.lavenderGray}

                  icon={<AttachmentIcon />}
                  onClick={() => setShowDropdown(true)}


                >
                  {t("attachmentBtnLable")}
                </StyledButton>
              </AttachmentTextIcon>


              {showDropdown ? (
                <DropdownContainer
                  ref={openRef}
                  className="position-absolute bg-white border shadow p-3"
                >
                  {categories?.length &&
                    categories?.map((item, index, array) => {

                      return (
                        <div key={index}>
                          <input
                            ref={fileref}
                            type="file"
                            // accept={item?.type}
                            id={item?.title}
                            name={item?.title}
                            hidden
                            onChange={handleAttachment}
                          />

                          <div
                            className={`d-flex align-items-center gap-2 cursor-pointer  ${index === array?.length - 1 ? "pb-0" : "pb-3"
                              }`}
                            onClick={() => {
                              fileref.current.click();
                              setFileCategory(item);
                            }}
                          >
                            <DocumentIcon />
                            <StyledText>{item?.title}</StyledText>
                          </div>
                        </div>
                      );
                    })}
                </DropdownContainer>
              ) : null}
            </div>
          </div>
          {children}
        </div>
      </StyledContainer>
    </>
  );
};



const AttachIcon = styled.div`
display: none;
@media (max-width: 1270px) {
  display: block;
}

`;

const AttachmentTextIcon = styled.div`
  display: block;

  @media (max-width: 1270px) {
  display: none;
}
`;

const StyledContainer = styled.div`
  height: 225px;
  background-color: ${({ theme, publicForm }) =>
    publicForm ? "transparent" : theme.white};
  border: ${({ wrapperborder, theme }) =>
    wrapperborder ? wrapperborder : `1px solid ${theme.lightGray}`} !important;
  border-radius: 10px;
  ${({ isValid, theme, isInvalid }) => {
    if (isValid && !isInvalid) {
      return `
      overflow: hidden;
      `;
    }
    if (!isValid && isInvalid) {
      return `
      overflow: hidden;
      border-color: ${theme.danger};
      `;
    }
    return `border-color: transparent`;
  }};

  .quill {
    display: flex;
    flex-direction: column;
  }
  .ql-container {
    color: black;
  }
  .ql-toolbar {
  display: flex;
  max-height: 38px;
    margin: 15px;
    border-radius: ${({ borderradius }) =>
    borderradius ? borderradius : "5px 5px 0 0"};
    background-color: ${({ bgcolor, theme }) =>
    bgcolor ? bgcolor : theme.white} !important;
    border: ${({ border }) => (border ? border : "0 !important")};
    overflow-x: auto !important;
    width: fit-content;
  }

  .ql-snow.ql-toolbar,
  button,
  .ql-stroke {
    display: ${({ toolbar }) => toolbar ?? ""} !important;
    stroke: ${({ theme }) =>
    theme.backgroundColor || theme.textColor} !important;

    &:hover {
      color: ${({ theme }) => theme.white} !important;
    }
  }

  .ql-fill {
    fill: ${({ theme }) => theme.backgroundColor || theme.textColor} !important;
  }

  .ql-container {
    border: 0 !important;
  }

  .ql-editor::placeholder {
    font-family: Montserrat !important;
  }

  .ql-editor {
    font-size: 16px !important;
    -webkit-text-size-adjust: 100%;
    font-family: "Montserrat";
    border-radius: ${({ borderradiuseditor }) =>
    borderradiuseditor ? borderradiuseditor : "0 0 5px 5px"};
    min-height: ${({ minHeight }) => (minHeight ? minHeight : "100px")};
    font-size: 14px;
    border-bottom: 2px solid
      ${({ theme, border, publicForm }) =>
    border ? border : publicForm ? theme.primary : theme.bodyBg};
    border-right: 2px solid
      ${({ theme, border, publicForm }) =>
    border ? border : publicForm ? theme.primary : theme.bodyBg};
    border-left: 2px solid
      ${({ theme, border, publicForm }) =>
    border ? border : publicForm ? theme.primary : theme.bodyBg};
    max-height: ${({ file }) => (file ? "105px" : "150px")};
    overflow-y: auto;
    border: ${({ borderapp }) => borderapp && borderapp};
    border-top: ${({ theme, borderTop }) =>
    `${borderTop} solid ${theme.bodyBg}` ?? "none"} !important;
    color: ${({ publicForm, theme }) => publicForm && theme.primary};
    word-break: break-all;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const DropdownContainer = styled.div`
  min-width: 198px;
  bottom: 51px;
  right: 0px;
  border-radius: 5px;
`;

export default StyledTextEditor;
