const DeleteWarningIcon = ({ size = 124, rotate = "down", transition, color = "#DB4640", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M61.9997 113.667C90.4163 113.667 113.666 90.4168 113.666 62.0002C113.666 33.5835 90.4163 10.3335 61.9997 10.3335C33.583 10.3335 10.333 33.5835 10.333 62.0002C10.333 90.4168 33.583 113.667 61.9997 113.667Z" stroke={color} strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M47.3779 76.6217L76.6213 47.3784" stroke={color} strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M76.6213 76.6217L47.3779 47.3784" stroke={color} strokeWidth="3.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


  );
};

export default DeleteWarningIcon;
