import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../Services/Utils/themes';
import AdminSidebar from '../AdminComponents/AdminSidebar/AdminSidebar';
import AdminHeader from '../../Layouts/Header/AdminHeader';
import { HeaderContainer } from '../../CommonComponents/ReusableComponents';
import { useGetAdminProfileQuery } from '../../Services/Admin Api/adminDashboardApi/adminProfileApiSlice';
import { Overlay } from '../../CommonComponents/SidebarStyling';
import { useDispatch } from 'react-redux';
import { SetIsSidemenu } from '../../Services/SharedDocumentsSlice/SidemenuSlice';

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
  padding: 10px;
  background-color: ${theme.aliceBlue};
`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0 0 10px;
  border-radius: 8px;
  background-color: ${theme.white};
  width: calc(100% - 250px );
  @media (max-width: 768px) {
      margin:0;
  }
`;



const AdminOutlet = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const dispatch = useDispatch();

    let sideMenu = false;

    const {
        data: getAdminProfile,
    } = useGetAdminProfileQuery();
    const handleResize = () => {
        if (window.innerWidth <= 768) {
            setIsSidebarOpen(false);
        } else {
            setIsSidebarOpen(true);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <AppContainer>
            <AdminSidebar isOpen={isSidebarOpen} getAdminProfile={getAdminProfile} />
            <Overlay isOpen={isSidebarOpen} onClick={() => setIsSidebarOpen(false)} />


            <MainContent>
                <HeaderContainer>
                    <AdminHeader adminDetails={getAdminProfile} toggleSidebar={() => setIsSidebarOpen(!isSidebarOpen)} docToggleSidebar={() => dispatch(SetIsSidemenu({ values: !sideMenu }))} />
                </HeaderContainer>
                <div className='w-100'>
                    {children}
                </div>
            </MainContent>

        </AppContainer>
    );
};

export default AdminOutlet;
