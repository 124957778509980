const VerifiedIcon = ({
  size="200",
  rotate = "down",
  transition,
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 200 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M89.5829 20.4166C95.4162 15.5 104.833 15.5 110.499 20.4166L123.666 31.6666C126.166 33.7499 130.916 35.4999 134.249 35.4999H148.416C157.249 35.4999 164.499 42.7499 164.499 51.5832V65.7499C164.499 69.0832 166.249 73.7499 168.333 76.2499L179.583 89.4165C184.499 95.2498 184.499 104.667 179.583 110.333L168.333 123.5C166.249 126 164.499 130.666 164.499 134V148.166C164.499 157 157.249 164.25 148.416 164.25H134.249C130.916 164.25 126.249 166 123.749 168.083L110.583 179.333C104.75 184.25 95.3329 184.25 89.6662 179.333L76.4996 168.083C73.9996 166 69.2496 164.25 65.9996 164.25H51.4163C42.583 164.25 35.333 157 35.333 148.166V133.916C35.333 130.666 33.6663 125.916 31.583 123.5L20.333 110.25C15.4997 104.5 15.4997 95.1665 20.333 89.4165L31.583 76.1665C33.6663 73.6665 35.333 68.9999 35.333 65.7499V51.6666C35.333 42.8333 42.583 35.5833 51.4163 35.5833H65.8329C69.1662 35.5833 73.8329 33.8333 76.3329 31.7499L89.5829 20.4166Z"
        stroke="#00A91B"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M132.353 78L87.4227 122.93L67 102.507"
        stroke="url(#paint0_linear_204_1102)"
        strokeWidth="10.11"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_204_1102"
          x1="63.6486"
          y1="100.674"
          x2="94.2303"
          y2="111.986"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00A91B" />
          <stop offset="1" stopColor="#00A91B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default VerifiedIcon;
