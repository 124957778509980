import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../../Services/Utils/themes';
import StyledText from '../StyledComponents/StyledText';
import { useWindowSize } from '../../Services/Utils/helper';



const Carousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const isSmallScreen = useWindowSize();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(prevIndex =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 2000); // Change slide every 2 seconds

    return () => clearInterval(interval);
  }, [slides.length]);


  const goToSlide = index => {
    setCurrentIndex(index);
  };

  return (
    <Container>
      <Slider currentIndex={currentIndex}>
        {slides.map((slide, index) => (
          <Slide key={index}>
            <div className='d-flex flex-column gap-3 flex-wrap'>
              <StyledText $fontWeight={500} $fontSize={isSmallScreen ? "18px" : "24px"} color={theme.white}>
                {slide.title}
              </StyledText>
              <StyledText $fontWeight={400} $fontSize={isSmallScreen ? "14px" : "16px"} color={theme.white}>
                {slide.content}
              </StyledText>
            </div>
          </Slide>
        ))}
      </Slider>
      <DotsContainer>
        {slides.map((_, index) => (
          <Dot
            key={index}
            active={index === currentIndex}
            onClick={() => goToSlide(index)}
          />
        ))}
      </DotsContainer>
    </Container>
  );
};


const Container = styled.div`
  max-width: 370px;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: start;

  @media only screen and (max-width: 434px) {
  max-width: 343px;
}

@media only screen and (max-width: 390px) {
  max-width: 260px;
}
 
`;

const Slider = styled.div`
  display: flex;
  transition: transform 0.5s ease;

  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 370}px)`};


   @media only screen and (max-width: 434px) {
  transform: ${({ currentIndex }) => `translateX(-${currentIndex * 343}px)`};
}

@media only screen and (max-width: 390px) {
   transform: ${({ currentIndex }) => `translateX(-${currentIndex * 260}px)`};

}

`;

const Slide = styled.div`
  flex: 0 0 370px;

  @media only screen and (max-width: 434px) {
   flex: 0 0 343px;
}

@media only screen and (max-width: 390px) {
flex: 0 0 260px;
}
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

const Dot = styled.div`

  height: 7px;
  width: ${props => (props.active ? '63px' : '24px')};
  background-color: ${theme.white};
  opacity: ${props => (props.active ? '1' : '0.5')};
  border-radius: 24px;
  margin: 0 2px;
  cursor: pointer;
  transition: background-color 0.3s ease, width 0.3s ease, height 0.3s ease;
`;

export default Carousel;
