const DoubleTickIcon = ({ size = 21, rotate = "down", transition, color = "white", ...props }) => {
  return (
    <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 4.33L4.33 7.66L11.0018 1" stroke="#1C79BF" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4 4.33L7.33 7.66L14.0018 1" stroke="#1C79BF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>


  );
};

export default DoubleTickIcon;
